import React from "react";
import { Box, Container } from "@mui/material";
import UserManagement from "./user";
import DeviceManagement from "./device";
import PestManagement from "./pests";
import CorpManagement from "./crops";
import CustomerManagement from "./management";
import SkippableDevices from "./skippableDevices";
import ArchiveManagement from "./archive";

const SmappManagamentMain = () => {
  return (
    <Container
      className="SmappManagamentMain"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        justifyContent: "center",
        width: "100%",
        maxWidth: "100% !important",
        p: 0,
        m: 0,
      }}
    >
      <UserManagement />
      <CustomerManagement />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          justifyContent: "center",
        }}
      >
        <ArchiveManagement />
        <SkippableDevices />
      </Box>
      <DeviceManagement />
      <PestManagement />
      <CorpManagement />
    </Container>
  );
};

export default SmappManagamentMain;
