import React from "react";
import { Image } from "react-konva";
import { Image as ImageRef } from "konva/lib/shapes/Image";
import { mergeRefs } from "utils/react";

type URLImageProps = {
  obj: Blob | MediaSource;
};
const URLImage = React.forwardRef<ImageRef, URLImageProps>(({ obj }, ref) => {
  const imageRef = React.useRef<ImageRef>(null);

  const stageWidth = imageRef.current?.getStage()?.width();

  const autoSize = React.useCallback(() => {
    const imageWidth = imageRef.current?.width();
    const imageHeight = imageRef.current?.height();
    const stage = imageRef.current?.getStage();
    const stageWidth = stage?.width();

    if (imageWidth && stageWidth && imageHeight && stage) {
      const ratio = stageWidth / imageWidth;
      stage.scale({ x: ratio, y: ratio });
      stage.height(imageHeight * ratio);
    }
  }, []);

  React.useEffect(() => {
    autoSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageWidth]);

  const image = React.useMemo(() => {
    const url = URL.createObjectURL(obj);
    const image = new window.Image();
    image.src = url;
    image.onload = autoSize;
    return image;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj]);

  return <Image image={image} ref={mergeRefs(ref, imageRef)} />;
});

export default URLImage;
