import React from "react";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  Box,
  DialogContentText,
} from "@mui/material";
import { useAbout } from "context/AboutProvider";
import CONFIG from "config";
import CloasableDialogTitle from "./ClosableDialogTitle";

const AboutDialog: React.FC = () => {
  const { isOpen, close } = useAbout();
  return (
    <Dialog open={isOpen} onClose={close} maxWidth="xs">
      <CloasableDialogTitle onClose={close}>About</CloasableDialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <DialogContentText>
            Information regarding the current version of scoutlabs dashboard application.
          </DialogContentText>
        </Box>
        <Box>
          <DialogContentText>App version: {CONFIG.appVersion}</DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AboutDialog;
