import React from "react";

import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";
import DeviceMain from "./main";
import DeviceView from "./view";

const Devices: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <DeviceMain />
          </ProtectedRoute>
        }
      />
      <Route
        path=":deviceId"
        element={
          <ProtectedRoute>
            <DeviceView />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Devices;
