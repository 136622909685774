import React from "react";
import AppWrapper from "components/AppWrapper";
import { FlexLoader } from "components/Loader";
import { debounce } from "lodash";
import DeviceMap from "./DeviceMap";

const MapMain: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [height, setHeight] = React.useState<number | undefined>(undefined);

  const resize = React.useCallback(() => {
    setHeight(ref.current?.getBoundingClientRect().height);
  }, []);

  const removeHeight = React.useCallback(() => {
    setHeight(undefined);
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", removeHeight);

    return () => {
      window.removeEventListener("resize", removeHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (height === undefined && ref.current?.getBoundingClientRect().height) {
      const debounced = debounce(resize, 500);
      debounced();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  return (
    <AppWrapper mainSxProps={{ p: 0, maxWidth: "unset", alignSelf: "unset" }} ref={ref}>
      {height ? <DeviceMap height={height} /> : <FlexLoader />}
    </AppWrapper>
  );
};

export default MapMain;
