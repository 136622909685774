import React from "react";
import { Badge, Fab, Tooltip } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import useDeviceFilters from "hooks/useDeviceFilters";
import { useDrawer } from "context/DrawerProvider";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

const DeviceFiltersFab: React.FC = () => {
  const { setRightDrawer } = useDrawer();
  const { filters } = useDeviceFilters();

  const activeFilterCount = Object.values(omit(filters, "ordering", "statuses")).filter(
    (it) => !(isNil(it) || isEmpty(it))
  ).length;

  return (
    <Tooltip title="Filter" arrow>
      <Fab
        color="primary"
        aria-label="filter"
        onClick={() => setRightDrawer("device-filters")}
        sx={{ position: "fixed", right: ["8px", "8px", "16px"], bottom: ["8px", "8px", "16px"] }}
      >
        <Badge badgeContent={activeFilterCount} color="secondary">
          <FilterAltOutlinedIcon />
        </Badge>
      </Fab>
    </Tooltip>
  );
};

export default DeviceFiltersFab;
