import React, { createContext, useContext, useState } from "react";
import useAnnotations, { Annotation } from "hooks/useAnnotations";
import { KonvaEventObject } from "konva/lib/Node";
import { Stage as StageRef } from "konva/lib/Stage";
import {
  ImageCoordinates,
  IMAGE_TYPES,
  updateImage,
  uploadImage,
  UpdateImageCoordinateParams,
  uploadImageCoordinates,
} from "queries/devices/images";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useValidationPanel } from "./ValidationPanelProvider";

type Stroke = "red" | "blue" | "green";
type ValidationContextProps = {
  stroke: Stroke;
  setStroke: StateSetter<Stroke>;
  handleMouseDown: (e: KonvaEventObject<MouseEvent>) => void;
  handleMouseMove: (e: KonvaEventObject<MouseEvent>) => void;
  handleMouseUp: (e: KonvaEventObject<MouseEvent>) => void;
  annotationsToDraw: Annotation[];
  annotations: Annotation[];

  onClear: () => void;
  onSave: () => void;
  isSaving: boolean;
  onClose: () => void;
  stageRef: React.MutableRefObject<StageRef | null>;
  setCoordinateList: React.Dispatch<React.SetStateAction<ImageCoordinates>>;
};

const INITIAL_VALUES: ValidationContextProps = {
  stroke: "red",
  setStroke: () => {},
  handleMouseDown: () => {},
  handleMouseMove: () => {},
  handleMouseUp: () => {},
  annotationsToDraw: [],
  annotations: [],
  onClear: () => {},
  onSave: () => {},
  isSaving: false,
  onClose: () => {},
  stageRef: { current: null },
  setCoordinateList: () => {},
};

const ValidationContext = React.createContext<ValidationContextProps>(INITIAL_VALUES);
function convertBase64ToBlob(base64Image: string) {
  // Split into two parts
  const parts = base64Image.split(";base64,");

  // Hold the content type
  const imageType = parts[0].split(":")[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
}

export type ValidationProviderProps = {
  id: string;
  saveAs: IMAGE_TYPES;
  onClose: ValidationContextProps["onClose"];
  type?: IMAGE_TYPES;
};
const ValidationProvider: React.FC<React.PropsWithChildren<ValidationProviderProps>> = ({
  id,
  saveAs,
  onClose,
  children,
  type,
}) => {
  const { handleMutationSettled } = useValidationPanel();
  const queryClient = useQueryClient();

  const [coordianteList, setCoordinateList] = React.useState([{}] as ImageCoordinates);

  const [stroke, setStroke] = React.useState<Stroke>(INITIAL_VALUES.stroke);
  const stageRef = React.useRef<StageRef | null>(INITIAL_VALUES.stageRef.current);

  const patchMutation = useMutation({
    mutationFn: async () => {
      await updateImage({ id, body: { is_validated: true } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["download-image", { id, type: saveAs }] });
    },
    onSettled: () => {
      handleMutationSettled();
    },
  });

  const uploadMutation = useMutation({
    mutationFn: async (encoded: string) => {
      const blob = convertBase64ToBlob(encoded);
      await uploadImage({ id, type: saveAs, blob });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["list-images"] });
      queryClient.invalidateQueries({ queryKey: ["detail-image", { id }] });
      if (saveAs === IMAGE_TYPES.validated) {
        patchMutation.mutate();
      }
      onClose();
    },
    onSettled: () => {
      handleMutationSettled();
    },
  });

  const uploadCoordinatesMutation = useMutation({
    mutationFn: (body: UpdateImageCoordinateParams) => {
      return uploadImageCoordinates(body);
    },
  });

  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    clearAnnotations,
    annotations,
    annotationsToDraw,
  } = useAnnotations({ stroke });

  const onSave = React.useCallback(() => {
    const stage = stageRef.current?.clone() as StageRef | null;
    if (!stage) throw new Error("Stage not exists, can't save");

    const { x, y } = stage.scale()!;

    stage.width(stage.width() / x);
    stage.height(stage.height() / y);
    stage.scale({ x: 1, y: 1 });

    const encoded = stage.toDataURL({
      mimeType: "image/jpeg",
      quality: 0.95,
    });
    if (!encoded) {
      throw new Error("Encoded dataURL is undefined, can't save");
    }
    uploadMutation.mutate(encoded);
    // saveAs === IMAGE_TYPES.validated
    uploadCoordinatesMutation.mutate({
      id,
      body: {
        operation: type === IMAGE_TYPES.raw ? "create" : "update",
        coordinates: coordianteList,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, saveAs, coordianteList]);

  const value = React.useMemo(
    () => ({
      stageRef,
      annotations,
      stroke,
      setStroke,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      onClear: clearAnnotations,
      onSave,
      isSaving: uploadMutation.isLoading || patchMutation.isLoading,
      onClose,
      annotationsToDraw,
      setCoordinateList,
    }),
    [
      annotations,
      stroke,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      clearAnnotations,
      onSave,
      uploadMutation.isLoading,
      patchMutation.isLoading,
      onClose,
      annotationsToDraw,
      setCoordinateList,
    ]
  );
  return <ValidationContext.Provider value={value}>{children}</ValidationContext.Provider>;
};

export default ValidationProvider;

export const useValidation = () => React.useContext(ValidationContext);
