import { useQuery } from "@tanstack/react-query";
import {
  listAllDetectionCountsByDevice,
  listAllDetectionCountsByOrchard,
  NewDetectionCountsByItem,
} from "queries/devices/averageDetectionCounts";
import React from "react";
import { formatDate } from "utils/date-fns";
import { Aggregations, Frequency } from "utils/query";

type DetectionCountsByOrchardsProps = {
  from: Date;
  to: Date;
  orchardID: string;
  frequency: Frequency;
  aggregations: Aggregations;
};

export const useDetectionCountsByOrchards = ({
  from,
  to,
  orchardID,
  frequency,
  aggregations,
}: DetectionCountsByOrchardsProps) => {
  const params = React.useMemo(() => {
    // Create a copy of the dates to avoid mutating the original
    const fromCopy = new Date(from);
    const toCopy = new Date(to);
    fromCopy.setHours(0, 0, 0, 0);
    toCopy.setHours(23, 59, 0, 0);

    return {
      orchard_ids: [orchardID],
      created_at__gte: fromCopy.getTime() / 1000,
      created_at__lte: toCopy.getTime() / 1000,
      // ordering: "timestamp",
      frequency,
      aggregations,
    };
  }, [orchardID, from, to, aggregations, frequency]);

  const {
    data: detectionCountQueryByOrchard,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["list-all-by-orchard-detection-counts", params],
    queryFn: async () => listAllDetectionCountsByOrchard(params),
    enabled: !!orchardID,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const detectionCountQueryByOrchardList =
    detectionCountQueryByOrchard?.data || ([] as NewDetectionCountsByItem[]);
  const { times } =
    detectionCountQueryByOrchardList.find((v) => v.id === orchardID) ||
    ({} as NewDetectionCountsByItem);

  const data = React.useMemo(
    () =>
      Object.fromEntries(
        (times ?? []).map(({ timestamp, detection_count_delta }) => [
          formatDate(new Date(timestamp * 1000), true),
          {
            detectionCountDelta: detection_count_delta,
          },
        ])
      ),
    [times]
  );

  return { data, error, isLoading };
};
type DetectionCountsByDevicesProps = {
  from: Date;
  to: Date;
  deviceID: string;
  frequency: Frequency;
  aggregations: Aggregations;
};

export const useDetectionCountsByDevices = ({
  from,
  to,
  deviceID,
  frequency,
  aggregations,
}: DetectionCountsByDevicesProps) => {
  const params = React.useMemo(() => {
    // Create a copy of the dates to avoid mutating the original
    const fromCopy = new Date(from);
    const toCopy = new Date(to);
    fromCopy.setHours(0, 0, 0, 0);
    toCopy.setHours(23, 59, 0, 0);

    return {
      device_ids: [deviceID],
      created_at__gte: fromCopy.getTime() / 1000,
      created_at__lte: toCopy.getTime() / 1000,
      // ordering: "timestamp",
      frequency,
      aggregations,
    };
  }, [deviceID, from, to, aggregations, frequency]);

  const {
    data: detectionCountQueryByDevice,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["list-all-by-orchard-detection-counts", params],
    queryFn: async () => listAllDetectionCountsByDevice(params),
    enabled: !!deviceID,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const detectionCountQueryByDeviceList =
    detectionCountQueryByDevice?.data || ([] as NewDetectionCountsByItem[]);
  const { times } =
    detectionCountQueryByDeviceList.find((v) => v.id === deviceID) ||
    ({} as NewDetectionCountsByItem);

  const data = React.useMemo(
    () =>
      Object.fromEntries(
        (times ?? []).map(({ timestamp, detection_count_delta }) => [
          formatDate(new Date(timestamp * 1000), true),
          {
            detectionCountDelta: detection_count_delta,
          },
        ])
      ),
    [times]
  );

  return { data, error, isLoading };
};
