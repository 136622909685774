import { Box, Divider, SwipeableDrawer, useTheme } from "@mui/material";
import List from "@mui/material/List";
import React from "react";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

import BlurOnIcon from "@mui/icons-material/BlurOn";
import HardwareIcon from "@mui/icons-material/Hardware";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useQueryClient } from "@tanstack/react-query";
import { useAbout } from "context/AboutProvider";
import { useAuth } from "context/AuthProvider";
import { useDrawer } from "context/DrawerProvider";
import { useNavigate } from "react-router-dom";

const NavigationDrawer: React.FC = () => {
  const { leftDrawer, setLeftDrawer } = useDrawer();
  const { open: openAbout } = useAbout();
  const { logout, isAdmin } = useAuth();
  const theme = useTheme();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return (
    <SwipeableDrawer
      anchor={theme.direction === "ltr" ? "left" : "right"}
      open={leftDrawer === "nav"}
      onClose={() => setLeftDrawer(undefined)}
      onOpen={() => setLeftDrawer("nav")}
    >
      <Box
        sx={{ width: 300, flex: 1, display: "flex", flexDirection: "column" }}
        role="presentation"
      >
        <List sx={{ flex: 1 }}>
          <ListItem key="/devices" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                navigate("/devices");
              }}
            >
              <ListItemIcon>
                <RouterOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Devices" />
            </ListItemButton>
          </ListItem>
          <ListItem key="/orchard" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                navigate("/orchard");
              }}
            >
              <ListItemIcon>
                <WorkspacesIcon />
              </ListItemIcon>
              <ListItemText primary="Trap Groups" />
            </ListItemButton>
          </ListItem>
          {isAdmin && (
            <ListItem key="/devices/config" disablePadding>
              <ListItemButton
                onClick={() => {
                  setLeftDrawer(undefined);
                  navigate("/devices/config");
                }}
              >
                <ListItemIcon>
                  <SettingsApplicationsIcon />
                </ListItemIcon>
                <ListItemText primary="Devices Config" />
              </ListItemButton>
            </ListItem>
          )}
          {isAdmin && (
            <ListItem key="/firmwares" disablePadding>
              <ListItemButton
                onClick={() => {
                  setLeftDrawer(undefined);
                  navigate("/firmwares");
                }}
              >
                <ListItemIcon>
                  <HardwareIcon />
                </ListItemIcon>
                <ListItemText primary="Firmwares" />
              </ListItemButton>
            </ListItem>
          )}
          {isAdmin && (
            <ListItem key="/management" disablePadding>
              <ListItemButton
                onClick={() => {
                  setLeftDrawer(undefined);
                  navigate("/management");
                }}
              >
                <ListItemIcon>
                  <HardwareIcon />
                </ListItemIcon>
                <ListItemText primary="Management" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem key="/map" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                navigate("/map");
              }}
            >
              <ListItemIcon>
                <MapOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Map" />
            </ListItemButton>
          </ListItem>
          <ListItem key="/charts" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                navigate("/charts");
              }}
            >
              <ListItemIcon>
                <SsidChartOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Charts" />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <Divider />
          <ListItem key="/my-profile" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                navigate("/my-profile");
              }}
            >
              <ListItemIcon>
                <PersonOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          <ListItem key="/orchardManager" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                navigate("/orchardManager");
              }}
            >
              <ListItemIcon>
                <BlurOnIcon />
              </ListItemIcon>
              <ListItemText primary="Trap Group Management" />
            </ListItemButton>
          </ListItem>
          <ListItem key="/about" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                openAbout();
              }}
            >
              <ListItemIcon>
                <RouterOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
          <Divider sx={{ mb: 2 }} />
          <ListItem key="/log-out" disablePadding>
            <ListItemButton
              onClick={() => {
                setLeftDrawer(undefined);
                queryClient.removeQueries();
                logout();
              }}
            >
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default NavigationDrawer;
