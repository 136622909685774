import * as Yup from "yup";

// TODO handle loading state properly
type UpdateDeviceSettingsFormValues = {
  target_pest: string | null;
  target_crop: string | null;
  target_config: string | null;
  target_commission_status: string | null;
  target_firmware: string | null;
};
export const schema = Yup.object({
  target_pest: Yup.string().nullable(),
  target_crop: Yup.string().nullable(),
  target_config: Yup.string().nullable(),
  target_commission_status: Yup.string().nullable(),
  target_firmware: Yup.string().nullable(),
});

export type { UpdateDeviceSettingsFormValues };
