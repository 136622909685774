import React from "react";
import { Box, Card, List, ListItem, Tooltip, Typography } from "@mui/material";
import AutoCompleteWithFilter from "components/AutoCompleteWithFilter";
import { detailDevice, listDevices } from "queries/devices";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { listSkipDevice, addSkipDevice, deleteSkipDevice } from "queries/skipDevices";
import CardWithTitle from "components/CardWithTitle";
import useCustomSnackBar from "hooks/useCustomSnackBar";

const SkippableDevices = () => {
  const { jsx: theSnack, setErrorList } = useCustomSnackBar();

  const listSkipDevicesQuery = useQuery(
    ["skip-list-list-devices"],
    () =>
      listSkipDevice({
        page: 1,
        page_size: 0,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  const addSkipDeviceMutation = useMutation<any, AxiosError, any, unknown>({
    mutationFn: addSkipDevice,
    onSuccess: () => {
      listSkipDevicesQuery.refetch();
    },
    onError: (v) => {
      const obj = Object.values(v.response?.data as any).flat();
      setErrorList((pre) => [...pre, ...(obj as string[])]);
    },
  });

  const detailDeviceMutation = useMutation<any, AxiosError, any, unknown>({
    mutationFn: detailDevice,
    onError: (v) => {
      const obj = Object.values(v.response?.data as any).flat();
      setErrorList((pre) => [...pre, ...(obj as string[])]);
    },
  });

  const deleteMutation = useMutation<any, AxiosError, any, unknown>({
    mutationFn: deleteSkipDevice,
    onSuccess: () => {
      listSkipDevicesQuery.refetch();
    },
    onError: (v) => {
      const obj = Object.values(v.response?.data as any).flat();
      setErrorList((pre) => [...pre, ...(obj as string[])]);
    },
  });

  const skipDevices = listSkipDevicesQuery.data?.data || [];

  return (
    <CardWithTitle
      title="Skippable devices"
      sx={{
        display: "flex",
        flexFlow: "column",
        width: "100%",
        // gap: 2,
        p: 1,
        m: 1,
        alignItems: "center",
        flex: 1,
      }}
    >
      <Box>
        {theSnack}
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
            }}
          >
            <AutoCompleteWithFilter
              boxSx={{ width: "100%" }}
              key="Devices"
              label="Devices"
              qKey="skipDevices"
              qFn={listDevices}
              queryParamsKey={["smapp_id"]}
              extraQueryParams={{ active: true }}
              keyToLabelList={["smapp_id"]}
              lookup="icontains"
              size="small"
              onChange={async (e, v) => {
                if (v) {
                  const detailDev = await detailDeviceMutation.mutateAsync({ id: v.value });
                  addSkipDeviceMutation.mutate(
                    detailDev.mac !== null ? { mac: detailDev.mac } : { imei: detailDev.imei }
                  );
                }
              }}
            />
          </Box>

          <Box>
            <Typography sx={{ ml: 2, flex: 1 }} variant="subtitle1" component="div">
              Device list
            </Typography>
            <Card
              sx={{
                height: "200px",
                width: "250px",
                overflowY: "scroll",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <Tooltip placement="top" title="Double click to remove">
                <List>
                  {skipDevices.map((v) => {
                    const macOrImei =
                      v.mac !== null ? { l: "Mac", v: v.mac } : { l: "Imei", v: v.imei };
                    return (
                      <ListItem
                        sx={{
                          borderRadius: "5px",
                          p: 0.125,
                          pl: 0.5,
                          fontSize: "small",
                          m: 0.5,
                        }}
                        key={v.id}
                        onDoubleClick={(e) => {
                          if (v) {
                            deleteMutation.mutate(v.id);
                          }
                        }}
                      >
                        {macOrImei.l}: {macOrImei.v}
                      </ListItem>
                    );
                  })}
                </List>
              </Tooltip>
            </Card>
          </Box>
        </Box>
      </Box>
    </CardWithTitle>
  );
};

export default SkippableDevices;
