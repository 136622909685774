import React from "react";
import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { listFirmwares } from "queries/firmwares";
import FWList from "./listFw";
import FwUpload from "./uploadFw";

const FirmwaresMain = () => {
  const listActiveFw = useQuery({
    queryFn: () => listFirmwares(),
    queryKey: ["listFirmwares"],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const listInActiveFw = useQuery({
    queryFn: () => listFirmwares({ active: false, page: 1, page_size: 100 }),
    queryKey: ["listInactiveFirmwares"],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  let fwList = listActiveFw.data?.data.flatMap((v) => v) || [];
  fwList = fwList.concat(...(listInActiveFw.data?.data.flatMap((v) => v) || []));

  return (
    <Container
      sx={{ display: "inline-flex", flexDirection: "row", gap: 2, justifyContent: "center" }}
    >
      <FwUpload
        listActiveFw={fwList}
        refetch={{ active: listActiveFw.refetch, inactive: listInActiveFw.refetch }}
      />
      <FWList activeFw={listActiveFw} inActiveFw={listInActiveFw} />
    </Container>
  );
};

export default FirmwaresMain;
