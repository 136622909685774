import React from "react";
import { Route, Routes } from "react-router-dom";

import AppWrapper from "components/AppWrapper";
import ProtectedRoute from "components/ProtectedRoute";
import OrchardManagerMain from ".";

const OrchardManager: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <AppWrapper mainSxProps={{ width: "95%", maxWidth: "95%" }}>
              <OrchardManagerMain />
            </AppWrapper>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default OrchardManager;
