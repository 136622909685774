import React from "react";

import { ListItemProps, ListItemText, ListItemTextProps, ListItem } from "@mui/material";
import isNil from "lodash/isNil";

export type PropertyCellProps = {
  label: ListItemTextProps["secondary"];
  value?: ListItemTextProps["primary"] | null;
  listItemTextProps?: Omit<ListItemTextProps, "primary" | "secondary">;
  listItemProps?: ListItemProps;
};
const PropertyCell: React.FC<React.PropsWithChildren<PropertyCellProps>> = ({
  label,
  value,
  listItemProps = {},
  listItemTextProps: { sx, ...listItemtextProps } = {},
  children,
}) => {
  return (
    <ListItem {...listItemProps}>
      <ListItemText
        secondary={label}
        primary={isNil(value) ? "Not defined" : value}
        sx={{ display: "flex", flexDirection: "column-reverse", ...sx }}
        {...listItemtextProps}
      />
      {children}
    </ListItem>
  );
};

export default PropertyCell;
