import React from "react";
import AppWrapper from "components/AppWrapper";
import { Stack } from "@mui/material";
import DetectionCountDeltaChartPanel from "components/visualization/DetectionCountDeltaChartPanel";
import MeasurementChartPanel from "components/visualization/MeasurementChartPanel";
// import DeviceFiltersFab from "pages/devices/main/DeviceFiltersFab";

// const data = [
//   {
//     name: "Page A",
//     detectionCountDelta: 4000,
//     temperature: 2400,
//     humidity: 2400,
//   },
//   {
//     name: "Page B",
//     detectionCountDelta: 3000,
//     temperature: 1398,
//     humidity: 2210,
//   },
//   {
//     name: "Page C",
//     detectionCountDelta: 2000,
//     temperature: 9800,
//     humidity: 2290,
//   },
//   {
//     name: "Page D",
//     detectionCountDelta: 2780,
//     temperature: 3908,
//     humidity: 2000,
//   },
//   {
//     name: "Page E",
//     detectionCountDelta: 1890,
//     temperature: 4800,
//     humidity: 2181,
//   },
//   {
//     name: "Page F",
//     detectionCountDelta: 2390,
//     temperature: 3800,
//     humidity: 2500,
//   },
//   {
//     name: "Page G",
//     detectionCountDelta: 3490,
//     temperature: 4300,
//     humidity: 2100,
//   },
// ];

const ChartsMain: React.FC = () => {
  // TODO: fetch data
  return (
    <AppWrapper>
      <Stack spacing={3}>
        <DetectionCountDeltaChartPanel />
        <MeasurementChartPanel />
      </Stack>
      {/* <DeviceFiltersFab filters={filters} /> */}
      {/* <DeviceFiltersDrawer filters={filters} setFilters={setFilters} /> */}

      {/* <ChartStack data={data} /> */}
    </AppWrapper>
  );
};

export default ChartsMain;
