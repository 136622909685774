import React from "react";
import { Autocomplete, AutocompleteProps, Box, TextField } from "@mui/material";
import useGenericQuery from "hooks/useGenericQuery";
import LinearProgress from "@mui/material/LinearProgress";
import _, { debounce } from "lodash";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

type GenericFilterProp = {
  boxSx?: DefaultComponentProps<any>;
  label: string;
  handleChange?: (name: string, value: string) => void;
  filteredData?: React.Dispatch<React.SetStateAction<any>>;
  qKey: string;
  qFn: any;
  queryParamsKey?: string[];
  keyToLabelList?: string[];
  extraQueryParams?: {
    [x: string]: unknown;
  };
  lookup?: string;
} & Omit<AutocompleteProps<any, boolean, boolean, boolean, any>, "options" | "renderInput">;

const AutoCompleteWithFilter: React.FC<GenericFilterProp> = <
  T extends object & { id: string; name: string }
>({
  handleChange,
  filteredData,
  boxSx,
  label,
  qKey,
  qFn,
  queryParamsKey,
  keyToLabelList,
  extraQueryParams,
  lookup,
  ...p
}: GenericFilterProp) => {
  const qParamKey = (queryParamsKey || []).map((v) => (lookup ? `${v}__${lookup}` : v));

  const [fetchProgress, setFetchProgress] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const { onInputChange: propInputChange, ...props } = p;

  const onAutoCompleteChange = React.useMemo(
    () =>
      debounce((e: any, value: string, r: any) => {
        setSearch(value);
        if (propInputChange) {
          propInputChange(e, value, r);
        }
      }, 600),
    [propInputChange]
  );

  const genericListQuery = useGenericQuery<T>({
    qKey,
    debouncedSearch: search,
    fn: qFn,
    queryParamsKey: qParamKey,
    extraQueryParams,
  });

  const genericData = React.useMemo(() => {
    return (genericListQuery.data?.pages ?? [])?.map((it) => it.data).flat();
  }, [genericListQuery.data?.pages]);

  React.useEffect(() => {
    if (filteredData) {
      filteredData(genericData);
    }
  }, [genericData, filteredData]);

  const genericDataToLabelAndValue = genericData
    .map((v) => {
      const label =
        keyToLabelList
          ?.map((vv) => v[vv as keyof T] as string)
          .filter((vv) => !_.isEmpty(vv) && vv.toLowerCase().includes(search.toLowerCase()))
          .at(0) || v.name;
      return {
        label,
        value: v.id,
      };
    })
    .filter((v) => v.label !== undefined);

  React.useEffect(() => {
    if (genericListQuery.isFetchingNextPage) {
      setFetchProgress(true);
      setTimeout(() => {
        setFetchProgress(false);
      }, 500);
    }
  }, [genericListQuery]);

  return (
    <Box sx={{ ...boxSx }}>
      {fetchProgress && <LinearProgress sx={{ mb: 0.5 }} />}

      <Autocomplete
        options={genericDataToLabelAndValue}
        onInputChange={onAutoCompleteChange}
        isOptionEqualToValue={(o, v) => {
          return o.value === v.value || v.label === "" || v.label === o.label;
        }}
        ListboxProps={{
          style: { maxHeight: 200 },
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (
              Math.round(listboxNode.scrollTop + listboxNode.clientHeight) + 5 >=
              listboxNode.scrollHeight
            ) {
              if (genericListQuery) {
                genericListQuery.fetchNextPage();
              }
            }
          },
        }}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        {...props}
      />
    </Box>
  );
};

export default AutoCompleteWithFilter;
