import * as React from "react";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF, GridCellParams } from "@mui/x-data-grid";
import { Box, Button, TextField } from "@mui/material";
// import _, { debounce } from "lodash";
// import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ListDeviceItem } from "queries/devices";

// const qKey = "listOfDevices"
const PAGE_SIZE = 5;

// const modelParams = (paginationModel: any, search: string) => {
//   const params = {
//     page: paginationModel.page + 1,
//     page_size: paginationModel.pageSize,
//     hr: "t",
//     active: true
//   } as ListDeviceParams
//   if (search.length > 0)
//     params.search = search
//   return params
// }

// const listModel = (paginationModel: any, search: string) => {
//   return listDevices(modelParams(paginationModel, search))
// }

// const preListModel = (paginationModel: any, search: string) => {
//   const params = modelParams(paginationModel, search)
//   params.page += 1
//   return listDevices(params)
// }

type DeviceTableParamsType = {
  setDevices: React.Dispatch<React.SetStateAction<ListDeviceItem[]>>;
  devices: ListDeviceItem[];
};

const TargetDevices = ({ devices, setDevices }: DeviceTableParamsType) => {
  // const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: PAGE_SIZE });
  // const [search, setSearch] = React.useState("");
  // const onNameChange = React.useMemo(
  //   () =>
  //     debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
  //       setSearch(value);
  //     }, 600),
  //   []
  // );

  // const listQuery = useQuery(
  //   [qKey, paginationModel, search],
  //   () => listModel(paginationModel, search),
  //   {
  //     refetchOnWindowFocus: false,
  //     keepPreviousData: true,
  //   }
  // );

  // React.useEffect(() => {
  //   if (!listQuery.isPreviousData && listQuery.data?.meta.next) {
  //     queryClient.prefetchQuery({
  //       queryKey: [qKey, paginationModel],
  //       queryFn: async () => preListModel(paginationModel, search),
  //       staleTime: 0,
  //       cacheTime: 0,
  //     });
  //   }
  // }, [queryClient, listQuery, paginationModel, search]);

  // const newData = (listQuery.data?.data ?? [])

  const columns = Object.entries(devices.length > 0 ? devices[0] : []).map(([k, v]) => {
    return { field: k.toLowerCase(), headerName: k, flex: 1, hide: false };
  });

  // const hideAllExcept = Object.keys(columns)

  // const hideAllExcept = columns.map(v => {
  //   const kek = [[v.field], v.hide]
  //   return kek
  // })
  const createColumnVisibility = Object.fromEntries(columns.map((v) => [[v.field], v.hide]));
  // const kk = Object.fromEntries(hideAllExcept)

  // const selectAllButtonInHeader = {
  //   ...GRID_CHECKBOX_SELECTION_COL_DEF,
  //   width: 150,
  //   renderHeader: () => (
  //     <Box sx={{ display: "flex", gap: "5px" }}>
  //       <Button
  //         title="Add all to target devices"
  //         onClick={() => console.log()}
  //       >
  //         <AddIcon fontSize="small" />
  //       </Button>
  //     </Box>
  //   ),
  //   renderCell: (params: GridCellParams) => (
  //     <Box
  //       onChange={(event) => {
  //         console.log(event, params)
  //       }}
  //     />
  //   ),
  // }

  const deviceSliceFrom = paginationModel.page * paginationModel.pageSize;
  const deviceSliceUntil = deviceSliceFrom + paginationModel.pageSize;

  const table = (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none",
        },
        width: "100%",
      }}
      columnVisibilityModel={{
        ...createColumnVisibility,
        smapp_id: true,
        corporations: true,
        type: true,
      }}
      // rows={devices}
      rows={devices.slice(deviceSliceFrom, deviceSliceUntil)}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      // checkboxSelection
      // rowCount={listQuery.data?.meta.count ?? 0}
      rowCount={devices.length ?? 0}
      // onRowSelectionModelChange={(ids) => setDevices((pre) => pre.filter((v) => !ids.includes(v.id)))}
      onRowSelectionModelChange={(ids) =>
        setDevices((pre) => pre.filter((v) => !ids.includes(v.id)))
      }
    />
  );

  return (
    // <Box sx={{ display: "flex", flexFlow: "column", width: "100%", gap: 0.5, height: "450px", flex:1 }}>
    <Box sx={{ display: "flex", flexFlow: "column", gap: 0.5, height: "450px", flex: 1 }}>
      {/* <TextField
        sx={{ width: "100%" }}
        label="Search in Devices (every column)"
        variant="outlined"
        size="small"
        onChange={onNameChange}
      /> */}
      Target devices
      {devices.length > 0 && table}
    </Box>
  );
};

export default TargetDevices;
