import { KonvaEventObject } from "konva/lib/Node";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export type StrokeColor = "red" | "blue" | "green";
export type Annotation = {
  x: number;
  y: number;
  width: number;
  height: number;
  id: string;
  stroke: StrokeColor;
};

type UseAnnotationsOptions = {
  stroke: StrokeColor;
};
const useAnnotations = ({ stroke }: UseAnnotationsOptions) => {
  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);
  const [newAnnotation, setNewAnnotation] = React.useState<undefined | Annotation>(undefined);

  const handleMouseDown = React.useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const vector = e.target.getStage()?.getPointerPosition();
      const scale = e.target.getStage()?.scale();

      if (!newAnnotation && vector && scale) {
        const { x, y } = vector;

        setNewAnnotation({
          x: x / scale.x,
          y: y / scale.y,
          width: 0,
          height: 0,
          id: uuidv4(),
          stroke,
        });
      }
    },
    [stroke, newAnnotation]
  );

  const handleMouseUp = React.useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const vector = e.target.getStage()?.getPointerPosition();
      const scale = e.target.getStage()?.scale();

      if (newAnnotation && vector && scale) {
        const { x: sx, y: sy, id } = newAnnotation;
        const { x, y } = vector;

        const annotationToAdd = {
          x: sx,
          y: sy,
          width: x / scale.x - sx,
          height: y / scale.y - sy,
          id,
          stroke,
        };

        setAnnotations([...annotations, annotationToAdd]);
        setNewAnnotation(undefined);
      }
    },
    [annotations, stroke, newAnnotation]
  );

  const handleMouseMove = React.useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const vector = e.target.getStage()?.getPointerPosition();
      const scale = e.target.getStage()?.scale();

      if (newAnnotation && vector && scale) {
        const { x: sx, y: sy, id } = newAnnotation;
        const { x, y } = vector;

        setNewAnnotation({
          x: sx,
          y: sy,
          width: x / scale.x - sx,
          height: y / scale.y - sy,
          id,
          stroke,
        });
      }
    },
    [stroke, newAnnotation]
  );

  const clearAnnotations = React.useCallback(() => {
    setAnnotations([]);
    setNewAnnotation(undefined);
  }, []);

  return React.useMemo(
    () => ({
      clearAnnotations,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      annotations,
      newAnnotation,
      annotationsToDraw: newAnnotation ? [...annotations, newAnnotation] : annotations,
    }),
    [annotations, clearAnnotations, handleMouseDown, handleMouseMove, handleMouseUp, newAnnotation]
  );
};

export default useAnnotations;
