import axios from "axios";

export type OperationRequestType = {
  id: string;
  body?: {
    target_operation_request: string;
  };
};

export type OperationRequestItem = {
  id: string;
  created_at: string;
  updated_at: string;
  operation_request: string;
  is_executed: boolean;
  device: string;
};

export const listOperationRequest = async ({
  id,
}: OperationRequestType): Promise<OperationRequestItem> => {
  const { data } = await axios.get<OperationRequestItem>(`/api/devices/operation-requests/${id}`);
  return data;
};

export const updateOperationRequest = async ({
  id,
  body,
}: OperationRequestType): Promise<OperationRequestItem> => {
  const { data } = await axios.patch<OperationRequestItem>(
    `/api/devices/operation-requests/${id}`,
    body
  );
  return data;
};

export const deleteOperationRequest = async ({
  id,
}: OperationRequestType): Promise<OperationRequestItem> => {
  const { data } = await axios.delete<OperationRequestItem>(
    `/api/devices/operation-requests/${id}`
  );
  return data;
};
