import React from "react";
import { Dialog, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { detailDevice } from "queries/devices";
import { useAuth } from "context/AuthProvider";
import UpdateDeviceGenericsDialogTitle from "./UpdateDeviceGenericsDialogTitle";
import UpdateDeviceGenericsDialogForm, {
  UpdateDeviceGenericsFormValues,
} from "./UpdateDeviceGenericsDialogForm";
import UpdateDeviceGenericsDialogFormLoader from "./UpdateDeviceGenericsDialogFormLoader";

type UpdateDeviceGenericsDialogProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateDeviceGenericsDialog: React.FC<UpdateDeviceGenericsDialogProps> = ({
  id,
  isOpen,
  onClose,
}) => {
  const { isAdmin } = useAuth();
  const deviceQuery = useQuery({
    queryKey: ["detail-device", { id }],
    queryFn: () => detailDevice({ id: id as string }),
    enabled: !!id,
  });

  const deviceId = deviceQuery.data?.id;

  const initialValues: UpdateDeviceGenericsFormValues | undefined = React.useMemo(() => {
    if (deviceQuery.data) {
      const initialValues: UpdateDeviceGenericsFormValues = {
        name: deviceQuery.data.name ?? "",
        country: deviceQuery.data.country ?? "",
        city: deviceQuery.data.city ?? "",
        timezone: deviceQuery.data.timezone ?? "",
      };
      if (isAdmin) {
        initialValues.comment = deviceQuery.data.comment ?? "";
      }
      return initialValues;
    }
    return undefined;
  }, [deviceQuery.data, isAdmin]);

  let content = null;
  if (deviceQuery.error) {
    content = <Typography>{String(deviceQuery.error)}</Typography>;
  } else if (deviceId && initialValues) {
    content = (
      <UpdateDeviceGenericsDialogForm
        handleSuccess={onClose}
        initialValues={initialValues}
        id={deviceId}
        actionsProps={{ handleCancelClick: onClose }}
      />
    );
  } else {
    content = (
      <UpdateDeviceGenericsDialogFormLoader actionsProps={{ handleCancelClick: onClose }} />
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <UpdateDeviceGenericsDialogTitle deviceQuery={deviceQuery} onClose={onClose} />
      {content}
    </Dialog>
  );
};

export default UpdateDeviceGenericsDialog;
