/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "./types";

export type ListCropParams = {
  page: number;
  page_size: number;
  id__in?: string[];
};
export type ListCropItem = {
  id: string;
  created_at: number;
  updated_at: number;
  comment: string | null;
  name: string;
};
export const listCrops = async (params?: ListCropParams): Promise<ListResponse<ListCropItem>> => {
  const { data } = await axios.get<ListResponse<ListCropItem>>("/api/crops/", { params });
  return data;
};

export const listAllCrops = listAllFactory(listCrops);

export type DetailCropParams = {
  id: string;
};
type DetailCropItem = ListCropItem;
export const detailCrop = async ({ id }: DetailCropParams): Promise<DetailCropItem> => {
  const { data } = await axios.get<DetailCropItem>(`/api/crops/${id}/`);
  return data;
};
