import { LoadingButton } from "@mui/lab";
import { Alert, Box, Card, DialogActions, Snackbar, TextField } from "@mui/material";
import { QueryObserverResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  FirmwareNameAndVersions,
  ListFirmwareItem,
  UpdateFirmwareParams,
  boardMajorMinorPatch,
  updateFirmware,
  uploadFirmware,
} from "queries/firmwares";
import { ListResponse } from "queries/types";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { waitCrc } from "utils/hash";

type FwUploadProps = {
  refetch: {
    active: () => Promise<QueryObserverResult<ListResponse<ListFirmwareItem>, unknown>>;
    inactive: () => Promise<QueryObserverResult<ListResponse<ListFirmwareItem>, unknown>>;
  };
  listActiveFw: ListFirmwareItem[];
};

const FwUpload = ({ refetch, listActiveFw }: FwUploadProps) => {
  const [file, setFile] = React.useState<File | null>(null);
  const [fileCrc32, setFileCrc32] = React.useState("Loading...");
  type FirmwareNameAndVersionsAndError = FirmwareNameAndVersions & { mutationError: string };
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<FirmwareNameAndVersionsAndError>({});

  const mutationUploadFw = useMutation<void, AxiosError<{ reason: object }>, FormData, unknown>({
    mutationFn: uploadFirmware,
    onSuccess: () => {
      refetch.active();
      refetch.inactive();
    },
    onError: (e) => {
      setError("mutationError", { message: JSON.stringify(e.response?.data.reason, null, 1) });
    },
  });

  const mutationUpdateFw = useMutation<
    void,
    AxiosError<{ reason: object }>,
    UpdateFirmwareParams,
    unknown
  >({
    mutationFn: updateFirmware,
    onSuccess: () => {
      refetch.active();
      refetch.inactive();
    },
    onError: (e) => {
      setError("mutationError", { message: JSON.stringify(e.response?.data.reason, null, 1) });
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      setValue("filename", file.name);
    }
  };

  type FWFormFieldsType = { label: string; inputType: string };

  const fwFormFields = {
    filename: { label: "Filename", inputType: "string" } as FWFormFieldsType,
    board_type: { label: "Board type", inputType: "string" } as FWFormFieldsType,
    major_version: { label: "Major version", inputType: "number" } as FWFormFieldsType,
    minor_version: { label: "Minor version", inputType: "number" } as FWFormFieldsType,
    patch_version: { label: "Patch version", inputType: "number" } as FWFormFieldsType,
  };

  const watchAllFields = watch();
  const isFwPatch = listActiveFw.find(
    (v) => boardMajorMinorPatch(v) === boardMajorMinorPatch(watchAllFields)
  );

  const onSubmit = React.useCallback(
    (params: FirmwareNameAndVersions) => {
      if (file == null) throw new Error("No file given");

      const data = new FormData();
      Object.entries(params).forEach(([key, val]) => {
        data.append(key, val);
      });

      if (typeof file === "string" || file instanceof Blob) {
        data.append("file", file);
      }

      if (isFwPatch) {
        mutationUpdateFw.mutate({ id: isFwPatch.id, data });
      } else {
        mutationUploadFw.mutate(data);
      }
    },
    [mutationUploadFw, mutationUpdateFw, file, isFwPatch]
  );

  const ChooseFirmware = (
    <Box sx={{ p: 1 }}>
      Choose a firmware
      <input id="file" type="file" accept=".bin" onChange={handleChange} />
    </Box>
  );

  const TextFieldsLoader = (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 1 }}>
      {Object.entries(fwFormFields).map(([k, v]) => (
        <TextField
          sx={{ width: "100%" }}
          key={k}
          InputLabelProps={{ shrink: true }}
          type={v.inputType}
          size="small"
          variant="outlined"
          label={v.label}
          {...register(k as keyof typeof fwFormFields)}
        />
      ))}
    </Box>
  );

  useEffect(() => {
    if (file) {
      waitCrc(file).then((hash) => setFileCrc32(hash));
    }
  }, [file]);

  const FileDetails = (
    <Box>
      {file && (
        <section>
          File details:
          <ul>
            <li>Name: {file.name}</li>
            <li>Type: {file.type}</li>
            <li>Size: {file.size} bytes</li>
            <li>Crc32: {fileCrc32} </li>
          </ul>
        </section>
      )}
    </Box>
  );

  const SaveButton = (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
      <DialogActions>
        <LoadingButton sx={{ textTransform: "none" }} variant="contained" type="submit">
          {isFwPatch ? "Patch Fw" : "Upload Fw"}
        </LoadingButton>
      </DialogActions>
    </Box>
  );

  return (
    <Card sx={{ flex: 1, padding: 1, height: "fit-content", minWidth: "400px" }}>
      Add new firmwares:
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {ChooseFirmware}
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2.5 }}>
          {TextFieldsLoader}
          {FileDetails}
        </Box>
        <Box>
          <Snackbar
            open={errors.mutationError !== undefined}
            autoHideDuration={6000}
            onClose={() => {
              clearErrors("mutationError");
            }}
          >
            <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
              <pre>{errors.mutationError?.message}</pre>
            </Alert>
          </Snackbar>
        </Box>
        {SaveButton}
      </Box>
    </Card>
  );
};

export default FwUpload;
