import React from "react";

// list drawer id-s here
type LeftDrawerId = "nav";
type RightDrawerId = "device-filters" | "preferences";

type DrawerContextProps = {
  leftDrawer?: LeftDrawerId;
  setLeftDrawer: StateSetter<undefined | LeftDrawerId>;
  rightDrawer?: RightDrawerId;
  setRightDrawer: StateSetter<undefined | RightDrawerId>;
};

const INITIAL_VALUES = {
  leftDrawer: undefined,
  setLeftDrawer: () => {},
  rightDrawer: undefined,
  setRightDrawer: () => {},
};

const DrawerContext = React.createContext<DrawerContextProps>(INITIAL_VALUES);

const DrawerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [leftDrawer, setLeftDrawer] = React.useState<LeftDrawerId | undefined>(
    INITIAL_VALUES.leftDrawer
  );
  const [rightDrawer, setRightDrawer] = React.useState<RightDrawerId | undefined>(
    INITIAL_VALUES.rightDrawer
  );

  const value = React.useMemo(
    () => ({
      leftDrawer,
      setLeftDrawer,
      rightDrawer,
      setRightDrawer,
    }),
    [leftDrawer, rightDrawer]
  );
  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export default DrawerProvider;

export const useDrawer = () => React.useContext(DrawerContext);
