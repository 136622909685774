import {
  CameraConfigFieldsType,
  DynamicConfigFieldsType,
  LightConfigFieldsType,
  MyFilter,
  TimeConfigFieldsType,
  WakeupConfigFieldsType,
} from "./types";

export const defaultMyFilter: MyFilter = {
  pest: { value: null, queryParams: "pest_id", lookups: null },
  crop: { value: null, queryParams: "crop_id", lookups: null },
  country: { value: null, queryParams: "country", lookups: ["icontains", "in"] },
  smapp_id: { value: null, queryParams: "smapp_id", lookups: ["icontains", "in"] },
  imei: { value: null, queryParams: "imei", lookups: ["icontains", "in"] },
  mac: { value: null, queryParams: "mac", lookups: ["icontains", "in"] },
  type: { value: null, queryParams: "type", lookups: ["icontains", "in"] },
};

export const defaultDynamicConfig = {
  sssr: "",
  plusMinus: "",
  offset: 0,
  stat: 0,
} as DynamicConfigFieldsType;

//
//  WAKEUP
//

export const defaultWakeupConfigFields = {
  firstDynamicConfig: defaultDynamicConfig,
  lastDynamicConfig: defaultDynamicConfig,
  staticMultiText: "",
  dynamicTimes: 0,
} as WakeupConfigFieldsType;

//
//  LIGHT
//

export const defaultLightConfigFields = {
  duration: "",
  intensity: "",
  dynamicConfig: defaultDynamicConfig,
} as LightConfigFieldsType;

//
//  TIME
//

export const defaultTimeConfigFields = {
  env_times: "",
  diag_times: "",
  network_times: "",
  image_times: "",
  loc_times: "",
  upload_times: "",
} as TimeConfigFieldsType;

//
//  CAMERA
//

export const defaultCameraConfigFields = {
  resolution: "vga",
  autofocus: false,
  flashlight: 0,
} as CameraConfigFieldsType;

export const defaultTemplateCfg = {
  comment: "",
  wakeup: {
    type: "",
    fields: defaultWakeupConfigFields,
  },
  light: {
    enabled: false,
    // fields: [defaultLightConfigFields],
    fields: [],
  },
  time: defaultTimeConfigFields,
  camera: defaultCameraConfigFields,
};
