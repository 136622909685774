import EmojiNatureOutlinedIcon from "@mui/icons-material/EmojiNatureOutlined";
import { Chip, ChipProps } from "@mui/material";
import React from "react";
import { DetectionCountCatchChipColor, GetDetectionCountColor } from "utils/commons";

type DetectionCountCatchChipProps = {
  title: string;
  prefixTitle?: string;
  colored?: boolean;
  value?: {
    dc: number;
    dcd: number;
  };
};

const DetectionCountCatchChip: React.FC<DetectionCountCatchChipProps> = ({
  title,
  prefixTitle,
  value,
  colored,
}) => {
  const extraProp = {} as Pick<ChipProps, "color">;
  if (colored) {
    extraProp.color = GetDetectionCountColor(value?.dcd || 0, DetectionCountCatchChipColor) as
      | "error"
      | "success"
      | "warning";
  }
  return (
    <Chip
      icon={<EmojiNatureOutlinedIcon />}
      label={`${prefixTitle || ""}${value?.dcd || 0} ${title}`}
      variant="outlined"
      {...extraProp}
    />
  );
};

export default DetectionCountCatchChip;
