import React from "react";
import { useQuery, useQueries } from "@tanstack/react-query";
import AppWrapper from "components/AppWrapper";
import { FlexLoader } from "components/Loader";
import { isNil } from "lodash";
import { detailDevice, DetailDeviceItem } from "queries/devices";
import { listOperationRequest } from "queries/devices/operationRequest";
import { Navigate, useParams } from "react-router-dom";
import ROUTES from "routes";
import { isUUID } from "utils/uuid";
import DeviceViewProvider, { OperationRequestSwitchData } from "context/DeviceViewProvider";
import { Stack } from "@mui/material";
import { useAuth } from "context/AuthProvider";
import ChartPanel from "./panels/ChartPanel";
import DataPanel from "./panels/DataPanel";
import ImagePanel from "./panels/ImagePanel";
import MapPanel from "./panels/MapPanel";
import ValidationPanel from "./panels/ValidationPanel";

type DeviceViewWithDataProps = { device: DetailDeviceItem };
const DeviceViewWithData: React.FC<DeviceViewWithDataProps> = ({ device }) => {
  const { isAdmin } = useAuth();
  return (
    <Stack spacing={3}>
      <DataPanel device={device} />
      <ChartPanel device={device} />
      <MapPanel device={device} />
      {/* <ImagePanel device={device} /> */}
      {isAdmin ? <ValidationPanel device={device} /> : <ImagePanel device={device} />}
      {/* <LastImage id={device.last_image} type="raw" /> */}
    </Stack>
  );
};

type DeviceViewWithIdProps = {
  id: string;
};
const DeviceViewWithId: React.FC<DeviceViewWithIdProps> = ({ id }) => {
  const {
    data: detailedDevice,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["detail-device", { id }],
    queryFn: () => detailDevice({ id }),
  });

  const operationRequests = detailedDevice?.active_operation_requests ?? [];

  const defaultOperationRequests: OperationRequestSwitchData[] = [
    { name: "clean", data: undefined },
    { name: "log", data: undefined },
  ];

  const result = useQueries({
    queries: operationRequests.map((op_request) => ({
      queryKey: ["targetConfig", op_request],
      queryFn: () => listOperationRequest({ id: op_request }),
      enabled: operationRequests.length > 0,
    })),
  });

  const operationRequestSwitchData = defaultOperationRequests.map((v) => {
    return {
      name: v.name.charAt(0).toUpperCase() + v.name.slice(1),
      data: result.find((vv) => vv.data?.operation_request === v.name)?.data || undefined,
    } as OperationRequestSwitchData;
  });

  let content = null;
  if (isLoading) {
    content = <FlexLoader />;
  } else if (detailedDevice) {
    content = (
      <DeviceViewProvider
        namedOperationRequests={operationRequestSwitchData}
        mutationSettledCallback={() => refetch()}
      >
        <DeviceViewWithData device={detailedDevice} />
      </DeviceViewProvider>
    );
  } else {
    content = <>{String(error)}</>;
  }
  return content;
};

const DeviceView: React.FC = () => {
  const { deviceId } = useParams();

  if (isNil(deviceId)) return <Navigate to={ROUTES.DEVICES} replace />;
  if (!isUUID(deviceId)) return <Navigate to="/not-found" replace />;

  return (
    <AppWrapper>
      <DeviceViewWithId id={deviceId} />
    </AppWrapper>
  );
};
export default DeviceView;
