import React from "react";
import { Chip, Tooltip } from "@mui/material";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
// TODO colors

type CropChipProps = {
  value?: string;
};
const CropChip: React.FC<CropChipProps> = ({ value }) => {
  return (
    <Tooltip title="Crop" arrow>
      <Chip icon={<SpaOutlinedIcon />} label={value ?? "Unknown"} variant="outlined" />
    </Tooltip>
  );
};

export default CropChip;
