import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDrawer } from "context/DrawerProvider";
import { Link } from "react-router-dom";
import { ReactComponent as LogoHBlack } from "icons/logo-h-black.svg";

const AppBar: React.FC = () => {
  const { setLeftDrawer } = useDrawer();
  return (
    <MuiAppBar position="fixed" color="inherit">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setLeftDrawer("nav")}
          edge="start"
          sx={{
            marginRight: 2,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flex: 1, display: "flex" }}>
          <Box
            sx={{ flex: 1, maxWidth: "10rem", display: "flex", cursor: "pointer" }}
            component={Link}
            to="/#"
          >
            <LogoHBlack />
          </Box>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
