/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "../types";

type ListGeolocationParams = {
  page: number;
  page_size: number;
  id__in: string[];
};
export type ListGeolocationItem = {
  id: string;
  created_at: number;
  longitude: number;
  latitude: number;
  is_recorded_by_user: boolean;
  device: string;
};
export const listGeolocations = async (
  params?: ListGeolocationParams
): Promise<ListResponse<ListGeolocationItem>> => {
  const { data } = await axios.get<ListResponse<ListGeolocationItem>>(
    "/api/devices/geo-locations/",
    { params }
  );
  return data;
};

export const listAllGeolocations = listAllFactory(listGeolocations);

type DetailGeolocationParams = {
  id: string;
};
export type DetailGeolocationItem = ListGeolocationItem;
export const detailGeolocation = async ({
  id,
}: DetailGeolocationParams): Promise<DetailGeolocationItem> => {
  const { data } = await axios.get<DetailGeolocationItem>(`/api/devices/geo-locations/${id}`);
  return data;
};
