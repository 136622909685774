/* eslint-disable import/prefer-default-export */
import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import React from "react";

export const Loader: React.FC<CircularProgressProps> = (props) => (
  <CircularProgress size={20} thickness={4} {...props} />
);

export const FlexLoader: React.FC<CircularProgressProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader {...props} />
    </Box>
  );
};
