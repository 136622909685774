import { useAuth } from "context/AuthProvider";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// Redirect unauthorized users to login page
const ProtectedRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <>{children}</>;
  }
  return <Navigate to="/login" replace state={{ from: location.pathname }} />;
};

export default ProtectedRoute;
