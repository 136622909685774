import useAvgMeasurements, { UseAvgMeasurementOptions } from "hooks/useAvgMeasurements";
import React from "react";
import { ChartProps } from "./Chart";
import ChartWithRQ from "./ChartWithRQ";

export const HUMIDITY_CHART_CONFIG = {
  dataKey: "humidity",
  stroke: "#299bff",
  connectNulls: true,
  strokeWidth: 3,
  fill: "#299bff99",
} as const;

export const TEMPERATURE_CHART_CONFIG = {
  dataKey: "temperature",
  stroke: "#14ac28",
  connectNulls: true,
  strokeWidth: 3,
  fill: "#14ac2899",
} as const;

const MEASUREMENT_CHART_CONFIG_ARR = [HUMIDITY_CHART_CONFIG, TEMPERATURE_CHART_CONFIG];

export type MeasurementChartProps = UseAvgMeasurementOptions &
  Pick<ChartProps<"humidity" | "temperature">, "chartConfig">;

const MeasurementChart: React.FC<MeasurementChartProps> = ({
  chartConfig = MEASUREMENT_CHART_CONFIG_ARR,
  ...props
}) => {
  const { data, error } = useAvgMeasurements(props);

  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} />;
};
export default MeasurementChart;
