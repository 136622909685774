import React from "react";
import { Chip, Tooltip } from "@mui/material";
import PestControlOutlinedIcon from "@mui/icons-material/PestControlOutlined";
// TODO colors
type PestChipProps = {
  value?: string;
};
const PestChip: React.FC<PestChipProps> = ({ value }) => {
  return (
    <Tooltip title="Pest" arrow>
      <Chip icon={<PestControlOutlinedIcon />} label={value ?? "Unknown"} variant="outlined" />
    </Tooltip>
  );
};

export default PestChip;
