import React from "react";
import { Chip, Tooltip } from "@mui/material";
import OpacityOutlinedIcon from "@mui/icons-material/OpacityOutlined";
import { isNotNil } from "utils/lodash";

// TODO: colors
type HumidityChipProps = {
  value?: number | null;
};
const HumidityChip: React.FC<HumidityChipProps> = ({ value }) => {
  return (
    <Tooltip title="Humidity" arrow>
      <Chip
        icon={<OpacityOutlinedIcon />}
        label={isNotNil(value) ? `${value.toFixed(2)}%` : "Unknown"}
        variant="outlined"
      />
    </Tooltip>
  );
};

export default HumidityChip;
