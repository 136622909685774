import React from "react";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "utils/date-fns";
import {
  AVG_MEASUREMENT_FREQUENCY,
  listAllAverageMeasurements,
} from "queries/devices/averageMeasurements";

export type UseAvgMeasurementOptions = {
  from: Date;
  to: Date;
  frequency: AVG_MEASUREMENT_FREQUENCY;
  deviceIdIn?: string[];
  enabled?: boolean;
};
const useAvgMeasurements = ({
  from,
  to,
  deviceIdIn,
  frequency,
  enabled,
}: UseAvgMeasurementOptions) => {
  const params = React.useMemo(() => {
    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);
    // this will add plus one day to 'to' parameter
    // without it, avgApi doesn't include today in result
    to.setDate(to.getDate() + 1);

    return {
      created_at__gte: from.getTime() / 1000,
      created_at__lte: to.getTime() / 1000,
      device__id__in: deviceIdIn,
      frequency,
      ordering: "timestamp",
    };
  }, [deviceIdIn, frequency, from, to]);

  const averageMeasurementsQuery = useQuery({
    queryKey: ["list-all-average-measurements", params],
    queryFn: async () => (await listAllAverageMeasurements(params)).data,
    enabled,
  });

  const data = React.useMemo(
    () =>
      Object.fromEntries(
        (averageMeasurementsQuery.data ?? []).map((it) => [
          formatDate(new Date(it.timestamp * 1000), true),
          {
            temperature: it.avg_temperature,
            humidity: it.avg_humidity,
            battery_voltage: it.avg_battery_voltage,
            solar_cell_voltage: it.avg_solar_cell_voltage,
          },
        ])
      ),
    [averageMeasurementsQuery.data]
  );

  return {
    data,
    error: averageMeasurementsQuery.error,
    isLoading: averageMeasurementsQuery.isLoading,
  };
};

export default useAvgMeasurements;
