import axios from "axios";

export type TargetConfigType = {
  newTargetConfigId: string;
  device: {
    ids: string[];
    filters: Record<string, string[] | null>[];
  };
};

export const patchTargetConfig = async (body: TargetConfigType): Promise<{ devices: number }> => {
  const { data } = await axios.patch<{ devices: number }>("/api/devices/target-config", body);
  return data;
};
