import axios from "axios";
import { ListResponse } from "queries/types";
import { Aggregations, listAllFactory } from "utils/query";

type OrderingParams =
  | "timestamp"
  | "-timestamp"
  | "avg_detection_count"
  | "-avg_detection_count"
  | "avg_detection_count_delta"
  | "-avg_detection_count_delta";

type ListAverageDetectionCountParams = {
  page: number;
  page_size: number;
  id__in?: string[];
  device__id?: string;
  device__id__in?: string[];
  frequency?: string;
  ordering?: OrderingParams | OrderingParams[];
};

type ByDeviceParams = {
  page: number;
  page_size: number;
  device_ids: string[];
  aggregations: Aggregations;
};

type ByOrchardParams = {
  page: number;
  page_size: number;
  orchard_ids: string[];
  aggregations: Aggregations;
};

export type ListAverageDetectionCountItem = {
  timestamp: number;
  avg_detection_count: number | null;
  avg_detection_count_delta: number | null;
};

export type DcDcdTimestampType = {
  timestamp: number;
  detection_count: number | null;
  detection_count_delta: number | null;
};

export type AvgDetectionCountsByItem = {
  id: string;
  days: DcDcdTimestampType[];
  weeks: DcDcdTimestampType[];
};

export type NewDetectionCountsByItem = {
  id: string;
  times: DcDcdTimestampType[];
};

export type DetectionCountsByItem = {
  id: string;
  days: DcDcdTimestampType[];
  weeks: DcDcdTimestampType[];
};

export const listAverageDetectionCounts = async (
  params?: ListAverageDetectionCountParams
): Promise<ListResponse<ListAverageDetectionCountItem>> => {
  const { data } = await axios.get<ListResponse<ListAverageDetectionCountItem>>(
    "/api/devices/average-detection-counts/",
    { params }
  );
  return data;
};

export const listDetectionCountsByDevice = async (
  params?: ByDeviceParams
): Promise<ListResponse<NewDetectionCountsByItem>> => {
  const { aggregations, ...rest } = params!;
  const { data } = await axios.get<ListResponse<NewDetectionCountsByItem>>(
    `/api/devices/detection-counts-by-device-ids/${aggregations}/`,
    { params: rest }
  );
  return data;
};

export const listDetectionCountsByOrchard = async (
  params?: ByOrchardParams
): Promise<ListResponse<NewDetectionCountsByItem>> => {
  const { aggregations, ...rest } = params!;
  const { data } = await axios.get<ListResponse<NewDetectionCountsByItem>>(
    `/api/devices/detection-counts-by-orchard-ids/${aggregations}/`,
    { params: rest }
  );
  return data;
};

export type ListSummationDetectionCountItem = {
  id: string;
  timestamp: number;
  sum_detection_count: number | null;
  sum_detection_count_delta: number | null;
};

export const listSummationDetectionCounts = async (
  params?: ListAverageDetectionCountParams
): Promise<ListResponse<ListSummationDetectionCountItem>> => {
  const { data } = await axios.get<ListResponse<ListSummationDetectionCountItem>>(
    "/api/devices/summation-detection-counts/",
    { params }
  );
  return data;
};

export type ListOneSevenDayDcDItem = {
  id: string;
  last_validation: number | null;
  last: {
    dc: number;
    dcd: number;
  };
  day: {
    dc: number;
    dcd: number;
  };
  week: {
    dc: number;
    dcd: number;
  };
};

type ListOneSevenDayDcdParams = {
  page: number;
  page_size: number;
  device_ids?: string[];
};

export const listOneSevenDayDcd = async (
  params?: ListOneSevenDayDcdParams
): Promise<ListResponse<ListOneSevenDayDcDItem>> => {
  const { data } = await axios.get<ListResponse<ListOneSevenDayDcDItem>>(
    "/api/devices/one-and-seven-day-detection-counts",
    { params }
  );
  return data;
};

export type OneSevenDayAvgDcdItem = {
  id: string;
  avg_day: { dc: number; dcd: number };
  avg_week: { dc: number; dcd: number };
};

type OneSevenDayAvgDcdParams = {
  page: number;
  page_size: number;
  orchard_ids?: string[];
};

export const listOneSevenDayAvgDcd = async (
  params?: OneSevenDayAvgDcdParams
): Promise<ListResponse<OneSevenDayAvgDcdItem>> => {
  const { data } = await axios.get<ListResponse<OneSevenDayAvgDcdItem>>(
    "/api/devices/one-and-seven-day-detection-counts/avg-by-orchards",
    { params }
  );
  return data;
};

export const listAllAverageDetectionCounts = listAllFactory(listAverageDetectionCounts);

export const listAllDetectionCountsByDevice = listAllFactory(listDetectionCountsByDevice);
export const listAllDetectionCountsByOrchard = listAllFactory(listDetectionCountsByOrchard);

export const listAllSummationDetectionCounts = listAllFactory(listSummationDetectionCounts);
export const listAllOneSevenDayDcd = listAllFactory(listOneSevenDayDcd);
export const listAllOneSevenDayAVGDCD = listAllFactory(listOneSevenDayAvgDcd);
