/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "../types";

type ListNetworkDiagnosticParams = {
  page: number;
  page_size: number;
  id__in: string[];
};
export type ListNetworkDiagnosticItem = {
  id: string;
  created_at: number;
  service: string;
  operator: string;
  band: string;
  channel: string;
  cell: string;
  rssi: number;
  ip: string;
  device: string;
};
export const listNetworkDiagnostics = async (
  params?: ListNetworkDiagnosticParams
): Promise<ListResponse<ListNetworkDiagnosticItem>> => {
  const { data } = await axios.get<ListResponse<ListNetworkDiagnosticItem>>(
    "/api/devices/network-diagnostics",
    { params }
  );
  return data;
};

export const listAllNetworkDiagnostics = listAllFactory(listNetworkDiagnostics);

type DetailNetworkDiagnosticParams = {
  id: string;
};
export type DetailNetworkDiagnosticItem = ListNetworkDiagnosticItem;
export const detailNetworkDiagnostic = async ({
  id,
}: DetailNetworkDiagnosticParams): Promise<DetailNetworkDiagnosticItem> => {
  const { data } = await axios.get<DetailNetworkDiagnosticItem>(
    `/api/devices/network-diagnostics/${id}/`
  );
  return data;
};
