import React from "react";
import { Box, Card } from "@mui/material";
import { ListDeviceItem } from "queries/devices";
import DeviceTable from "./deviceTable";
import TargetDevices from "./targetDevices";
import CorpTable from "./corpTable";

const DeviceManagement = () => {
  const [devices, setDevices] = React.useState<ListDeviceItem[]>([]);
  return (
    <Box>
      <Card sx={{ display: "flex", flexFlow: "column", p: 1, m: 1, gap: 4, flex: 5 }}>
        <DeviceTable setDevices={setDevices} />
        <Box sx={{ display: "flex", flexFlow: "row", gap: 4 }}>
          <TargetDevices devices={devices} setDevices={setDevices} />
          <CorpTable />
        </Box>
      </Card>
    </Box>
  );
};

export default DeviceManagement;
