import React, { ReactNode } from "react";
import { Card, CardContent, CardProps, Typography, styled } from "@mui/material";

type MyCardProps = {
  title: string;
  children: ReactNode;
} & CardProps;

const CustomCard = styled(Card)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  border: "1px solid",
  borderColor: theme.palette.divider,
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-10px",
    left: "10px",
    backgroundColor: theme.palette.background.paper,
    padding: "0 5px",
  },
}));

const CustomCardLabel = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "-5px",
  left: "5px",
  backgroundColor: theme.palette.background.paper,
  // padding: '0 5px',
  padding: 5,
}));

const CardWithTitle: React.FC<MyCardProps> = ({ title, children, ...props }) => {
  return (
    <CustomCard {...props}>
      <CustomCardLabel variant="subtitle2">{title}</CustomCardLabel>
      <CardContent>{children}</CardContent>
    </CustomCard>
  );
};

export default CardWithTitle;
