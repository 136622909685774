/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "./types";
import { ListUserItem } from "./users";

type ListOrchardParams = {
  page: number;
  page_size: number;
  id__in?: string[];
  user_id__in?: string[];
};
export type ListOrchardItem = {
  id: string;
  created_at: number;
  name: string;
  user: { id: string; name: string };
};
export const listOrchards = async (
  params?: ListOrchardParams
): Promise<ListResponse<ListOrchardItem>> => {
  const { data } = await axios.get<ListResponse<ListOrchardItem>>("/api/orchard/", { params });
  return data;
};

export const listAllOrchards = listAllFactory(listOrchards);

type DetailOrchardParams = {
  id: string;
};
export type DetailOrchardItem = ListOrchardItem;
export const detailOrchard = async ({ id }: DetailOrchardParams): Promise<DetailOrchardItem> => {
  const { data } = await axios.get<DetailOrchardItem>(`/api/orchard/${id}`);
  return data;
};

type CreateOrchardParams = {
  name: string;
  // user: string;
  devices_to_add: string[];
};
export const createOrchard = async (params: CreateOrchardParams): Promise<DetailOrchardItem> => {
  const { data } = await axios.post<DetailOrchardItem>("/api/orchard/", params);
  return data;
};

export type OrchardParams = {
  name: string;
  devices_to_add?: string[];
  devices_to_remove?: string[];
};
type UpdateOrchardParams = {
  id: string;
  params: OrchardParams;
};
export const updateOrchard = async ({
  id,
  params,
}: UpdateOrchardParams): Promise<DetailOrchardItem> => {
  const { data } = await axios.patch<DetailOrchardItem>(`/api/orchard/${id}`, params);
  return data;
};

export const deleteOrchard = async (id: string): Promise<DetailOrchardItem> => {
  const { data } = await axios.delete<DetailOrchardItem>(`/api/orchard/${id}`);
  return data;
};
