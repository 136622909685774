import axios from "axios";
import CONFIG from "config";
import { API_ROUTES } from "routes";
import set from "lodash/set";
import qs from "qs";
import * as Sentry from "@sentry/react";
import { refreshToken } from "queries/auth";
import { getAccessToken, getRefreshToken, setTokens } from "./token";

axios.defaults.baseURL = CONFIG.baseUrl;
const PUBLIC_PATHS = [API_ROUTES.ACCESS_TOKEN, API_ROUTES.REFRESH_TOKEN];

axios.interceptors.request.use(
  async (config) => {
    // add trailing slash to url
    if (config.url === undefined) config.url = "/";
    else if (!config.url.endsWith("/")) config.url = `${config.url}/`;

    // configuring array format
    config.paramsSerializer = {
      serialize: (params) => qs.stringify(params, { arrayFormat: "comma" }),
    };

    // don't add Authorization header to public routes
    if (PUBLIC_PATHS.includes(config.url)) {
      return config;
    }

    // refresh token if there is no access token
    if (!getAccessToken()) {
      const refresh = getRefreshToken();
      if (refresh) {
        await refreshToken({ refresh }).then((res) => setTokens(res));
      }
    }

    // add Authorization header to requests with access token
    const access = getAccessToken();
    if (access) {
      set(config, "headers.Authorization", `Bearer ${access}`);
    }

    return config;
  },
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);
