import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import React from "react";
import { CurrentDeviceConfigType } from "./Cards/Contents/utils/types";
import Filter from "./Cards/Filter";
import Template from "./Cards/Template";

const openButtonStyle = {
  position: "absolute",
  backgroundColor: "white",
  left: 0,
  height: "75%",
  minWidth: "0px",
  width: "5px",
  p: 1,
};

const DeviceConfigMain: React.FC = () => {
  // const [debugPanel, setDebugPanel] = React.useState([""]);
  const [currentDeviceConfig, setCurrentDeviceConfig] = React.useState<CurrentDeviceConfigType>({
    id: "",
    name: "",
  } as CurrentDeviceConfigType);

  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        sx={openButtonStyle}
        onClick={() => {
          setIsOpen((pre) => !pre);
        }}
      >
        <ArrowForwardIosIcon fontSize="inherit" />
      </Button>
      <Box
        sx={{
          display: `${isOpen ? "flex" : "contents"}`,
          alignContent: "space-between",
          width: "100%",
          height: "100%",
          margin: 0,
        }}
      >
        <Template
          // setPopup={setDebugPanel}
          open={isOpen}
          currentDeviceConfigState={[currentDeviceConfig, setCurrentDeviceConfig]}
        />

        <Filter currentDeviceConfig={currentDeviceConfig} />
      </Box>
    </>
  );
};

export default DeviceConfigMain;
