import React from "react";
import { Box } from "@mui/material";
import { sub } from "date-fns";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";

export type DateRange = { from: Date; to: Date };

const schema = Yup.object({
  from: Yup.date().required().max(Yup.ref("to")),
  to: Yup.date().required().min(Yup.ref("to")),
});

type UseDateRangeParams = {
  defaultValues?: Partial<DateRange>;
  defaultDuration?: Duration;
};
const useDateRange = ({
  defaultValues = {},
  defaultDuration = { days: 30 },
}: UseDateRangeParams) => {
  const [dateRange, setDateRange] = React.useState<DateRange>({
    from: defaultValues.from ?? sub(new Date(), defaultDuration),
    to: defaultValues.to ?? new Date(),
  });
  const { handleSubmit, control, watch } = useForm<DateRange>({
    defaultValues: dateRange,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<DateRange> = React.useCallback((data) => {
    setDateRange({ ...data });
  }, []);

  const debouncedSubmit = React.useMemo(
    () => debounce(handleSubmit(onSubmit), 500),
    [handleSubmit, onSubmit]
  );

  React.useEffect(() => {
    const subscription = watch(() => {
      debouncedSubmit();
    });
    return () => subscription.unsubscribe();
  }, [debouncedSubmit, watch]);

  return {
    dateRange,
    form: (
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ display: "flex", alignItems: "center" }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Controller
          control={control}
          name="from"
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <DatePicker
              label="From"
              value={value}
              onChange={(value) => onChange(value)}
              inputRef={ref}
              maxDate={new Date()}
              slotProps={{
                textField: {
                  size: "small",
                  error: !!error,
                  helperText: error?.message,
                },
              }}
              sx={{ mr: 2 }}
            />
          )}
        />
        <Controller
          control={control}
          name="to"
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <DatePicker
              label="To"
              value={value}
              onChange={(value) => onChange(value)}
              inputRef={ref}
              maxDate={new Date()}
              slotProps={{
                textField: {
                  size: "small",
                  error: !!error,
                  helperText: error?.message,
                },
              }}
            />
          )}
        />
      </Box>
    ),
  };
};

export default useDateRange;
