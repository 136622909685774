import React from "react";
import { Chip, Tooltip } from "@mui/material";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import { isNotNil } from "utils/lodash";

// TODO: colors
type SolarCellVoltageChipProps = {
  value?: number | null;
};
const SolarCellVoltageChip: React.FC<SolarCellVoltageChipProps> = ({ value }) => {
  return (
    <Tooltip title="Solar cell voltage" arrow>
      <Chip
        icon={<OfflineBoltOutlinedIcon />}
        label={isNotNil(value) ? `${value.toFixed(2)} V` : "Unknown"}
        variant="outlined"
      />
    </Tooltip>
  );
};

export default SolarCellVoltageChip;
