/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "./types";

export type ListPestParams = {
  page: number;
  page_size: number;
  id__in?: string[];
};
export type ListPestItem = {
  id: string;
  created_at: number;
  updated_at: number;
  comment: string | null;
  name: string;
};
export const listPests = async (params?: ListPestParams): Promise<ListResponse<ListPestItem>> => {
  const { data } = await axios.get<ListResponse<ListPestItem>>("/api/pests/", { params });
  return data;
};

export const listAllPests = listAllFactory(listPests);

export type DetailPestParams = {
  id: string;
};
export type DetailPestItem = ListPestItem;
export const detailPest = async ({ id }: DetailPestParams): Promise<DetailPestItem> => {
  const { data } = await axios.get<DetailPestItem>(`/api/pests/${id}/`);
  return data;
};
