import { THEME_MODE, useUserPreferences } from "context/UserPreferencesProvider";
import React from "react";

const LIGHT_BACKGROUND_PROPS = {
  // backgroundColor: "#fafafa",
  backgroundImage: "radial-gradient(#e9e9e9 20%,#fafafa 0)",
  backgroundPosition: "50%",
  backgroundSize: "10px 10px",
  backgroundRepeat: "repeat",
} as const;

const DARK_BACKGROUND_PROPS = {
  // backgroundColor: "#fafafa",
  backgroundImage: "radial-gradient(#484848 20%,#1c1c1c 0)",
  backgroundPosition: "50%",
  backgroundSize: "10px 10px",
  backgroundRepeat: "repeat",
} as const;

const useBackgroundProps = () => {
  const { directThemeMode } = useUserPreferences();
  return React.useMemo(
    () => (directThemeMode === THEME_MODE.LIGHT ? LIGHT_BACKGROUND_PROPS : DARK_BACKGROUND_PROPS),
    [directThemeMode]
  );
};

export default useBackgroundProps;
