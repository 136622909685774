import NothingToShow from "components/NothingToShow";
import React from "react";
import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import TabPanel from "components/TabPanel";
import { IMAGE_TYPES, ListImageItem, listImages } from "queries/devices/images";
import Image from "components/Image";
import LoadingImage from "components/Image/LoadingImage";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { ListDeviceItem } from "queries/devices";
import { format } from "utils/date-fns";

type PreviousImageProps = {
  imageQuery: UseQueryResult<ListImageItem[], unknown>;
  device: ListDeviceItem;
};
const PreviousImage: React.FC<PreviousImageProps> = ({ imageQuery, device }) => {
  const [value, setValue] = React.useState(0);

  const currentTimestamp = imageQuery.data?.[0]?.created_at;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const params = React.useMemo(
    () => ({
      device__id: device.id,
      page: 1,
      page_size: 1,
      ordering: "-created_at" as const,
      created_at__lt: currentTimestamp,
      is_validated: true,
    }),
    [currentTimestamp, device.id]
  );

  const lastValidatedImageQuery = useQuery({
    queryKey: ["list-last-validated-images", params],
    queryFn: async () => (await listImages(params)).data,
  });

  const data = lastValidatedImageQuery.data?.[0];
  const { isLoading } = lastValidatedImageQuery;

  let content = <NothingToShow />;
  if (isLoading) {
    content = <LoadingImage />;
  } else if (value === 0 && data?.validated_blob_name) {
    content = (
      <>
        <Typography> {format(new Date(data.created_at * 1000))} </Typography>
        <Image id={data.id} type={IMAGE_TYPES.validated} />
      </>
    );
  }

  return (
    <Grid item sm={12} lg={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              label="Previous validated"
              id="tabcontrol-prev-validated-image"
              aria-controls="tabpanel-prev-validated-image"
              disabled={!isLoading && !data?.validated_blob_name}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={value}
          index={0}
          id="tabpanel-prev-validated-image"
          aria-labelledby="tabcontrol-prev-validated-image"
        >
          {content}
        </TabPanel>
      </Paper>
    </Grid>
  );
};

export default PreviousImage;
