import React from "react";
import { Dialog, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { detailUser } from "queries/users";

import UpdatePasswordDialogTitle from "./UpdatePasswordDialogTitle";
import UpdatePasswordDialogForm from "./UpdatePasswordDialogForm";
import UpdatePasswordDialogFormLoader from "./UpdatePasswordDialogFormLoader";

type UpdatePasswordDialogProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

const INITIAL_VALUES = { currentPassword: "", newPassword: "", confirmNewPassword: "" };
const UpdatePasswordDialog: React.FC<UpdatePasswordDialogProps> = ({ id, isOpen, onClose }) => {
  const userQuery = useQuery({
    queryKey: ["detail-user", { id }],
    queryFn: () => detailUser({ id: id as string }),
    enabled: !!id,
  });

  const userId = userQuery.data?.id;

  let content = null;
  if (userQuery.error) {
    content = <Typography>{String(userQuery.error)}</Typography>;
  } else if (userId) {
    content = (
      <UpdatePasswordDialogForm
        handleSuccess={onClose}
        initialValues={INITIAL_VALUES}
        id={userId}
        actionsProps={{ handleCancelClick: onClose }}
      />
    );
  } else {
    content = <UpdatePasswordDialogFormLoader actionsProps={{ handleCancelClick: onClose }} />;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <UpdatePasswordDialogTitle userQuery={userQuery} onClose={onClose} />
      {content}
    </Dialog>
  );
};

export default UpdatePasswordDialog;
