import React from "react";
import { Chip, Tooltip } from "@mui/material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// TODO colors

type CommissionStatusChipProps = {
  value?: string;
};
const CommissionStatusChip: React.FC<CommissionStatusChipProps> = ({ value }) => {
  return (
    <Tooltip title="Commission status" arrow>
      <Chip icon={<LocalShippingOutlinedIcon />} label={value ?? "Unknown"} variant="outlined" />
    </Tooltip>
  );
};

export default CommissionStatusChip;
