import { Box, Grid, IconButton, Menu, MenuItem, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import { IMAGE_TYPES, ListImageItem } from "queries/devices/images";
import NothingToShow from "components/NothingToShow";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Image from "components/Image";
import LoadingImage from "components/Image/LoadingImage";

type DeltaImageProps = {
  isLoading?: boolean;
  data?: ListImageItem;
};

const DeltaImage: React.FC<DeltaImageProps> = ({ isLoading, data }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [editBaseType, setEditBaseType] = React.useState<IMAGE_TYPES | undefined>(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let content = <NothingToShow />;
  if (isLoading) {
    content = <LoadingImage />;
  } else if (data && (editBaseType || data?.delta_blob_name)) {
    content = (
      <Image
        id={data.id}
        type={editBaseType ?? IMAGE_TYPES.delta}
        saveAs={IMAGE_TYPES.delta}
        isEditing={!!editBaseType}
        onClose={() => {
          setEditBaseType(undefined);
        }}
      />
    );
  }

  return (
    <Grid item sm={12} lg={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="overline" component="div" sx={{ flex: 1 }}>
            Current delta
          </Typography>
          {!editBaseType && (
            <>
              <Tooltip title="Create current delta..." arrow>
                <IconButton
                  id="button-create-delta"
                  aria-controls={open ? "menu-create-delta" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-create-delta"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "button-create-delta",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.raw);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.raw_blob_name}
                  aria-disabled={!data?.raw_blob_name}
                >
                  From current raw
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.processed);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.processed_blob_name}
                  aria-disabled={!data?.processed_blob_name}
                >
                  From current processed
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.delta);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.delta_blob_name}
                  aria-disabled={!data?.delta_blob_name}
                >
                  From current delta
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.validated);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.validated_blob_name}
                  aria-disabled={!data?.validated_blob_name}
                >
                  From current validated
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
        {content}
      </Paper>
    </Grid>
  );
};

export default DeltaImage;
