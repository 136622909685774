import React from "react";
import { Chip, Tooltip } from "@mui/material";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import isNumber from "lodash/isNumber";

type DetectionCountDeltaChipProps = {
  value?: number | null;
};

const DetectionCountDeltaChip: React.FC<DetectionCountDeltaChipProps> = ({ value }) => {
  let label = null;

  if (isNumber(value)) label = value;

  return (
    <Tooltip title="Detection count delta" arrow>
      <Chip icon={<ChangeHistoryOutlinedIcon />} label={label} variant="outlined" />
    </Tooltip>
  );
};

export default DetectionCountDeltaChip;
