/* eslint-disable import/prefer-default-export */
import parseISO from "date-fns/parseISO";
import intlFormat from "date-fns/intlFormat";
import CONFIG from "config";
import isString from "lodash/isString";

export function format(value: string): string;
export function format(value: Date): string;
export function format(value: Date | string): string {
  const date = isString(value) ? parseISO(value) : value;
  return intlFormat(
    date,
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    },
    { locale: CONFIG.locale }
  );
}

export function formatDate(value: string, useShortFormat?: boolean): string;
export function formatDate(value: Date, useShortFormat?: boolean): string;
export function formatDate(value: Date | string, useShortFormat?: boolean): string {
  const date = isString(value) ? parseISO(value) : value;
  return intlFormat(
    date,
    {
      year: useShortFormat ? undefined : "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    { locale: CONFIG.locale }
  );
}

export const offsetInSeconds = (timeZone: string) => {
  const date = new Date();
  const offsetInSeconds = Math.floor(
    (new Date(date.toLocaleString("en-US", { timeZone })).getTime() - date.getTime()) / 1000
  );
  return offsetInSeconds;
};
