import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { Loader } from "components/Loader";
import { debounce, flatten } from "lodash";
import { ListDeviceItem, ListDeviceParams, listDevices } from "queries/devices";
import { listAllDeviceOrchards, ListDeviceOrchardItem } from "queries/devices/orchards";
import {
  createOrchard,
  deleteOrchard,
  listAllOrchards,
  ListOrchardItem,
  OrchardParams,
  updateOrchard,
} from "queries/orchards";
import React from "react";
import { changePageTitle, smappAssert } from "utils/commons";
import { Item } from "../main/common";
import {
  ActionType,
  DeleteOrchardPopUpParams,
  ItemActionType,
  Orchard,
  OrchardCardItemParams,
  OrchardListDeviceParams,
} from "./types";

const INFINITE_PAGE_SIZE = 20;

const ItemAction = {
  delete: ({ actionHandler, id }: ItemActionType) => (
    <IconButton
      onClick={() => {
        actionHandler("devices_to_remove", id);
      }}
    >
      <DeleteIcon style={{ fontSize: 20 }} />
    </IconButton>
  ),
  add: ({ actionHandler, id }: ItemActionType) => (
    <IconButton
      onClick={() => {
        actionHandler("devices_to_add", id);
      }}
    >
      <AddIcon style={{ fontSize: 20 }} />
    </IconButton>
  ),
};

const ListDevices = ({
  title,
  devices,
  fetchNextPage,
  hasNextPage,
  extraElement,
  actionName,
  actionHandler,
}: OrchardListDeviceParams) => {
  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <FormLabel
        style={{
          backgroundColor: "white",
        }}
      >
        {title && <Typography variant="subtitle1">{title}</Typography>}
      </FormLabel>
      {extraElement && extraElement}
      <Grid
        container
        sx={{
          maxHeight: "25vh",
          overflowY: "auto",
          scrollbarWidth: "thin",
          alignItems: "flex-start",
        }}
      >
        {devices.map((v) => {
          return (
            <Grid
              item
              sx={{
                borderRadius: "5px",
                height: "fit-content",
                p: 0.5,
                fontSize: "small",
              }}
              key={v.id}
            >
              <Item sx={{ color: "ButtonText" }}>
                {v.name ? `${v.name} (${v.smapp_id})` : v.smapp_id}
                {actionName &&
                  actionHandler &&
                  ItemAction[actionName]({ actionHandler, id: v.smapp_id })}
              </Item>
            </Grid>
          );
        })}
      </Grid>

      {fetchNextPage && (
        <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "center" }}>
          <Button
            variant="contained"
            disabled={!(hasNextPage && hasNextPage)}
            sx={{
              width: "25%",
            }}
            onClick={() => fetchNextPage()}
          >
            Show more traps
          </Button>
        </Box>
      )}
    </Box>
  );
};

const DeleteOrchardPopUp = ({ orchard, handleDelete }: DeleteOrchardPopUpParams) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const contentText = (
    <Typography component="div">
      You are about to delete a trap group{" "}
      <Box display="inline" sx={{ fontWeight: "bold" }}>
        {orchard.name}
      </Box>
      .
      <br />
      The traps within this trap group will remain assigned to you and can be found in the available
      traps section.
    </Typography>
  );

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Trap Group</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDelete(orchard);
              handleClose();
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const OrchardCardItem = ({
  orchardMutated,
  onEdit,
  onAddNewOrchardClose,
  orchard,
  allDeviceOrchards,
}: OrchardCardItemParams) => {
  const [orchardName, setOrchardName] = React.useState<string | null>(null);
  const [deviceSearch, setDeviceSearch] = React.useState<string | null>(null);
  const [deviceListForCreateOrchard, setDeviceListForCreateOrchard] = React.useState<
    ListDeviceItem[]
  >([]);

  const {
    data: infData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["inf-list-devices-for-orchard-manager", deviceSearch],
    queryFn: async ({ pageParam = 1 }) => {
      const listDeviceParams = {
        page: pageParam,
        page_size: INFINITE_PAGE_SIZE,
        active: true,
      } as ListDeviceParams;
      if (deviceSearch && deviceSearch.length > 0) {
        listDeviceParams.search = deviceSearch;
      }
      return listDevices(listDeviceParams);
    },
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const deviceList = React.useMemo(
    () => flatten((infData?.pages ?? [])?.map((it) => it.data)),
    [infData]
  );

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const isCreate = orchard.id === null;

  const createOrchardMutation = useMutation({
    mutationFn: createOrchard,
    onSuccess: () => {
      orchardMutated();
    },
  });

  const updateOrchardMutation = useMutation({
    mutationFn: updateOrchard,
    onSuccess: () => {
      orchardMutated();
    },
  });

  const deleteOrchardMutation = useMutation({
    mutationFn: deleteOrchard,
    onSuccess: () => {
      orchardMutated();
    },
  });

  const onOrchardNameChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setOrchardName(value);
      }, 600),
    []
  );

  const onDeviceSearchChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceSearch(value);
      }, 600),
    []
  );

  let orchardsTraps = allDeviceOrchards
    .filter((v) => v.orchard.id === orchard.id)
    .map((v) => v.device)
    .sort((a, b) => a.smapp_id.localeCompare(b.smapp_id));
  let availableTraps = deviceList.filter(
    (v) => orchardsTraps.filter((vv) => vv.smapp_id === v.smapp_id).length === 0
  );
  if (isCreate) {
    orchardsTraps = deviceListForCreateOrchard;
    availableTraps = deviceList.filter(
      (v) => orchardsTraps.filter((vv) => vv.smapp_id === v.smapp_id).length === 0
    );
  }

  const handleOrchardDelete = (orchard: Orchard) => {
    smappAssert(orchard.id !== null, "Orchard id should not null, when deleting orchard");
    deleteOrchardMutation.mutate(orchard.id);
  };

  const handleOrchardNameSave = (orchard: Orchard) => {
    smappAssert(
      orchard.id !== null && orchard.name !== null,
      "Orchard id and name should not null, when name saving"
    );
    updateOrchardMutation.mutate({
      id: orchard.id,
      params: { name: orchard.name },
    });
    setOrchardName(null);
  };

  const handleOrchardCreation = () => {
    smappAssert(orchardName !== null, "orchardName should not null, when handleOrchardCreation");
    createOrchardMutation.mutate({
      name: orchardName,
      devices_to_add: deviceListForCreateOrchard.map((v) => v.smapp_id),
    });
    setOrchardName(null);
    setDeviceListForCreateOrchard([]);
  };

  const handleActionChange = (orchardID: string | null, action: ActionType, smappID: string) => {
    // when orchardID is not null, then we will update orchards
    if (orchardID) {
      const params = {} as OrchardParams;
      params[action] = [smappID];
      updateOrchardMutation.mutate({ id: orchardID, params });
      return;
    }
    const device = availableTraps.find((v) => v.smapp_id === smappID);
    if (device && action === "devices_to_add") {
      setDeviceListForCreateOrchard((pre) => [...pre, device]);
    }
    if (action === "devices_to_remove") {
      setDeviceListForCreateOrchard((pre) => pre.filter((v) => v.smapp_id !== smappID));
    }
  };

  const cleanUpOnClose = () => {
    if (onAddNewOrchardClose) onAddNewOrchardClose();
    setOrchardName(null);
    setDeviceListForCreateOrchard([]);
  };

  let orchardMain = <ListDevices devices={orchardsTraps} title={undefined} />;
  let orchardDisplayName = orchard ? <Typography variant="h6">{orchard.name}</Typography> : "";
  let saveOrCreateButton: React.JSX.Element | undefined;
  let headerButtons = (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Button
        onClick={() => {
          setIsEdit((v) => !v);
          onEdit && onEdit(!isEdit);
        }}
        variant="outlined"
      >
        <EditIcon />
      </Button>
      <DeleteOrchardPopUp orchard={orchard} handleDelete={handleOrchardDelete} />
    </Box>
  );

  if (isEdit) {
    saveOrCreateButton = (
      <Button
        disabled={orchardName === null || orchardName.length === 0}
        variant="contained"
        onClick={() => {
          handleOrchardNameSave({ id: orchard.id, name: orchardName! });
        }}
      >
        Save
      </Button>
    );
  }

  if (isCreate) {
    headerButtons = (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button variant="contained" onClick={cleanUpOnClose}>
          Cancel
        </Button>
        <Button
          disabled={deviceListForCreateOrchard.length === 0 || orchardName === null}
          variant="contained"
          onClick={() => {
            handleOrchardCreation();
          }}
        >
          Save / Create trap group
        </Button>
      </Box>
    );
  }

  if (isEdit || isCreate) {
    orchardDisplayName = (
      <Box sx={{ pt: 2, display: "flex", gap: 1 }}>
        <TextField
          defaultValue={orchard.name}
          size="small"
          id="outlined-basic"
          label="Trap group name"
          variant="outlined"
          onChange={onOrchardNameChange}
          // TODO: use this if save button is confusing for useres
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton
          //         edge="end"
          //         color="primary"
          //         disabled={orchardName === null || orchardName.length === 0}
          //         onClick={() => {
          //           handleOrchardNameSave({ id: orchard.id, name: orchardName! });
          //         }}
          //       >
          //         <SaveIcon />
          //       </IconButton>
          //     </InputAdornment>
          //   ),
          // }}
        />
        {saveOrCreateButton}
      </Box>
    );
    orchardMain = (
      <>
        <ListDevices
          actionName="delete"
          title="Traps in trap group"
          devices={orchardsTraps}
          actionHandler={(action: ActionType, smappID: string) => {
            handleActionChange(orchard.id, action, smappID);
          }}
        />

        <ListDevices
          actionName="add"
          title="Available traps"
          devices={availableTraps}
          extraElement={
            <TextField
              sx={{ width: "25%", mt: 1, mb: 1 }}
              // sx={{ width: "25%" }}
              size="small"
              id="outlined-basic"
              label="Search for available traps ..."
              variant="outlined"
              onChange={onDeviceSearchChange}
            />
          }
          actionHandler={(action: ActionType, smappID: string) => {
            handleActionChange(orchard.id, action, smappID);
          }}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </>
    );
  }

  return (
    <Card sx={{ p: 1, display: "flex", flexFlow: "column" }}>
      <Box className="OrchardCardItemHeader" sx={{ display: "flex", flexFlow: "row" }}>
        {orchardDisplayName}
        <Box className="OrchardCardItemHeader" sx={{ marginLeft: "auto" }}>
          {headerButtons}
        </Box>
      </Box>
      <Box className="OrchardCardItemMain">
        <Box sx={{ display: "flex", flexDirection: "column" }}>{orchardMain}</Box>
      </Box>
    </Card>
  );
};

const OrchardManagerMain = () => {
  const [isCreateOpen, setIsCreateOpen] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState("");

  const { data: orchards, refetch: refretchOrchards } = useQuery({
    queryFn: () => listAllOrchards(),
    queryKey: ["list-all-orchards"],
  });

  const {
    data: deviceOrchards,
    refetch: refretchDeviceOrchards,
    isLoading,
  } = useQuery({
    queryFn: () => listAllDeviceOrchards(),
    queryKey: ["list-all-device-orchards"],
  });

  const allDeviceOrchards = deviceOrchards?.data || ([] as ListDeviceOrchardItem[]);
  const allOrchards = orchards?.data || ([] as ListOrchardItem[]);

  let createOrchard = null;
  if (isCreateOpen) {
    changePageTitle("Trap Group Management - New trap group");
    createOrchard = (
      <OrchardCardItem
        onAddNewOrchardClose={() => {
          changePageTitle(pageTitle);
          setIsCreateOpen(false);
        }}
        orchardMutated={() => {
          refretchOrchards();
          refretchDeviceOrchards();
          changePageTitle(pageTitle);
          setIsCreateOpen(false);
        }}
        orchard={{ id: null, name: null }}
        allDeviceOrchards={allDeviceOrchards}
      />
    );
  }

  const title =
    allOrchards.length !== 0
      ? "Trap Group Management - Trap group list"
      : "Trap Group Management - No trap groups yet";

  React.useEffect(() => {
    setPageTitle(title);
  }, [title]);

  React.useEffect(() => {
    changePageTitle(pageTitle);
  }, [pageTitle]);

  const addNewOrchardButton = (
    <Button
      onClick={() => setIsCreateOpen((v) => !v)}
      sx={{ marginLeft: "auto" }}
      variant="contained"
    >
      Add new trap group
    </Button>
  );

  let orchardCardItems = null;
  if (allOrchards.length !== 0) {
    orchardCardItems = (
      <>
        {allOrchards.map((orchard) => {
          return (
            <OrchardCardItem
              onEdit={(isEdit) => {
                const title = isEdit ? "Trap Group Management - Edit trap group" : pageTitle;
                changePageTitle(title);
              }}
              orchardMutated={() => {
                refretchOrchards();
                refretchDeviceOrchards();
              }}
              key={orchard.id}
              orchard={orchard}
              allDeviceOrchards={allDeviceOrchards}
            />
          );
        })}
      </>
    );
  } else {
    orchardCardItems = (
      <Box
        sx={{
          display: "flex",
          height: "500px",
          gap: 2,
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {(!isLoading && (
          <>
            <HourglassEmptyIcon sx={{ fontSize: "200px" }} />
            <Typography variant="subtitle1">There are no trap groups yet</Typography>
            {React.cloneElement(addNewOrchardButton, { sx: { fontSize: 10 } })}
          </>
        )) || <Loader />}
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", gap: 2, flexFlow: "column" }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6" fontWeight="bold">
          Trap Group Management
        </Typography>
        {addNewOrchardButton}
      </Box>
      {createOrchard}
      {orchardCardItems}
    </Box>
  );
};

export default OrchardManagerMain;
