import { useMediaQuery } from "@mui/material";
import React from "react";

export enum TEMPERATURE {
  CELSIUS = "celsius",
  FAHRENHEIT = "fahrenheit",
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum THEME_MODE {
  DARK = "dark",
  LIGHT = "light",
  SYSTEM = "system",
}

type UserPreferencesProps = {
  temperature: TEMPERATURE;
  setTemperature: StateSetter<TEMPERATURE>;
  directThemeMode: THEME_MODE.LIGHT | THEME_MODE.DARK;
  themeMode: THEME_MODE;
  setThemeMode: StateSetter<THEME_MODE>;
};

const INITIAL_VALUES: UserPreferencesProps = {
  temperature: TEMPERATURE.CELSIUS,
  setTemperature: () => {},
  // this will be overwritten anyways
  directThemeMode: THEME_MODE.LIGHT,
  themeMode: THEME_MODE.LIGHT,
  setThemeMode: () => {},
};

const getDirectThemeMode = (
  themeMode: THEME_MODE,
  prefersDarkMode: boolean
): THEME_MODE.LIGHT | THEME_MODE.DARK => {
  if (themeMode === THEME_MODE.SYSTEM) {
    if (prefersDarkMode) return THEME_MODE.DARK;
    return THEME_MODE.LIGHT;
  }
  return themeMode;
};

const UserPreferencesContext = React.createContext<UserPreferencesProps>(INITIAL_VALUES);

const UserPreferencesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const defaultThemeMode = React.useMemo(
    () => getDirectThemeMode(INITIAL_VALUES.themeMode, prefersDarkMode),
    [prefersDarkMode]
  );

  const [themeMode, setThemeMode] = React.useState<THEME_MODE>(defaultThemeMode);

  const [temperature, setTemperature] = React.useState<TEMPERATURE>(INITIAL_VALUES.temperature);

  const value = React.useMemo(
    () => ({
      directThemeMode: getDirectThemeMode(themeMode, prefersDarkMode),
      themeMode,
      setThemeMode,
      temperature,
      setTemperature,
    }),
    [themeMode, prefersDarkMode, temperature]
  );
  return (
    <UserPreferencesContext.Provider value={value}>{children}</UserPreferencesContext.Provider>
  );
};

export default UserPreferencesProvider;

export const useUserPreferences = () => React.useContext(UserPreferencesContext);
