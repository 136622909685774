import { Box, ListSubheader } from "@mui/material";

import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AVG_MEASUREMENT_FREQUENCY } from "queries/devices/averageMeasurements";
import useDateRange from "hooks/useDateRange";
import MeasurementChart, {
  MeasurementChartProps,
  TEMPERATURE_CHART_CONFIG,
  HUMIDITY_CHART_CONFIG,
} from "./MeasurementChart";

type MeasurementChartAccordionProps = Pick<MeasurementChartProps, "deviceIdIn">;

const MeasurementChartAccordion: React.FC<MeasurementChartAccordionProps> = ({ deviceIdIn }) => {
  const [showMeasurementChart, setShowMeasurementChart] = React.useState(false);

  const { dateRange, form: dateRangeForm } = useDateRange({});

  const { humidityLabel, temperatureLabel, title } =
    deviceIdIn?.length === 1
      ? { temperatureLabel: "Temperature", humidityLabel: "Humidity", title: "Measurement" }
      : {
          temperatureLabel: "AVG temperature",
          humidityLabel: "AVG humidity",
          title: "Average measurements",
        };

  return (
    // wrapper Box component removes stupid Material UI Accordion margin behavior
    <Box>
      <Accordion
        expanded={showMeasurementChart}
        onChange={(e, isExpanded) => setShowMeasurementChart(isExpanded)}
        elevation={3}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ mx: 2 }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ display: "flex" }}
        >
          <ListSubheader sx={{ flex: 1 }}>{title}</ListSubheader>
          {dateRangeForm}
        </AccordionSummary>
        <AccordionDetails sx={{ height: "min(350px, calc(100vw / 2))" }}>
          <MeasurementChart
            enabled={showMeasurementChart}
            deviceIdIn={deviceIdIn}
            from={dateRange.from}
            to={dateRange.to}
            frequency={AVG_MEASUREMENT_FREQUENCY.D1}
            chartConfig={[
              { ...HUMIDITY_CHART_CONFIG, name: humidityLabel },
              { ...TEMPERATURE_CHART_CONFIG, name: temperatureLabel },
            ]}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MeasurementChartAccordion;
