import { Tooltip } from "@mui/material";
import { DEVICE_TYPE } from "queries/devices";
import React from "react";
import { titleCase } from "utils/string";

type DeviceTypeImageProps = {
  type: DEVICE_TYPE;
};
const DeviceTypeImage: React.FC<DeviceTypeImageProps> = ({ type }) => {
  const { imgSrc, imgAlt } =
    type === DEVICE_TYPE.Premium
      ? { imgSrc: "/trap_premium.png", imgAlt: "premium trap" }
      : { imgSrc: "/trap_mini.png", imgAlt: "mini trap" };

  return (
    <Tooltip arrow title={titleCase(type)}>
      <img src={imgSrc} alt={imgAlt} loading="lazy" style={{ width: "50px", height: "50px" }} />
    </Tooltip>
  );
};
export default DeviceTypeImage;
