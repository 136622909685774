import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import React from "react";
import { IMAGE_TYPES, ListImageItem } from "queries/devices/images";
import TabPanel from "components/TabPanel";
import NothingToShow from "components/NothingToShow";
import Image from "components/Image";
import LoadingImage from "components/Image/LoadingImage";

type ProcessedImageProps = {
  isLoading?: boolean;
  data?: ListImageItem;
};
const ProcessedImage: React.FC<ProcessedImageProps> = ({ isLoading, data }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let content = <NothingToShow />;
  if (isLoading) {
    content = <LoadingImage />;
  } else if (value === 0 && data?.raw_blob_name) {
    content = <Image id={data.id} type={IMAGE_TYPES.raw} />;
  } else if (value === 1 && data?.processed_blob_name) {
    content = <Image id={data.id} type={IMAGE_TYPES.processed} />;
  }

  return (
    <Grid item sm={12} lg={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              label="Current raw"
              id="tabcontrol-current-raw-image"
              aria-controls="tabpanel-current-raw-image"
              disabled={!isLoading && !data?.raw_blob_name}
            />
            <Tab
              label="Current processed"
              id="tabcontrol-current-validated-image"
              aria-controls="tabpanel-current-processed-image"
              disabled={!isLoading && !data?.processed_blob_name}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={value}
          index={0}
          id="tabpanel-current-raw-image"
          aria-labelledby="tabcontrol-current-raw-image"
        >
          {content}
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          id="tabpanel-current-processed-image"
          aria-labelledby="tabcontrol-current-validated-image"
        >
          {content}
        </TabPanel>
      </Paper>
    </Grid>
  );
};

export default ProcessedImage;
