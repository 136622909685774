/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "../types";

type ListMeasurementParams = {
  page: number;
  page_size: number;
  id__in: string[];
};
export type ListMeasurementItem = {
  id: string;
  created_at: number;
  battery_voltage: number | null;
  solar_cell_voltage: number | null;
  temperature: number | null;
  humidity: number | null;
  device: string;
};
export const listMeasurements = async (
  params?: ListMeasurementParams
): Promise<ListResponse<ListMeasurementItem>> => {
  const { data } = await axios.get<ListResponse<ListMeasurementItem>>("/api/devices/measurements", {
    params,
  });
  return data;
};

export const listAllMeasurements = listAllFactory(listMeasurements);

type DetailMeasurementParams = {
  id: string;
};
export type DetailMeasurementItem = ListMeasurementItem;
export const detailMeasurement = async ({
  id,
}: DetailMeasurementParams): Promise<DetailMeasurementItem> => {
  const { data } = await axios.get<DetailMeasurementItem>(`/api/devices/measurements/${id}/`);
  return data;
};
