import axios from "axios";
import { ListResponse } from "queries/types";
import { listAllFactory } from "utils/query";

type OrderingParams =
  | "timestamp"
  | "-timestamp"
  | "avg_battery_voltage"
  | "-avg_battery_voltage"
  | "avg_solar_cell_voltage"
  | "-avg_solar_cell_voltage"
  | "avg_temperature"
  | "-avg_temperature"
  | "avg_humidity"
  | "-avg_humidity";

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum AVG_MEASUREMENT_FREQUENCY {
  "H1" = "1h",
  "H2" = "2h",
  "H4" = "4h",
  "H6" = "6h",
  "H8" = "8h",
  "H12" = "12h",
  "D1" = "1d",
}

type ListAverageMeasurementParams = {
  page: number;
  page_size: number;
  id__in?: string[];
  device__id?: string;
  device__id__in?: string[];
  frequency: AVG_MEASUREMENT_FREQUENCY;
  ordering?: OrderingParams | OrderingParams[];
};

export type ListAverageMeasurementItem = {
  timestamp: number;
  avg_battery_voltage: number | null;
  avg_solar_cell_voltage: number | null;
  avg_temperature: number | null;
  avg_humidity: number | null;
};

export const listAverageMeasurements = async (
  params?: ListAverageMeasurementParams
): Promise<ListResponse<ListAverageMeasurementItem>> => {
  const { data } = await axios.get<ListResponse<ListAverageMeasurementItem>>(
    "/api/devices/average-measurements/",
    { params }
  );
  return data;
};
export const listAllAverageMeasurements = listAllFactory(listAverageMeasurements);
