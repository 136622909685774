import React from "react";
import { Layer, Rect, Stage } from "react-konva";
import { ImageCoordinates, downloadImage } from "queries/devices/images";
import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { SECONDS_30 } from "utils/time";
import { ImageProps } from ".";
import LoadingImage from "./LoadingImage";
import URLImage from "./URLImage";

const CoordinateImage: React.FC<ImageProps & { coordinates: ImageCoordinates | undefined }> = (
  props
) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react/destructuring-assignment
  const [width, setWidth] = React.useState<number>(props.width ?? 0);

  React.useEffect(() => {
    const { current } = ref;
    const fn = () => {
      setWidth(current?.offsetWidth ?? 0);
    };

    window.addEventListener("resize", fn);
    fn();

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  const { id, title, type, coordinates } = props;

  const { data, isFetching, error } = useQuery({
    queryFn: () => downloadImage({ id: id as string, type }),
    queryKey: ["download-coordinate-image", { id, type }],
    staleTime: SECONDS_30,
    enabled: !!id,
  });

  let content = null;
  if (isFetching) {
    content = <LoadingImage showTitle={!!title} />;
  } else if (data) {
    // eslint-disable-next-line react/destructuring-assignment
    content = (
      <Stage width={width} height={300}>
        <Layer>
          <URLImage obj={data} />
          {coordinates &&
            coordinates.map((v) => (
              <Rect
                key={`${v.x}${v.y}`}
                x={v.x}
                y={v.y}
                width={v.w}
                height={v.h}
                fill="transparent"
                stroke="black"
                strokeWidth={5}
              />
            ))}
        </Layer>
      </Stage>
    );
  }

  return (
    <div className="image" ref={ref}>
      {title && <Typography variant="overline">{title}</Typography>}
      {content}
    </div>
  );
};

export default CoordinateImage;
