import React from "react";
import { Box, Skeleton } from "@mui/material";
import CloasableDialogTitle from "components/ClosableDialogTitle";
import { UseQueryResult } from "@tanstack/react-query";
import { ListUserItem } from "queries/users";

type UpdatePasswordDialogTitleProps = {
  onClose: () => void;
  userQuery: UseQueryResult<ListUserItem, unknown>;
};

const UpdatePasswordDialogTitle: React.FC<UpdatePasswordDialogTitleProps> = ({
  onClose,
  userQuery,
}) => {
  let email = null;

  if (userQuery.isLoading) {
    email = <Skeleton variant="text" width={400} sx={{ display: "inline-block", ml: 2 }} />;
  }
  if (userQuery.data) {
    email = (
      <>
        <Box component="span" sx={{ display: "inline-block" }}>
          &nbsp;-&nbsp;
        </Box>
        <Box component="span" sx={{ display: "inline-block" }}>
          {userQuery.data.email}
        </Box>
      </>
    );
  }

  return (
    <CloasableDialogTitle onClose={onClose}>
      <Box component="span" sx={{ display: "inline-block" }}>
        Change password
      </Box>
      {email}
    </CloasableDialogTitle>
  );
};

export default UpdatePasswordDialogTitle;
