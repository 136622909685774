import React from "react";
import { DialogContent, Box, Skeleton } from "@mui/material";

import UpdateUserDialogActions, { UpdateDeviceSettingsDialogActionsProps } from "./Actions";

type UpdateUserDialogFormLoaderProps = {
  actionsProps: UpdateDeviceSettingsDialogActionsProps;
};

const UpdateUserDialogFormLoader: React.FC<UpdateUserDialogFormLoaderProps> = ({
  actionsProps,
}) => {
  return (
    <Box>
      <DialogContent>
        <Skeleton height="4rem" />
        <Skeleton height="4rem" />
      </DialogContent>
      <UpdateUserDialogActions isSaveDisabled {...actionsProps} />
    </Box>
  );
};

export default UpdateUserDialogFormLoader;
