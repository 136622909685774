import React from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omitBy from "lodash/omitBy";
import { useSearchParams } from "react-router-dom";
import { DeviceFilters } from "pages/devices/main/DeviceFiltersDrawer";
import { DEVICE_ORDERING_FIELDS } from "queries/devices";
import {
  DEFAULT_CUSTOMER_DEVICE_STATUSES,
  DEVICE_GENERAL_STATUSES,
  DeviceGeneralStatus,
} from "utils/device-statuses";

const DEFAULT_FILTERS: DeviceFilters = {
  imei__icontains: "",
  mac__icontains: "",
  smapp_id__icontains: "",
  imsi__icontains: "",
  iccid__icontains: "",
  type__in: [],
  country__icontains: "",
  comment__icontains: "",
  ordering: DEVICE_ORDERING_FIELDS[0].value,
};

type UseDeviceFilters = () => {
  filters: DeviceFilters;
  activeStatuses: DeviceGeneralStatus[];
  defaultFilters: DeviceFilters;
  nonEmptyFilters: DeviceFilters;
  setFilters: (filters: DeviceFilters) => void;
  toggleStatus: (status: DeviceGeneralStatus) => void;
  setAllStatuses: (active: boolean) => void;
  resetFilters: () => void;
};

const useDeviceFilters: UseDeviceFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, nonEmptyFilters, activeStatuses } = React.useMemo(() => {
    const filters = { ...DEFAULT_FILTERS, ...Object.fromEntries(searchParams) };
    const activeStatuses: DeviceGeneralStatus[] =
      searchParams
        .get("statuses")
        ?.split(",")
        .map((s) => s as DeviceGeneralStatus) || DEFAULT_CUSTOMER_DEVICE_STATUSES;

    return {
      filters,
      nonEmptyFilters: omitBy(filters, isEmpty),
      activeStatuses,
    };
  }, [searchParams]);

  const setFilters = React.useCallback(
    (values: Omit<DeviceFilters, "active">) => {
      setSearchParams(omitBy(values, isEmpty));
    },
    [setSearchParams]
  );

  const toggleStatus = React.useCallback(
    (status: DeviceGeneralStatus) => {
      const currentStatuses = searchParams.get("statuses")?.split(",") || [];
      const updatedStatuses = currentStatuses.includes(status)
        ? currentStatuses.filter((s) => s !== status)
        : [...currentStatuses, status];

      setSearchParams((prev) => ({
        ...prev,
        statuses: updatedStatuses.join(","),
      }));
    },
    [searchParams, setSearchParams]
  );

  const setAllStatuses = React.useCallback(
    (active: boolean) => {
      setSearchParams((prev) => ({
        ...prev,
        statuses: (active ? DEVICE_GENERAL_STATUSES : DEFAULT_CUSTOMER_DEVICE_STATUSES).join(","),
      }));
    },
    [setSearchParams]
  );

  const resetFilters = React.useCallback(() => {
    setFilters(DEFAULT_FILTERS);
  }, [setFilters]);

  React.useEffect(() => {
    const initial = { ...DEFAULT_FILTERS, ...filters };
    if (!isEqual(initial, Object.fromEntries(searchParams))) {
      setFilters(initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    nonEmptyFilters,
    filters,
    activeStatuses,
    setFilters,
    toggleStatus,
    setAllStatuses,
    resetFilters,
    defaultFilters: DEFAULT_FILTERS,
  };
};

export default useDeviceFilters;
