import React from "react";
import {
  Dialog,
  Chip,
  Button,
  Tooltip,
  DialogProps,
  DialogContent,
  DialogActions,
  Box,
  TextField,
} from "@mui/material";
import EmojiNatureOutlinedIcon from "@mui/icons-material/EmojiNatureOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateImage } from "queries/devices/images";
import { isNaN } from "lodash";
import CloasableDialogTitle from "components/ClosableDialogTitle";

const schema = Yup.object({
  detection_count: Yup.string(),
});

type DetectionCountFormValues = {
  detection_count: string;
};

type UpdateDetectionCountDialogProps = Omit<DialogProps, "open" | "onClose"> & {
  imageId: string;
  initialValues: DetectionCountFormValues;
  isOpen: boolean;
  onClose: () => void;
};
const UpdateDetectionCountDialog: React.FC<UpdateDetectionCountDialogProps> = ({
  imageId,
  isOpen,
  initialValues,
  onClose,
  ...props
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DetectionCountFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list-images"] });
      queryClient.invalidateQueries({ queryKey: ["list-all-images"] });
      queryClient.invalidateQueries({ queryKey: ["detail-image", { id: imageId }] });
      onClose();
    },
  });

  const onSubmit = React.useCallback(
    (params: DetectionCountFormValues) => {
      const parsedDetectionCount = parseInt(params.detection_count, 10);
      const values = {
        detection_count: isNaN(parsedDetectionCount) ? null : parsedDetectionCount,
      };

      mutation.mutate({ id: imageId, body: values });
    },
    [imageId, mutation]
  );

  return (
    <Dialog open={isOpen} onClose={onClose} {...props}>
      <CloasableDialogTitle onClose={onClose}>Update detection count</CloasableDialogTitle>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            id="detection_count"
            label="Detection count"
            type="number"
            size="small"
            error={!!errors.detection_count}
            helperText={errors.detection_count?.message}
            {...register("detection_count")}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={onClose} variant="outlined" size="small">
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

// TODO colors

type DetectionCountChipProps = {
  value?: number | null;
  imageId?: string;
  isEditable?: boolean;
};

const DetectionCountChip: React.FC<DetectionCountChipProps> = ({ value, imageId, isEditable }) => {
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = React.useState(false);

  const chipProps = React.useMemo(
    () =>
      isEditable && imageId
        ? {
            deleteIcon: <ModeEditOutlinedIcon />,
            onDelete: () => {
              setIsUpdateDialogOpen(true);
            },
          }
        : {},
    [imageId, isEditable]
  );

  return (
    <>
      <Tooltip title="Detection count" arrow>
        <Chip icon={<EmojiNatureOutlinedIcon />} label={value} variant="outlined" {...chipProps} />
      </Tooltip>

      {isEditable && imageId && isUpdateDialogOpen && (
        <UpdateDetectionCountDialog
          initialValues={{ detection_count: value?.toString() ?? "" }}
          imageId={imageId}
          isOpen={isUpdateDialogOpen}
          onClose={() => {
            setIsUpdateDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default DetectionCountChip;
