import React from "react";
import { Box, DialogTitle, DialogTitleProps, IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

type ClosableDialogTitleProps = DialogTitleProps & {
  onClose?: () => void;
};
const CloasableDialogTitle: React.FC<React.PropsWithChildren<ClosableDialogTitleProps>> = ({
  children,
  onClose,
  ...props
}) => {
  return (
    <DialogTitle sx={{ display: "flex", m: 0, p: 2 }} {...props}>
      <Box sx={{ flex: 1 }}>{children}</Box>
      <Box>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              transform: "translate(0.5rem, -0.5rem)",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        ) : null}
      </Box>
    </DialogTitle>
  );
};
export default CloasableDialogTitle;
