export const API_ROUTES = {
  ACCESS_TOKEN: "/api/auth/",
  REFRESH_TOKEN: "/api/auth/refresh/",
};

const ROUTES = {
  HOME: "/",
  DEVICES: "/devices",
  DEVICES_CONFIG: "/devices/config",
  FIRMWARES: "/firmwares",
  MANAGEMENT: "/management",
  MAP: "/map",
  CHARTS: "/charts",
  ORCHARD: "/orchard",
  ORCHARD_MANAGER: "/orchardManager",
  LOGIN: "/login",
  MY_PROFILE: "/my-profile",
};

export default ROUTES;
