import React from "react";
import { Dialog, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { detailUser } from "queries/users";

import { pick } from "lodash";
import UpdateUserDialogTitle from "./UpdateUserDialogTitle";
import UpdateUserDialogForm from "./UpdateUserDialogForm";
import UpdateUserDialogFormLoader from "./UpdateUserDialogFormLoader";

type UpdateUserDialogProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

const UpdateUserDialog: React.FC<UpdateUserDialogProps> = ({ id, isOpen, onClose }) => {
  const userQuery = useQuery({
    queryKey: ["detail-user", { id }],
    queryFn: () => detailUser({ id: id as string }),
    enabled: !!id,
  });

  const userId = userQuery.data?.id;

  const initialValues = React.useMemo(
    () =>
      userQuery.data
        ? pick(userQuery.data, ["username", "first_name", "last_name", "email"])
        : undefined,
    [userQuery.data]
  );

  let content = null;
  if (userQuery.error) {
    content = <Typography>{String(userQuery.error)}</Typography>;
  } else if (userId && initialValues) {
    content = (
      <UpdateUserDialogForm
        handleSuccess={onClose}
        initialValues={initialValues}
        id={userId}
        actionsProps={{ handleCancelClick: onClose }}
      />
    );
  } else {
    content = <UpdateUserDialogFormLoader actionsProps={{ handleCancelClick: onClose }} />;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <UpdateUserDialogTitle userQuery={userQuery} onClose={onClose} />
      {content}
    </Dialog>
  );
};

export default UpdateUserDialog;
