import { useQueries } from "@tanstack/react-query";
import { FirmwareNameAndVersions, boardMajorMinorPatch } from "queries/firmwares";
import { useMemo } from "react";

type DetailGenericParams = {
  id: string;
};
type Props = {
  queriesData: {
    qkID: string | null | undefined;
    qk: string;
    qf: ({ id }: DetailGenericParams) => Promise<Record<string, unknown>>;
  }[];
};

export type BatchQueriesResultType = {
  name: string;
  id: string;
  label: string | null | undefined;
}[];

const useBatchQueries = ({ queriesData }: Props) => {
  const queries = useQueries({
    queries: queriesData.map((v) => {
      return {
        queryKey: [`detail-${v.qk}`, v.qkID],
        queryFn: async () => ({ name: v.qk, val: await v.qf({ id: v.qkID as string }) }),
        enabled: !!v.qkID,
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    }),
  });

  const data = useMemo(() => {
    const goingToBeNull = queriesData
      .map((qq) => ({ name: qq.qk, label: "", id: qq.qkID }))
      .filter((v) => v.id === null);

    const validData = queries
      .map((v) => ({
        name: v.data?.name,
        id: v.data?.val.id as string,
        label:
          (v.data?.val.name as string) ??
          (v.data?.val.commission_status as string) ??
          boardMajorMinorPatch(v.data?.val as FirmwareNameAndVersions),
      }))
      .filter((v) => v.name !== undefined);
    return [...goingToBeNull, ...validData] as BatchQueriesResultType;
  }, [queries, queriesData]);

  return data;
};

export default useBatchQueries;
