/* eslint-disable import/prefer-default-export */
import React from "react";
import isFunction from "lodash/isFunction";
import { isNotNil } from "./lodash";

export const mergeRefs = <T>(...refs: (React.MutableRefObject<T> | React.ForwardedRef<T>)[]) => {
  return (instance: T) => {
    refs.forEach((ref) => {
      if (isFunction(ref)) {
        ref(instance);
      } else if (isNotNil(ref)) {
        ref.current = instance;
      }
    });
  };
};

export const makeUniquListFromStrin = (data: string) => {
  return data
    .trim()
    .split(" ")
    .filter((x, i, a) => a.indexOf(x) === i) // removes duplicated lines
    .join(", ");
};

export const clearListChars = (data: string, extraChars?: (string | RegExp)[], remove = false) => {
  let tmpVal = data;
  ["|", ",", ";", /\s+/g].concat(extraChars || []).forEach((v) => {
    tmpVal = tmpVal.replaceAll(v, remove ? "" : " ");
  });
  return tmpVal;
};
