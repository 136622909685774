import React from "react";
import { Skeleton } from "@mui/material";

type LoadingImageProps = {
  showTitle?: boolean;
  titleWidth?: number;
  imageHeight?: number;
  imageWidth?: number;
};
const LoadingImage: React.FC<LoadingImageProps> = ({
  showTitle,
  titleWidth = 200,
  imageHeight = 300,
  imageWidth,
}) => (
  <>
    {showTitle && <Skeleton variant="text" width={titleWidth} />}
    <Skeleton variant="rectangular" height={imageHeight} width={imageWidth} />
  </>
);

export default LoadingImage;
