import React from "react";

type AboutContextProps = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const INITIAL_VALUES = {
  isOpen: false,
  open: () => {},
  close: () => {},
  toggle: () => {},
};

const AboutContext = React.createContext<AboutContextProps>(INITIAL_VALUES);

const AboutProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);
  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const value = React.useMemo(
    () => ({
      isOpen,
      open,
      close,
      toggle,
    }),
    [close, isOpen, open, toggle]
  );
  return <AboutContext.Provider value={value}>{children}</AboutContext.Provider>;
};

export default AboutProvider;

export const useAbout = () => React.useContext(AboutContext);
