import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { Button, Grid, IconButton, List, ListSubheader, Paper, Tooltip } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import AsyncPropertyCell from "components/PropertyCell/AsyncPropertyCell";
import { useAuth } from "context/AuthProvider";
import { useDeviceView } from "context/DeviceViewProvider";
import UpdateDeviceSettingsDialog from "pages/devices/_dialogs/UpdateDeviceSettingsDialog";
import { detailConfig } from "queries/configs";
import { detailCrop } from "queries/crops";
import {
  DetailDeviceItem,
  ListDeviceItem,
  UpdateDeviceParams,
  updateDevice,
} from "queries/devices";
import { detailCommissionStatus } from "queries/devices/commissionStatus";
import { boardMajorMinorPatch, detailFirmware } from "queries/firmwares";
import { detailPest } from "queries/pests";
import React from "react";

type CurrentSettingsProps = {
  device: DetailDeviceItem;
};

type DeleteButtonProps = {
  deviceParams: UpdateDeviceParams;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ deviceParams }) => {
  const { id, params } = deviceParams;

  const { handleMutationSettled } = useDeviceView();

  const updateDeviceMutation = useMutation<ListDeviceItem, Error, UpdateDeviceParams, unknown>({
    mutationFn: updateDevice,
    onSettled: handleMutationSettled,
  });

  return (
    <Button
      onClick={() => {
        updateDeviceMutation.mutate({ id, params });
      }}
    >
      <DeleteIcon />
    </Button>
  );
};

const CurrentSettings: React.FC<CurrentSettingsProps> = ({ device }) => {
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const deleteButtonSx = { display: "flex", alignItems: "center" };

  const { isAdmin } = useAuth();
  const cropQuery = useQuery({
    queryFn: () => detailCrop({ id: device.current_crop as string }),
    queryKey: ["detail-crop", { id: device.current_crop }],
    enabled: !!device.current_crop,
  });

  const pestQuery = useQuery({
    queryFn: () => detailPest({ id: device.current_pest as string }),
    queryKey: ["detail-pest", { id: device.current_pest }],
    enabled: !!device.current_pest,
  });

  const commissionStatusQuery = useQuery({
    queryFn: () => detailCommissionStatus({ id: device.current_commission_status as string }),
    queryKey: ["detail-commission-status", { id: device.current_commission_status }],
    enabled: !!(device.current_commission_status && isAdmin),
  });

  const configQueryCurrent = useQuery({
    queryFn: () => detailConfig({ id: device.current_config as string }),
    queryKey: ["detail-config-current", { id: device.current_config }],
    enabled: !!(device.current_config && isAdmin),
  });

  const configQueryTarget = useQuery({
    queryFn: () => detailConfig({ id: device.target_config as string }),
    queryKey: ["detail-config-target", { id: device.target_config }],
    enabled: !!(device.target_config && isAdmin),
  });

  const firmwareQueryCurrent = useQuery({
    queryFn: () => detailFirmware({ id: device.current_firmware as string }),
    queryKey: ["detail-firmware-current", { id: device.current_firmware }],
    enabled: !!(device.current_firmware && isAdmin),
  });

  const firmwareQueryTarget = useQuery({
    queryFn: () => detailFirmware({ id: device.target_firmware as string }),
    queryKey: ["detail-firmware-target", { id: device.target_firmware }],
    enabled: !!(device.target_firmware && isAdmin),
  });

  const activeOrInactive = (active: boolean) => {
    return active ? "active" : "inactive";
  };

  return (
    <>
      <UpdateDeviceSettingsDialog
        isOpen={isEditOpen}
        onClose={() => {
          setIsEditOpen(false);
        }}
        id={device.id}
      />
      <Paper elevation={3} sx={{ height: "100%" }}>
        <IconButton sx={{ float: "right", zIndex: 2 }} onClick={() => setIsEditOpen(true)}>
          <Tooltip title="Edit current settings" arrow>
            <ModeEditOutlinedIcon />
          </Tooltip>
        </IconButton>
        <List subheader={<ListSubheader>Current settings</ListSubheader>} dense>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={12}>
              <AsyncPropertyCell
                label="Crop"
                value={(data) => data.name ?? "Not defined"}
                query={cropQuery}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={12}>
              <AsyncPropertyCell label="Pest" value={(data) => data.name} query={pestQuery} />
            </Grid>
            {isAdmin && (
              <Grid item xs={12} sm={12} md={6} lg={12}>
                <AsyncPropertyCell
                  label="Commission status"
                  value={(data) => data.commission_status}
                  query={commissionStatusQuery}
                />
              </Grid>
            )}
            {isAdmin && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={12}>
                  <AsyncPropertyCell
                    label="Config (current)"
                    extendedLabel={(data) => `Config (current, version: ${data.version} ) `}
                    value={(data) =>
                      data.name && data.version
                        ? `${data.name} - ${activeOrInactive(data.active)}`
                        : "Custom config"
                    }
                    query={configQueryCurrent}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={12} sx={deleteButtonSx}>
                  <AsyncPropertyCell
                    label="Config (target)"
                    extendedLabel={(data) => `Config (target, version: ${data.version} ) `}
                    value={(data) =>
                      data.name && data.version
                        ? `${data.name} - ${activeOrInactive(data.active)}`
                        : "Custom config"
                    }
                    query={configQueryTarget}
                  />
                  {/* <Button onClick={() => { updateDeviceMutation.mutate({ id: device.id, params: { target_config: null } }) }}><DeleteIcon /> </Button> */}
                  <DeleteButton deviceParams={{ id: device.id, params: { target_config: null } }} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={12}>
                  <AsyncPropertyCell
                    label="Firmware (current)"
                    value={(data) =>
                      `${boardMajorMinorPatch(data)} - ${activeOrInactive(data.active)}`
                    }
                    query={firmwareQueryCurrent}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={12} sx={deleteButtonSx}>
                  <AsyncPropertyCell
                    label="Firmware (target)"
                    value={(data) =>
                      `${boardMajorMinorPatch(data)} - ${activeOrInactive(data.active)}`
                    }
                    query={firmwareQueryTarget}
                  />
                  {/* <Button onClick={() => { updateDeviceMutation.mutate({ id: device.id, params: { target_firmware: null } }) }}><DeleteIcon /> </Button> */}
                  <DeleteButton
                    deviceParams={{ id: device.id, params: { target_firmware: null } }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </List>
      </Paper>
    </>
  );
};

export default CurrentSettings;
