import { useInfiniteQuery } from "@tanstack/react-query";
import { ListResponse } from "queries/types";

type Params = Record<"page" | "page_size", number> & { [x: string]: unknown };

type UseGenericQueryParams<TParams> = {
  qKey: string;
  debouncedSearch: string;
  fn: (params?: Params) => Promise<ListResponse<TParams>>;
  queryParamsKey?: string[];
  extraQueryParams?: {
    [x: string]: unknown;
  };
};

const useGenericQuery = <TParams extends object>({
  qKey,
  debouncedSearch,
  fn,
  queryParamsKey,
  extraQueryParams,
}: UseGenericQueryParams<TParams>) => {
  const qp = Object.fromEntries((queryParamsKey || [])?.map((v) => [[v], debouncedSearch]));

  const genericQuery = useInfiniteQuery({
    queryKey: [qKey, debouncedSearch],
    queryFn: async ({ pageParam = 1 }) => {
      const { data, meta } = await fn({
        page: pageParam,
        page_size: 6,
        ...qp,
        ...extraQueryParams,
      });
      return { data, meta };
    },
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    // staleTime: Infinity,
    // cacheTime: Infinity,
  });
  return genericQuery;
};

export default useGenericQuery;
