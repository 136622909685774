import useAvgMeasurements, { UseAvgMeasurementOptions } from "hooks/useAvgMeasurements";
import React from "react";
import { ChartProps } from "./Chart";
import ChartWithRQ from "./ChartWithRQ";

export const BATTERY_VOLTAGE_CHART_CONFIG = {
  dataKey: "battery_voltage",
  stroke: "#9f6c13",
  connectNulls: true,
  strokeWidth: 3,
  fill: "#9f6c1399",
} as const;

const BATTERY_VOLTAGE_CHART_CONFIG_ARR = [BATTERY_VOLTAGE_CHART_CONFIG];

export type BatteryVoltageChartProps = UseAvgMeasurementOptions &
  Pick<ChartProps<"battery_voltage">, "chartConfig">;

const BatteryVoltageChart: React.FC<BatteryVoltageChartProps> = ({
  chartConfig = BATTERY_VOLTAGE_CHART_CONFIG_ARR,
  ...props
}) => {
  const { data, error } = useAvgMeasurements(props);

  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} yAxisDomain={[2.5, 5]} />;
};
export default BatteryVoltageChart;
