import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import React from "react";

export type UpdateUserDialogActionsProps = {
  handleCancelClick: () => void;
  isSaveDisabled?: boolean;
  isSaveLoading?: boolean;
};

const UpdateUserDialogActions: React.FC<UpdateUserDialogActionsProps> = ({
  handleCancelClick,
  isSaveDisabled,
  isSaveLoading,
}) => {
  return (
    <DialogActions sx={{ px: 3, py: 2 }}>
      <Button variant="outlined" onClick={handleCancelClick}>
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={isSaveLoading}
        disabled={isSaveDisabled}
      >
        Save
      </LoadingButton>
    </DialogActions>
  );
};

export default UpdateUserDialogActions;
