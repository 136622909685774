import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";

type PasswordInputType = {
  handlePassword?: (parms: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const PasswordInput = React.forwardRef<HTMLDivElement, TextFieldProps & PasswordInputType>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const { handlePassword } = props;
    const { type, ...newProps } = props;

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <TextField
        size="small"
        type={!showPassword ? "password" : "string"}
        onChange={handlePassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...newProps}
        ref={ref}
      />
    );
  }
);

export default PasswordInput;
