import React, { useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, Dialog, DialogActions, DialogContent, IconButton, Tooltip } from "@mui/material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { cleanedImage } from "queries/devices/images";
import { green, red } from "@mui/material/colors";

type CleanedButtonProps = {
  id?: string | undefined;
  handleCleanupClick: () => void;
};

const CleanedButton: React.FC<CleanedButtonProps> = ({ id, handleCleanupClick }) => {
  const [open, setOpen] = useState(false);

  const cleanedImageMutation = useMutation({
    mutationFn: cleanedImage,
    onSettled: () => {
      setTimeout(() => {
        cleanedImageMutation.reset();
      }, 2500);
    },
  });

  const handleInnerCleanupClick = () => {
    setOpen(false);
    if (!id) return;
    cleanedImageMutation.mutate({ id });
    handleCleanupClick();
  };

  const buttonBgColorCode = useMemo(() => {
    return cleanedImageMutation.isSuccess
      ? green[500]
      : cleanedImageMutation.isError
      ? red[500]
      : 0;
  }, [cleanedImageMutation]);

  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <p>Do you really want to clean the indicator ?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="outlined"
            size="small"
            sx={{ m: 1 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleInnerCleanupClick}
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Tooltip title="Cleanup indicator" arrow>
        <IconButton
          disabled={!cleanedImageMutation.isIdle}
          onClick={() => {
            setOpen((open) => !open);
          }}
          sx={{
            "&.Mui-disabled": {
              bgcolor: buttonBgColorCode,
            },
          }}
        >
          <CleaningServicesIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CleanedButton;
