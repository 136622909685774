/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "../types";
import { ListDeviceItem } from ".";

type ListDeviceOrchardParams = {
  page: number;
  page_size: number;
  id__in: string[];
};
export type ListDeviceOrchardItem = {
  id: string;
  created_at: number;
  device: ListDeviceItem;
  orchard: { id: string; name: string };
};
export const listDeviceOrchards = async (
  params?: ListDeviceOrchardParams
): Promise<ListResponse<ListDeviceOrchardItem>> => {
  const { data } = await axios.get<ListResponse<ListDeviceOrchardItem>>("/api/devices/orchard/", {
    params,
  });
  return data;
};

export const listAllDeviceOrchards = listAllFactory(listDeviceOrchards);

type DetailDeviceOrchardParams = {
  id: string;
};
export type DetailDeviceOrchardItem = ListDeviceOrchardItem;
export const detailOrchard = async ({
  id,
}: DetailDeviceOrchardParams): Promise<DetailDeviceOrchardItem> => {
  const { data } = await axios.get<DetailDeviceOrchardItem>(`/api/devices/orchard/${id}`);
  return data;
};

type CreateDeviceOrchardParams = {
  name: string;
  devices_to_add: string[];
};
export const createDeviceOrchard = async (
  params: CreateDeviceOrchardParams
): Promise<DetailDeviceOrchardItem> => {
  const { data } = await axios.post<DetailDeviceOrchardItem>("/api/devices/orchard/", params);
  return data;
};

type UpdateDeviceOrchardParams = {
  device: string;
};
export const upateOrchard = async (
  id: string,
  params: UpdateDeviceOrchardParams
): Promise<DetailDeviceOrchardItem> => {
  const { data } = await axios.patch<DetailDeviceOrchardItem>(`/api/devices/orchard/${id}`, params);
  return data;
};
