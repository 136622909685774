import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DetectionCountCatchChip from "components/deviceChips/DetectionCountCatchChip";
import { DetectionCountsByOrchardsChartPanel } from "components/visualization/DetectionCountDeltaChartPanel";
import { OrchardMapPanel } from "pages/devices/view/panels/MapPanel";
import { ListDeviceItem } from "queries/devices";
import {
  OneSevenDayAvgDcdItem,
  listAllOneSevenDayAVGDCD,
} from "queries/devices/averageDetectionCounts";
import { ListDeviceOrchardItem } from "queries/devices/orchards";
import React from "react";
import SmappAccordian from "./accordian";
import { groupedOrchardViewGridSx } from "./common";

type GroupedOrchardViewType = {
  allDeviceOrchards: ListDeviceOrchardItem[];
};

const GroupedOrchardView = ({ allDeviceOrchards }: GroupedOrchardViewType) => {
  const allOrchardNames = allDeviceOrchards
    .map((v) => v.orchard)
    .filter((obj, index, self) => index === self.findIndex((t) => t.id === obj.id))
    .sort();

  const orchardIDs = allOrchardNames.map((v) => v.id);

  const { data: OneAndSevenOrchardsListResponse } = useQuery({
    queryFn: () => listAllOneSevenDayAVGDCD({ orchard_ids: orchardIDs }),
    queryKey: ["list-all-avg-orchards-dcd", orchardIDs],
    enabled: orchardIDs.length > 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const oneAndSevenOrchards =
    OneAndSevenOrchardsListResponse?.data || ([] as OneSevenDayAvgDcdItem[]);

  const getCatchInDays = (orchard_id: string, isOpen: boolean) => {
    const { avg_day: avgDay, avg_week: avgWeek } =
      oneAndSevenOrchards.find((v) => v.id === orchard_id) || ({} as OneSevenDayAvgDcdItem);
    return (
      <Box sx={{ marginLeft: "auto", display: "flex", gap: 1 }}>
        <DetectionCountCatchChip
          prefixTitle="Average "
          colored
          title="catch in 7 days"
          value={avgWeek}
        />
        <DetectionCountCatchChip
          prefixTitle="Average "
          colored
          title="catch today"
          value={avgDay}
        />
        {isOpen ? (
          <ExpandLessIcon sx={{ fontSize: 30 }} />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: 30 }} />
        )}
      </Box>
    );
  };

  const lazyCharts = (orchard_id: string, devices: ListDeviceItem[]) => {
    return (
      <Grid container sx={{ ...groupedOrchardViewGridSx, flexDirection: "column" }}>
        <DetectionCountsByOrchardsChartPanel
          width="100%"
          orchardID={orchard_id}
          aggregations="avg"
        />
        <DetectionCountsByOrchardsChartPanel
          width="100%"
          orchardID={orchard_id}
          aggregations="avg"
          frequency="week"
        />
        <OrchardMapPanel devices={devices} />
      </Grid>
    );
  };

  return (
    <>
      {allOrchardNames.map((orchard) => {
        const devices = allDeviceOrchards
          .filter((v) => v.orchard.id === orchard.id)
          .map((v) => v.device);
        return (
          <SmappAccordian
            title={orchard.name}
            key={orchard.id}
            titleBar={(isOpen) => getCatchInDays(orchard.id, isOpen)}
            lazyLoad={() => lazyCharts(orchard.id, devices)}
          >
            {null}
          </SmappAccordian>
        );
      })}
    </>
  );
};

export default GroupedOrchardView;
