import React from "react";
import { Box, Skeleton } from "@mui/material";
import CloasableDialogTitle from "components/ClosableDialogTitle";
import { UseQueryResult } from "@tanstack/react-query";
import { DetailDeviceItem } from "queries/devices";

type UpdateUserDialogTitleProps = {
  onClose: () => void;
  deviceQuery: UseQueryResult<DetailDeviceItem, unknown>;
};

const UpdateUserDialogTitle: React.FC<UpdateUserDialogTitleProps> = ({ onClose, deviceQuery }) => {
  let smappId = null;

  if (deviceQuery.isLoading) {
    smappId = <Skeleton variant="text" width={400} sx={{ display: "inline-block", ml: 2 }} />;
  }
  if (deviceQuery.data) {
    smappId = (
      <>
        <Box component="span" sx={{ display: "inline-block" }}>
          &nbsp;-&nbsp;
        </Box>
        <Box component="span" sx={{ display: "inline-block" }}>
          {deviceQuery.data.smapp_id}
        </Box>
      </>
    );
  }

  return (
    <CloasableDialogTitle onClose={onClose}>
      <Box component="span" sx={{ display: "inline-block" }}>
        Update device generics
      </Box>
      {smappId}
    </CloasableDialogTitle>
  );
};

export default UpdateUserDialogTitle;
