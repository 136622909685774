/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "../types";

export type ListCommissionStatusParams = {
  page: number;
  page_size: number;
  id__in?: string[];
};
export type ListCommissionStatusItem = {
  id: string;
  created_at: number;
  commission_status: string;
  device: string;
};

export const allCommissionStatuses = [
  { value: "unknown", label: "Unknown" },
  { value: "in_storage", label: "In storage" },
  { value: "pending_commission", label: "Pending commission" },
  { value: "commissioned", label: "Commissioned" },
  { value: "pending_decommission", label: "Pending decommission" },
  { value: "decommissioned", label: "Decommissioned" },
];

export const listCommissionStatuses = async (
  params?: ListCommissionStatusParams
): Promise<ListResponse<ListCommissionStatusItem>> => {
  const { data } = await axios.get<ListResponse<ListCommissionStatusItem>>(
    "/api/devices/commission-statuses/",
    { params }
  );
  return data;
};

export const listAllCommissionStatuses = listAllFactory(listCommissionStatuses);

type DetailCommissionStatusParams = {
  id: string;
};
export type DetailCommissionStatusItem = ListCommissionStatusItem;
export const detailCommissionStatus = async ({
  id,
}: DetailCommissionStatusParams): Promise<DetailCommissionStatusItem> => {
  const { data } = await axios.get<DetailCommissionStatusItem>(
    `/api/devices/commission-statuses/${id}/`
  );
  return data;
};
