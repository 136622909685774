import React from "react";
import { Box, Button, Card } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import {
  ListFirmwareItem,
  boardMajorMinorPatch,
  deleteFirmware,
  downloadFirmware,
} from "queries/firmwares";
import { ListResponse } from "queries/types";

type FmListProps = {
  activeFw: UseQueryResult<ListResponse<ListFirmwareItem>, unknown>;
  inActiveFw: UseQueryResult<ListResponse<ListFirmwareItem>, unknown>;
};

const FWList = ({ activeFw, inActiveFw }: FmListProps) => {
  const downloadMutation = useMutation({
    mutationFn: downloadFirmware,
  });

  const mutationDeleteFw = useMutation({
    mutationFn: deleteFirmware,
    onSuccess: () => {
      activeFw.refetch();
      inActiveFw.refetch();
    },
  });

  const genLi = (
    data: ListResponse<ListFirmwareItem> | undefined,
    label: string,
    deleteEnabled = true
  ) => {
    return (
      <Box sx={{ display: "flex", flexFlow: "column", padding: 0.5, margin: 1 }}>
        {label}
        <Box sx={{ display: "flex", margin: 1 }}>
          <Box>Filename</Box>
          <Box sx={{ marginLeft: "auto" }}>Version</Box>
          {deleteEnabled && <Box> Delete </Box>}
        </Box>
        <Box sx={{ maxHeight: 200, overflow: "auto" }}>
          {data?.data.map((v) => [
            <Box
              key={v.id}
              sx={{
                display: "flex",
                gap: 2.5,
                alignItems: "center",
                paddingBottom: 0.5,
              }}
            >
              <Button
                sx={{
                  padding: 0,
                  margin: 0,
                  marginRight: 2.5,
                  marginLeft: 2.5,
                  minWidth: 0,
                }}
                onClick={async () => {
                  const csvFile = await downloadMutation.mutateAsync({
                    id: boardMajorMinorPatch(v),
                  });
                  const csvURL = URL.createObjectURL(csvFile);
                  const tempLink = document.createElement("a");
                  tempLink.href = csvURL;
                  tempLink.setAttribute("download", v.filename);
                  tempLink.click();
                }}
              >
                <DownloadForOfflineIcon />
              </Button>
              <Box>{v.filename}</Box>
              <Box sx={{ marginLeft: "auto" }}>{boardMajorMinorPatch(v)}</Box>
              {deleteEnabled && (
                <Button
                  onClick={() => {
                    mutationDeleteFw.mutate(v);
                  }}
                  sx={{ padding: 0, margin: 0, marginRight: 2.5, marginLeft: 2.5, minWidth: 0 }}
                >
                  <DeleteIcon />
                </Button>
              )}
            </Box>,
          ])}
        </Box>
      </Box>
    );
  };

  let activeFirmwareList = <>Loading ...</>;
  if (activeFw.isFetched) {
    activeFirmwareList = genLi(activeFw.data, "Current active firmwares:");
  }

  let inActiveFirmwareList = <>Loading ...</>;
  if (activeFw.isFetched) {
    inActiveFirmwareList = genLi(inActiveFw.data, "Current inactive firmwares:", false);
  }

  return (
    <Card sx={{ flex: 2, height: "fit-content" }}>
      {activeFirmwareList}
      {inActiveFirmwareList}
    </Card>
  );
};

export default FWList;
