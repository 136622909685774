import { OperationRequestItem } from "queries/devices/operationRequest";
import React from "react";

export type OperationRequestSwitchData = {
  name: string;
  data: OperationRequestItem | undefined;
};

interface DeviceViewContextProps {
  handleMutationSettled: () => void;
  namedOperationRequests: OperationRequestSwitchData[];
}

const INITIAL_VALUES: DeviceViewContextProps = {
  handleMutationSettled: () => {},
  namedOperationRequests: [],
};

const DeviceViewContext = React.createContext<DeviceViewContextProps>(INITIAL_VALUES);

type DeviceViewProviderType = {
  mutationSettledCallback: () => void;
  namedOperationRequests: OperationRequestSwitchData[];
} & React.PropsWithChildren;

const DeviceViewProvider: React.FC<DeviceViewProviderType> = ({
  children,
  mutationSettledCallback,
  namedOperationRequests,
}) => {
  const value = React.useMemo(
    () => ({
      handleMutationSettled: () => {
        mutationSettledCallback();
      },
      namedOperationRequests,
    }),
    [mutationSettledCallback, namedOperationRequests]
  );

  return <DeviceViewContext.Provider value={value}> {children} </DeviceViewContext.Provider>;
};

export default DeviceViewProvider;

export const useDeviceView = () => React.useContext(DeviceViewContext);
