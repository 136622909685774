/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "./types";

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum CAM_RES {
  QQVGA = "qqvga",
  QVGA = "qvga",
  VGA = "vga",
  SVGA = "svga",
  XGA = "xga",
  UXGA = "uxga",
  QXGA = "qxga",
  QSXGA = "qsxga",
}

export type ListConfigParams = {
  page: number;
  page_size: number;
  id__in?: string[];
  name__icontains?: string;
  active?: boolean;
};
export type IdCreatedUpdated = {
  id: string;
  created_at: number;
  updated_at: number;
};
export type TimesFieldsType = {
  image_times: unknown[] | Record<string, unknown>;
  env_times: unknown[] | Record<string, unknown>;
  diag_times: unknown[] | Record<string, unknown>;
  network_times: unknown[] | Record<string, unknown>;
  loc_times: unknown[] | Record<string, unknown>;
  upload_times: unknown[] | Record<string, unknown>;
};
export type ConfigItems = {
  active: boolean;
  comment: string | null;
  name: string;
  version: number | null;
  light_config: unknown[] | Record<string, unknown>;
  wakeup_times: unknown[] | Record<string, unknown>;
  camera_resolution: CAM_RES;
  camera_autofocus: boolean;
  camera_flashlight: number;
} & TimesFieldsType;
export type ListConfigItem = IdCreatedUpdated & ConfigItems;

export const listConfigs = async (
  params?: ListConfigParams
): Promise<ListResponse<ListConfigItem>> => {
  const { data } = await axios.get<ListResponse<ListConfigItem>>("/api/configs/", {
    params: { active: true, ...params },
  });
  return data;
};

export const listAllConfigs = listAllFactory(listConfigs);

export type DetailConfigParams = {
  id: string;
};
export type DetailConfigItem = ListConfigItem;
export const detailConfig = async ({ id }: DetailConfigParams): Promise<DetailConfigItem> => {
  const { data } = await axios.get<DetailConfigItem>(`/api/configs/${id}`);
  return data;
};

export const updateConfigs = async (
  id: string,
  configParams: ConfigItems
): Promise<DetailConfigItem> => {
  const { data } = await axios.patch<DetailConfigItem>(`/api/configs/${id}`, configParams);
  return data;
};

export const createConfigs = async (configParams: ConfigItems): Promise<DetailConfigItem> => {
  const { data } = await axios.post<DetailConfigItem>(`/api/configs`, configParams);
  return data;
};

export const deleteConfigs = async (id: string): Promise<DetailConfigItem> => {
  const { data } = await axios.delete<DetailConfigItem>(`/api/configs/${id}`);
  return data;
};
