/* eslint-disable no-bitwise */

const makeCRCTable = () => {
  let c;
  const crcTable = [];
  for (let n = 0; n < 256; n++) {
    c = n;
    for (let k = 0; k < 8; k++) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1;
    }
    crcTable[n] = c;
  }
  return crcTable;
};

const toUint32 = (num: number): number => {
  if (num >= 0) {
    return num;
  }
  const a = new Uint32Array(1);
  a[0] = num;
  return a[0];
};

export const bytesToCrc32 = (bytes: Uint8Array): number => {
  let crc = 0xffffffff;
  const lookupTable = makeCRCTable();
  for (let index = 0; index < bytes.length; index++) {
    const tableIndex = (crc ^ bytes[index]) & 0xff;
    const tableVal = lookupTable?.[tableIndex];
    if (tableVal === undefined) throw new Error("tableIndex out of range 0-255");
    crc = (crc >>> 8) ^ tableVal;
  }

  return toUint32(crc ^ 0xffffffff);
};

export const stringToCrc32 = (input: string): number => {
  const bytes = new TextEncoder().encode(input);
  return bytesToCrc32(bytes);
};

export const waitCrc = async (file: File) => {
  let partialData: Uint8Array = new Uint8Array();
  const reader = file.stream().getReader();

  const processResult = (
    result: ReadableStreamReadResult<Uint8Array>
  ): Promise<string> | string => {
    if (result.done) {
      return bytesToCrc32(partialData).toString(16);
    }
    partialData = new Uint8Array([...partialData, ...result.value]);
    return reader.read().then(processResult);
  };
  return reader.read().then(processResult);
};

/* eslint-enable no-bitwise */
