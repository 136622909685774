import React from "react";

import { UseQueryResult } from "@tanstack/react-query";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { IconButton, Skeleton } from "@mui/material";
import PropertyCell, { PropertyCellProps } from ".";

type AsyncPropertyCellProps<TQueryFnData, TError, TData = TQueryFnData> = Omit<
  PropertyCellProps,
  "value"
> & {
  query: UseQueryResult<TData, TError>;
  value: (data: NonNullable<UseQueryResult<TData, TError>["data"]>) => string;
  extendedLabel?: (data: NonNullable<UseQueryResult<TData, TError>["data"]>) => string;
};
const AsyncPropertyCell = <TQueryFnData, TError, TData = TQueryFnData>({
  query,
  value: getValue,
  extendedLabel: getLabel,
  listItemProps,
  ...props
}: AsyncPropertyCellProps<TQueryFnData, TError, TData>): JSX.Element => {
  const { value, extLabel, secondaryAction } = React.useMemo(() => {
    if (query.isInitialLoading) return { value: <Skeleton variant="text" /> };
    if (query.data)
      return { value: getValue(query.data), extLabel: getLabel && getLabel(query.data) };
    if (query.isError) {
      return {
        value: String(query.error),
        secondaryAction: (
          <IconButton
            edge="end"
            aria-label="reload"
            onClick={() => {
              query.refetch();
            }}
          >
            <RefreshOutlinedIcon />
          </IconButton>
        ),
      };
    }
    return { value: "Not defined" };
  }, [getValue, query, getLabel]);

  props.label = extLabel || props.label;

  return (
    <PropertyCell
      value={value === "" ? <br /> : value}
      listItemProps={{
        secondaryAction,
        ...listItemProps,
      }}
      {...props}
    />
  );
};
export default AsyncPropertyCell;
