import { Info } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";
import { detailedStatusToAction, DeviceDetailedStatus } from "utils/device-statuses";

type TrapFixingInstructionsChipProps = {
  detailedStatus: DeviceDetailedStatus;
};

const TrapFixingInstructionsChip: React.FC<TrapFixingInstructionsChipProps> = ({
  detailedStatus,
}) => {
  // get instructions from status
  const instructions = detailedStatusToAction[detailedStatus];

  if (!instructions) {
    return null;
  }

  return <Chip icon={<Info />} label={instructions} color="warning" variant="outlined" />;
};

export default TrapFixingInstructionsChip;
