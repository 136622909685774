import { Paper, styled } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const groupedOrchardViewGridSx = {
  display: "flex",
  flexDirection: "row",
  gap: 5,
  flex: 1,
  p: 1,
  alignItems: "center",
  justifyContent: "center",
};

const formLabelSx = {
  marginLeft: "0.71em",
  marginTop: "-0.71em",
  paddingLeft: "0.44em",
  zIndex: 2,
  backgroundColor: "white",
  fontSize: "0.75em",
};

const gridItemSx = {
  borderRadius: "5px",
  height: "fit-content",
  p: 0.5,
  fontSize: "small",
};

export { Item, groupedOrchardViewGridSx, formLabelSx, gridItemSx };
