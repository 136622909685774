import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import SaveIcon from "@mui/icons-material/Save";

import {
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItem,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import AutoCompleteWithFilter from "components/AutoCompleteWithFilter";
import { listDevicesV2, listDevices } from "queries/devices";
import {
  getUserAllDevice,
  UserManagementType,
  UpdateUserAllDevice,
  listUsers,
  updateUser,
  USER_TYPE,
  UpdateUserParams,
} from "queries/users";
import InfoBox from "pages/devicesConfig/main/Cards/Contents/utils/InfoBox";
import { CreateCorporationParams, createCorporation, listCorporations } from "queries/corporations";
import _ from "lodash";
import readFromClipboard from "utils/commons";
import useCustomSnackBar from "hooks/useCustomSnackBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const CustomerManagement = () => {
  const { jsx: theSnack, setErrorList, setSuccessList } = useCustomSnackBar();

  const [deviceList, setDeviceList] = React.useState<string[]>([]);

  const [userSelectedList, setUserSelectedList] = React.useState<string[]>([]);
  const [corpSelectedList, setCorpSelectedList] = React.useState<string[]>([]);

  const [userToRemove, setUserToRemove] = React.useState<string[]>([]);
  const [userToAdd, setUserToAdd] = React.useState<string[]>([]);
  const [corpToRemove, setCorpToRemove] = React.useState<string[]>([]);
  const [corpToAdd, setCorpToAdd] = React.useState<string[]>([]);

  const [filteredData, setFilteredData] = React.useState<string[]>([]);

  const [userFilter, setUserFilter] = React.useState("");
  const [corpFilter, setCorpFilter] = React.useState("");

  const [userInfo, setUserInfo] = React.useState("");
  const [newCorporation, setNewCorporatoin] = React.useState("");

  const [smappIdFromClipboard, setSmappIdFromClipboard] = React.useState<string[]>([]);

  const onUserFilterChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setUserFilter(value);
      }, 600),
    []
  );
  const onCorpFilterChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setCorpFilter(value);
      }, 600),
    []
  );
  const onCorpChange = React.useMemo(
    () =>
      debounce((e: any, value: string) => {
        setNewCorporatoin(value);
      }, 600),
    []
  );

  const getUserAllDeviceQuery = useQuery(
    ["getUserAllDevice", userInfo],
    () => getUserAllDevice(userInfo),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: userInfo.length > 0,
    }
  );

  const listDevicesQuery = useQuery(
    ["listDevices", smappIdFromClipboard],
    () =>
      listDevicesV2({
        smapp_id__in: smappIdFromClipboard,
        paginator: "off",
        page: 0,
        page_size: 0,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: smappIdFromClipboard.length > 0,
    }
  );

  React.useEffect(() => {
    const devFromClipb = (listDevicesQuery.data || []).map((v) => v.smapp_id);
    if (devFromClipb.length > 0) {
      setDeviceList((pre) => devFromClipb.filter((v) => !pre.includes(v)));
      setSmappIdFromClipboard([]);
    }
  }, [listDevicesQuery.data]);

  const user = getUserAllDeviceQuery.data?.user || ({} as UserManagementType);
  const userDev = getUserAllDeviceQuery.data?.user_dev || [];
  const corpDev = getUserAllDeviceQuery.data?.corp_dev || [];

  const updateUserDeviceMutation = useMutation({
    mutationFn: UpdateUserAllDevice,
    onSuccess: (d) => {
      if (!_.isEmpty(userInfo)) getUserAllDeviceQuery.refetch();
      setSuccessList((pre) => [
        ...pre,
        `user created: ${d.user.create.length} deleted: ${d.user.delete} `,
        `corporation created: ${d.corp.create.length} deleted: ${d.corp.delete} `,
      ]);
    },
  });

  const createCorpMutation = useMutation({
    mutationFn: (params: CreateCorporationParams) => createCorporation(params),
    onSuccess: (data, variables) => {
      if (!_.isEmpty(userInfo)) getUserAllDeviceQuery.refetch();
      setSuccessList((pre) => [...pre, `New corp added as ${variables.name}`]);
    },
  });

  const updateuserMutation = useMutation({
    mutationFn: ({ id, params }: UpdateUserParams) => updateUser({ id, params }),
    onSuccess: (d, val) => {
      if (!_.isEmpty(userInfo)) getUserAllDeviceQuery.refetch();
      const [k, v] =
        Object.entries(val.params)
          .filter(([k, v]) => v !== undefined)
          .at(0) || "none";
      setSuccessList((pre) => [...pre, `${k as string} updated`]);
    },
  });

  const [userPassword, setUserPassword] = React.useState("");
  const onPasswordChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setUserPassword(value);
      }, 600),
    []
  );

  const [userName, setUserName] = React.useState("");
  const onUserNameChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(value);
      }, 600),
    []
  );

  const [userEmail, setUserEmail] = React.useState("");
  const onEmailChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(value);
      }, 600),
    []
  );

  const [open, setOpen] = React.useState({ userField: "" });
  const editPassword = (
    <>
      <KeyIcon onClick={() => setOpen({ userField: "pass" })} />
      <Modal
        open={open.userField === "pass"}
        onClose={() => setOpen({ userField: "" })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, display: "flex" }}>
          <TextField label="Password" fullWidth onChange={onPasswordChange} />
          <Tooltip title="Save password">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (!_.isEmpty(user) && userPassword.length > 0) {
                  updateuserMutation.mutate({ id: user.id, params: { password: userPassword } });
                  setOpen({ userField: "" });
                } else {
                  setErrorList((pre) => [...pre, "Before edit, select a user and type a password"]);
                }
              }}
            >
              <SaveIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    </>
  );

  const editUsername = (
    <>
      <EditIcon onClick={() => setOpen({ userField: "username" })} />
      <Modal
        open={open.userField === "username"}
        onClose={() => setOpen({ userField: "" })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, display: "flex" }}>
          <TextField
            label="Username"
            defaultValue={user.username}
            fullWidth
            onChange={onUserNameChange}
          />
          <Tooltip title="Save username">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (!_.isEmpty(user) && userName.length > 0) {
                  updateuserMutation.mutate({ id: user.id, params: { username: userName } });
                  setOpen({ userField: "" });
                } else {
                  setErrorList((pre) => [...pre, "Before edit, select a user and type a username"]);
                }
              }}
            >
              <SaveIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    </>
  );

  const editEmail = (
    <>
      <EditIcon onClick={() => setOpen({ userField: "email" })} />
      <Modal
        open={open.userField === "email"}
        onClose={() => setOpen({ userField: "" })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, display: "flex" }}>
          <TextField label="Email" defaultValue={user.email} fullWidth onChange={onEmailChange} />
          <Tooltip title="Save email">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (!_.isEmpty(user) && userEmail.length > 0) {
                  updateuserMutation.mutate({ id: user.id, params: { email: userEmail } });
                  setOpen({ userField: "" });
                } else {
                  setErrorList((pre) => [...pre, "Before edit, select a user and type a email"]);
                }
              }}
            >
              <SaveIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    </>
  );

  const editType = (
    <>
      <EditIcon onClick={() => setOpen({ userField: "type" })} />
      <Modal
        open={open.userField === "type"}
        onClose={() => setOpen({ userField: "" })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Select
            value={user.type}
            fullWidth
            label="User Type"
            onChange={(e) => {
              if (!_.isEmpty(user)) {
                if (e.target.value === USER_TYPE.ADMIN) {
                  updateuserMutation.mutate({
                    id: user.id,
                    params: { type: e.target.value as USER_TYPE, corporation: null },
                  });
                  setOpen({ userField: "" });
                } else {
                  updateuserMutation.mutate({
                    id: user.id,
                    params: { type: e.target.value as USER_TYPE },
                  });
                  setOpen({ userField: "" });
                }
              } else {
                setErrorList((pre) => [...pre, "Before edit, select a user"]);
              }
            }}
          >
            <MenuItem value={USER_TYPE.ADMIN}>Admin</MenuItem>
            <MenuItem value={USER_TYPE.REGULAR}>Regular</MenuItem>
            <MenuItem value={USER_TYPE.CORPORATE}>Corporate</MenuItem>
          </Select>
        </Box>
      </Modal>
    </>
  );

  const editCorporation = (
    <>
      <EditIcon onClick={() => setOpen({ userField: "corp" })} />
      <Modal
        open={open.userField === "corp"}
        onClose={() => {
          setOpen({ userField: "" });
          setNewCorporatoin("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, display: "flex", alignItems: "center", gap: 1 }}>
          <Tooltip title="Add new Corporation">
            <span>
              <Button
                variant="contained"
                sx={{ minWidth: 0, minHeight: 0, width: 0, height: 0.5 }}
                disabled={filteredData.length > 0}
                onClick={() => {
                  createCorpMutation.mutate({ name: newCorporation });
                  setOpen({ userField: "" });
                }}
              >
                <AddIcon />
              </Button>
            </span>
          </Tooltip>
          <AutoCompleteWithFilter
            boxSx={{ width: "100%" }}
            freeSolo
            filteredData={setFilteredData}
            key="Corporations"
            label="Corporations"
            qKey="corpMan"
            queryParamsKey={["name"]}
            onInputChange={onCorpChange}
            qFn={listCorporations}
            lookup="icontains"
            size="small"
            fullWidth
            onChange={(e, v) => {
              if (!_.isEmpty(user) && v) {
                updateuserMutation.mutate({
                  id: user.id,
                  params: { corporation: v.value, type: USER_TYPE.CORPORATE },
                });
                setOpen({ userField: "" });
              } else {
                setErrorList((pre) => [...pre, "Before edit, select a user and valid corporation"]);
              }
            }}
          />
        </Box>
      </Modal>
    </>
  );

  return (
    <Card
      sx={{
        display: "flex",
        flexFlow: "column",
        width: "100%",
        gap: 2,
        p: 1,
        m: 1,
        alignItems: "center",
      }}
    >
      {theSnack}
      <Box
        className="UserDetails"
        sx={{ display: "flex", flexFlow: "column", width: "100%", alignItems: "center" }}
      >
        <Box sx={{ width: "33%" }}>
          <Box sx={{ display: "flex", flexFlow: "row", alignItems: "center", gap: 1, p: 1 }}>
            <AutoCompleteWithFilter
              boxSx={{ width: "100%" }}
              key="User"
              label="User"
              qKey="userMan"
              qFn={listUsers}
              queryParamsKey={["username", "email"]}
              extraQueryParams={{ active: true, or: true }}
              keyToLabelList={["email", "username"]}
              lookup="icontains"
              size="small"
              fullWidth
              onChange={(e, value) => {
                if (value) {
                  setUserInfo(value.value);
                }
              }}
            />
            {editPassword}
          </Box>
          <Typography sx={{ ml: 2, flex: 1, display: "flex" }} variant="subtitle1" component="div">
            {editUsername} Username: {user.username}
          </Typography>
          <Typography sx={{ ml: 2, flex: 1, display: "flex" }} variant="subtitle1" component="div">
            {editEmail} Email: {user.email}
          </Typography>
          <Typography sx={{ ml: 2, flex: 1, display: "flex" }} variant="subtitle1" component="div">
            {editCorporation} Corporation: {user.corporation}
          </Typography>
          <Typography sx={{ ml: 2, flex: 1, display: "flex" }} variant="subtitle1" component="div">
            {editType} Type: {user.type}
          </Typography>
        </Box>
      </Box>

      <Box
        className="allDeviceBoxes"
        sx={{
          display: "flex",
          flexFlow: "row",
          width: "80%",
          gap: 4,
          justifyContent: "center",
        }}
      >
        <Box
          className="UserDevices"
          sx={{
            display: "flex",
            flexFlow: "column",
            width: "max-content",
            gap: 0.5,
          }}
        >
          <Box
            sx={{ width: "100%", display: "flex", flexFlow: "row", gap: 1, alignItems: "center" }}
          >
            <Tooltip title="Select Devices From Clipboard">
              <Button
                sx={{ minWidth: 0, minHeight: 0, width: 0, height: 0.5 }}
                size="small"
                variant="contained"
                onClick={async () => {
                  const clipList = await readFromClipboard();
                  setUserSelectedList(clipList.filter((v) => userDev.includes(v)));
                }}
              >
                <AddIcon fontSize="small" />
              </Button>
            </Tooltip>
            <TextField
              sx={{ width: "100%" }}
              label="User devices"
              variant="outlined"
              size="small"
              onChange={onUserFilterChange}
            />
            {userSelectedList.length > 0 && <InfoBox info={userSelectedList} />}
          </Box>
          <Card
            sx={{
              height: "200px",
              width: "250px",
              overflowY: "scroll",
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <List>
              {userDev
                .filter((v) => v.includes(userFilter === "" ? v : userFilter))
                .map((v) => {
                  return (
                    <ListItem
                      onClick={() => {
                        if (userSelectedList.includes(v)) {
                          setUserSelectedList((pre) => pre.filter((vvv) => vvv !== v));
                          return;
                        }
                        setUserSelectedList((pre) => [...pre, v]);
                      }}
                      sx={{
                        backgroundColor: userSelectedList.includes(v) ? "orange" : "",
                        borderRadius: "5px",
                        p: 0.125,
                        pl: 0.5,
                        fontSize: "small",
                        m: 0.5,
                      }}
                      key={v}
                    >
                      {v}
                    </ListItem>
                  );
                })}
            </List>
          </Card>
          <Box sx={{ display: "flex", flexFlow: "row", gap: 1.5 }}>
            <Tooltip title="Clear selection">
              <Button
                sx={{ minWidth: 0, minHeight: 0 }}
                variant="contained"
                onClick={() => setUserSelectedList([])}
              >
                <ClearIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="Select all">
              <Button
                sx={{ minWidth: 0, minHeight: 0 }}
                variant="contained"
                onClick={() => setUserSelectedList(userDev || [])}
              >
                <SelectAllIcon fontSize="small" />
              </Button>
            </Tooltip>
            Selected: {userSelectedList.length}
          </Box>
        </Box>
        {/* ************* */}
        {/* ************* */}
        {/* LEFT BUTTONS */}
        {/* ************* */}
        {/* ************* */}
        <Box
          className="leftButtons"
          sx={{
            display: "flex",
            flexFlow: "column",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Tooltip title="Remove Device from User">
            <Button
              sx={{ minWidth: 0, minHeight: 0, width: 0.6 }}
              size="small"
              variant="contained"
              onClick={() => {
                setUserToRemove((pre) => [
                  ...pre,
                  ...userSelectedList.filter((vv) => !pre.includes(vv) && !userToAdd.includes(vv)),
                ]);
                setUserSelectedList([]);
              }}
            >
              <RemoveIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Add Device to User">
            <Button
              sx={{ minWidth: 0, minHeight: 0, width: 0.6 }}
              size="small"
              variant="contained"
              onClick={() => {
                setUserToAdd((pre) => [
                  ...pre,
                  ...deviceList.filter(
                    (vv) => !userToRemove.includes(vv) && !pre.includes(vv) && !userDev.includes(vv)
                  ),
                ]);
                setDeviceList([]);
              }}
            >
              <NavigateBeforeIcon fontSize="small" />
              <AddIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>

        {/* ************* */}
        {/* ************* */}
        {/* DEVICES LIST User/Corp Add/Remove */}
        {/* ************* */}
        {/* ************* */}

        <Box
          className="selectDevice"
          sx={{
            display: "flex",
            flexFlow: "column",
            gap: 0.5,
          }}
        >
          <Box
            sx={{ width: "100%", display: "flex", flexFlow: "row", gap: 1, alignItems: "center" }}
          >
            <Tooltip title="Add Devices From Clipboard">
              <Button
                sx={{ minWidth: 0, minHeight: 0, width: 0, height: 0.5 }}
                size="small"
                variant="contained"
                onClick={async () => {
                  const clipList = await readFromClipboard();
                  setSmappIdFromClipboard(clipList);
                }}
              >
                <AddIcon fontSize="small" />
              </Button>
            </Tooltip>
            <AutoCompleteWithFilter
              boxSx={{ width: "100%" }}
              key={deviceList.length} // cuz of this, it will clear input field
              label="Devices"
              qKey="user-update-devices"
              qFn={listDevices}
              queryParamsKey={["smapp_id"]}
              extraQueryParams={{ active: true }}
              keyToLabelList={["smapp_id"]}
              lookup="icontains"
              size="small"
              fullWidth
              onChange={(e, value) => {
                if (value) {
                  setDeviceList((pre) => [...new Set([...pre, value.label])]);
                }
              }}
            />
            {deviceList.length > 0 && <InfoBox info={deviceList} />}
          </Box>
          <Card
            sx={{
              height: "200px",
              width: "250px",
              overflowY: "scroll",
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <List>
              {deviceList.map((v) => {
                return (
                  <ListItem
                    onClick={() => setDeviceList((pre) => pre.filter((vvv) => vvv !== v))}
                    sx={{
                      borderRadius: "5px",
                      p: 0.125,
                      pl: 0.5,
                      fontSize: "small",
                      m: 0.5,
                    }}
                    key={v}
                  >
                    {v}
                  </ListItem>
                );
              })}
              <Divider variant="middle" component="li" />
              {userToAdd.map((v) => {
                return (
                  <ListItem
                    onClick={() => setUserToAdd((pre) => pre.filter((vvv) => vvv !== v))}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#90EE90",
                      p: 0.125,
                      pl: 0.5,
                      fontSize: "small",
                      m: 0.5,
                      justifyContent: "space-between",
                    }}
                    key={v}
                  >
                    <Box>{v}</Box>
                    <Box>+ user</Box>
                  </ListItem>
                );
              })}
              <Divider variant="middle" component="li" />
              {corpToAdd.map((v) => {
                return (
                  <ListItem
                    onClick={() => setCorpToAdd((pre) => pre.filter((vvv) => vvv !== v))}
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#90EE90",
                      p: 0.125,
                      pl: 0.5,
                      fontSize: "small",
                      m: 0.5,
                      justifyContent: "space-between",
                    }}
                    key={v}
                  >
                    <Box>{v}</Box>
                    <Box>+ corp</Box>
                  </ListItem>
                );
              })}
              <Divider variant="middle" component="li" />
              {userToRemove.map((v) => {
                return (
                  <ListItem
                    onClick={() => setUserToRemove((pre) => pre.filter((vvv) => vvv !== v))}
                    sx={{
                      backgroundColor: "#FFCCCB",
                      borderRadius: "5px",
                      p: 0.125,
                      pl: 0.5,
                      fontSize: "small",
                      m: 0.5,
                      justifyContent: "space-between",
                    }}
                    key={v}
                  >
                    <Box>{v}</Box>
                    <Box>- User</Box>
                  </ListItem>
                );
              })}
              <Divider variant="middle" component="li" />
              {corpToRemove.map((v) => {
                return (
                  <ListItem
                    onClick={() => setCorpToRemove((pre) => pre.filter((vvv) => vvv !== v))}
                    sx={{
                      backgroundColor: "#FFCCCB",
                      borderRadius: "5px",
                      p: 0.125,
                      pl: 0.5,
                      fontSize: "small",
                      m: 0.5,
                      justifyContent: "space-between",
                    }}
                    key={v}
                  >
                    <Box>{v}</Box>
                    <Box>- Corp</Box>
                  </ListItem>
                );
              })}
            </List>
          </Card>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Save changes">
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  updateUserDeviceMutation.mutate({
                    user,
                    remove_from_user: userToRemove,
                    add_to_user: userToAdd,
                    remove_from_corp: corpToRemove,
                    add_to_corp: corpToAdd,
                  });

                  setUserToRemove([]);
                  setUserToAdd([]);
                  setCorpToRemove([]);
                  setCorpToAdd([]);
                  setUserSelectedList([]);
                  setCorpSelectedList([]);
                }}
              >
                Save
              </Button>
            </Tooltip>
            To assign: {deviceList.length}
          </Box>
        </Box>
        {/* ************* */}
        {/* ************* */}
        {/* RIGHT BUTTONS */}
        {/* ************* */}
        {/* ************* */}
        <Box
          className="rightButtons"
          sx={{
            display: "flex",
            flexFlow: "column",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Tooltip title="Remove Device from Corporate">
            <Button
              sx={{ minWidth: 0, minHeight: 0, width: 0.6 }}
              size="small"
              variant="contained"
              onClick={() => {
                setCorpToRemove((pre) => [
                  ...pre,
                  ...corpSelectedList.filter((vv) => !pre.includes(vv) && !corpToAdd.includes(vv)),
                ]);
                setCorpSelectedList([]);
              }}
            >
              <NavigateBeforeIcon fontSize="small" />
              <RemoveIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Add Device to Corporate">
            <Button
              sx={{ minWidth: 0, minHeight: 0, width: 0.6 }}
              size="small"
              variant="contained"
              onClick={() => {
                setCorpToAdd((pre) => [
                  ...pre,
                  ...deviceList.filter(
                    (vv) => !corpToRemove.includes(vv) && !pre.includes(vv) && !corpDev.includes(vv)
                  ),
                ]);
                setDeviceList([]);
              }}
            >
              <AddIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", flexFlow: "column", width: "max-content", gap: 0.5 }}>
          <Box
            sx={{ width: "100%", display: "flex", flexFlow: "row", gap: 1, alignItems: "center" }}
          >
            <Tooltip title="Select Devices From Clipboard">
              <Button
                sx={{ minWidth: 0, minHeight: 0, width: 0, height: 0.5 }}
                size="small"
                variant="contained"
                onClick={async () => {
                  const clipList = await readFromClipboard();
                  setCorpSelectedList(clipList.filter((v) => corpDev.includes(v)));
                }}
              >
                <AddIcon fontSize="small" />
              </Button>
            </Tooltip>
            <TextField
              sx={{ width: "100%" }}
              label="Corp devices"
              variant="outlined"
              size="small"
              onChange={onCorpFilterChange}
            />
            {corpSelectedList.length > 0 && <InfoBox info={corpSelectedList} />}
          </Box>

          <Card
            sx={{
              height: "200px",
              width: "250px",
              overflowY: "scroll",
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <List>
              {corpDev
                .filter((v) => v.includes(corpFilter === "" ? v : corpFilter))
                .map((v) => {
                  return (
                    <ListItem
                      onClick={() => {
                        if (corpSelectedList.includes(v)) {
                          setCorpSelectedList((pre) => pre.filter((vvv) => vvv !== v));
                          return;
                        }
                        setCorpSelectedList((pre) => [...pre, v]);
                      }}
                      sx={{
                        backgroundColor: corpSelectedList.includes(v) ? "orange" : "",
                        borderRadius: "5px",
                        p: 0.125,
                        pl: 0.5,
                        fontSize: "small",
                        m: 0.5,
                      }}
                      key={v}
                    >
                      {v}
                    </ListItem>
                  );
                })}
            </List>
          </Card>

          <Box sx={{ display: "flex", flexFlow: "row", gap: 1.5 }}>
            <Tooltip title="Clear selection">
              <Button
                sx={{ minWidth: 0, minHeight: 0 }}
                variant="contained"
                onClick={() => setCorpSelectedList([])}
              >
                <ClearIcon fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="Select all">
              <Button
                sx={{ minWidth: 0, minHeight: 0 }}
                variant="contained"
                onClick={() => setCorpSelectedList(corpDev || [])}
              >
                <SelectAllIcon fontSize="small" />
              </Button>
            </Tooltip>
            Selected: {corpSelectedList.length}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CustomerManagement;
