import React from "react";
import { Chip, Tooltip } from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

type CorporateChipProps = {
  value?: string;
};
const CorporateChip: React.FC<CorporateChipProps> = ({ value }) => {
  return (
    <Tooltip title="Corporate" arrow>
      <Chip icon={<CorporateFareIcon />} label={value ?? "Unknown"} variant="outlined" />
    </Tooltip>
  );
};

export default CorporateChip;
