import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./utils/axios";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "context/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CssBaseline } from "@mui/material";
import UserPreferencesProvider from "context/UserPreferencesProvider";
import DrawerProvider from "context/DrawerProvider";
import AboutProvider from "context/AboutProvider";
import ThemeProvider from "context/ThemeProvider";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
// import NavProvider from "context/NavProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { PostHogProvider } from "posthog-js/react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_FE_ENV,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // TODO: check if we need to set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // traces
  tracesSampleRate: Number.isNaN(Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE))
    ? 1
    : Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const posthogOptions = {
  api_host: "https://eu.posthog.com",
  // use localstorage, so technically no cookies
  persistence: "localStorage",
} as const;

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_KEY} options={posthogOptions}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AuthProvider>
                <UserPreferencesProvider>
                  <ThemeProvider>
                    <DrawerProvider>
                      <CssBaseline />
                      <AboutProvider>
                        <App />
                      </AboutProvider>
                    </DrawerProvider>
                  </ThemeProvider>
                </UserPreferencesProvider>
              </AuthProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
