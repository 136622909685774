import React, { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import { FirmwareNameAndVersions, boardMajorMinorPatch } from "queries/firmwares";
import { useDebounce } from "use-debounce";
import useGenericQuery from "hooks/useGenericQuery";
import { UpdateDeviceSettingsFormValues } from "./types";

type CommonListResponse = {
  id: string;
  name?: string;
};

export type GenericListResponse =
  | CommonListResponse
  | (CommonListResponse & FirmwareNameAndVersions);

type DeviceFieldsType = {
  name: string;
  id: string;
  label: string | null | undefined;
  registerName: string;
  query: any;
};

export type AutocompleteWithInfinityQueryProps = {
  defaultIdName: {
    value: string;
    label: string;
  } | null;
} & DeviceFieldsType;

const AutocompleteWithInfinityQuery = <
  T extends object & { id: string; name: string } & FirmwareNameAndVersions
>({
  defaultIdName,
  name,
  registerName,
  query,
}: AutocompleteWithInfinityQueryProps) => {
  const {
    formState: { errors },
    control,
  } = useFormContext<UpdateDeviceSettingsFormValues>();

  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 600);
  const genericListQuery = useGenericQuery<T>({
    qKey: name,
    debouncedSearch,
    fn: query,
    queryParamsKey: ["name__icontains"],
  });

  const genericData = (genericListQuery.data?.pages ?? [])?.map((it) => it.data).flat();

  const options = genericData.map((v) => ({
    label: v.name ?? boardMajorMinorPatch(v as FirmwareNameAndVersions),
    value: v.id,
  }));

  if (defaultIdName && options.every((v) => v.value !== defaultIdName.value)) {
    options.push(defaultIdName);
  }

  const err = errors[registerName as keyof UpdateDeviceSettingsFormValues];
  const errMessage = err?.message;
  const isError = !!err;

  return (
    <Controller
      name={registerName as keyof UpdateDeviceSettingsFormValues}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          sx={{ fontSize: "1px", flexBasis: "49%" }}
          options={options}
          defaultValue={defaultIdName}
          onChange={(_e, data) => {
            onChange(data?.value);
          }}
          onInputChange={(_event, value: string, _reason) => {
            const searchedValueFoundInLabel = options.some(
              (v) => v.label.includes(value) && value.length > 0
            );
            if (!searchedValueFoundInLabel) setSearch(value);
          }}
          isOptionEqualToValue={(option, value) =>
            option.value === value.value || value.label === ""
          }
          ListboxProps={{
            style: { maxHeight: 200 },
            onScroll: (event: React.SyntheticEvent) => {
              const listboxNode = event.currentTarget;
              if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight) {
                if (genericListQuery) {
                  genericListQuery.fetchNextPage();
                }
              }
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              name={registerName}
              label={name}
              error={isError}
              helperText={isError && errMessage}
              variant="outlined"
            />
          )}
        />
      )}
    />
  );
};

export type DeviceFieldsProps = {
  params: DeviceFieldsType[];
};

const DeviceFields: React.FC<DeviceFieldsProps> = ({ params }) => {
  const content = useMemo(() => {
    return params.map((v) => {
      const label = v.label ?? "";
      const value = v.id;

      let cntnt = null;
      if (value === null) {
        cntnt = <AutocompleteWithInfinityQuery key={v.name} defaultIdName={null} {...v} />;
      } else if (value && value.length > 0 && label.length > 0) {
        cntnt = (
          <AutocompleteWithInfinityQuery key={v.name} defaultIdName={{ value, label }} {...v} />
        );
      }
      return cntnt;
    });
  }, [params]);

  return <>{content}</>;
};

export default DeviceFields;
