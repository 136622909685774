import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { Controller, useFormContext } from "react-hook-form";
import DynamicConfigFields from "./DynamicConfigFields";

const InputLigthConfig: React.FC<{ currentIdx: number | null }> = ({ currentIdx }) => {
  const { control, getValues } = useFormContext();

  if (currentIdx === null) {
    return <>Loading...</>;
  }

  const duration = getValues(`light.fields[${currentIdx}].duration`);
  const intensity = getValues(`light.fields[${currentIdx}].intensity`);

  return (
    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
      <FormLabel style={{ fontWeight: "bold" }}>Light config</FormLabel>

      <DynamicConfigFields
        dynamicLabel="Start"
        controllerParentName={`light.fields[${currentIdx}].dynamicConfig`}
      />

      <CardContent sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
        <FormLabel sx={{ display: "flex", alignItems: "center" }}>Duration</FormLabel>
        <Controller
          name={`light.fields[${currentIdx}].duration`}
          control={control}
          render={({ field: { value, ...rest } }) => (
            <TextField
              {...rest}
              value={value || ""}
              label="min"
              size="small"
              InputLabelProps={{ shrink: duration !== null }}
            />
          )}
        />
      </CardContent>

      <CardContent sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
        <FormLabel sx={{ display: "flex", alignItems: "center" }}>Intensity</FormLabel>
        <Controller
          name={`light.fields[${currentIdx}].intensity`}
          control={control}
          render={({ field: { value, ...rest } }) => (
            <TextField
              {...rest}
              value={value || ""}
              label="%"
              size="small"
              InputLabelProps={{ shrink: intensity !== null }}
            />
          )}
        />
      </CardContent>
    </CardContent>
  );
};

export default InputLigthConfig;
