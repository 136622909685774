import React from "react";
import { Box, ListSubheader, Paper } from "@mui/material";
import BatteryVoltageChart, {
  BatteryVoltageChartProps,
  BATTERY_VOLTAGE_CHART_CONFIG,
} from "components/visualization/BatteryVoltageChart";
import useDateRange from "hooks/useDateRange";
import { AVG_MEASUREMENT_FREQUENCY } from "queries/devices/averageMeasurements";

type BatteryVoltageChartPanelProps = Pick<BatteryVoltageChartProps, "deviceIdIn">;
const BatteryVoltageChartPanel: React.FC<BatteryVoltageChartPanelProps> = ({ deviceIdIn }) => {
  const { dateRange, form: dateRangeForm } = useDateRange({ defaultDuration: { days: 30 * 12 } });

  const title = deviceIdIn?.length === 1 ? "Battery voltage" : "Average Battery voltage";

  return (
    <Paper
      elevation={3}
      sx={{
        pt: 3,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 1, display: "flex" }}>
        <ListSubheader sx={{ flex: 1 }}>{title}</ListSubheader>
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <BatteryVoltageChart
          deviceIdIn={deviceIdIn}
          from={dateRange.from}
          to={dateRange.to}
          chartConfig={[{ ...BATTERY_VOLTAGE_CHART_CONFIG, name: title }]}
          frequency={AVG_MEASUREMENT_FREQUENCY.D1}
        />
      </Box>
    </Paper>
  );
};

export default BatteryVoltageChartPanel;
