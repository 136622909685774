import React from "react";
import { DEVICE_TYPE, DetailDeviceItem } from "queries/devices";
import { DetectionCountsByDevicesChartPanel } from "components/visualization/DetectionCountDeltaChartPanel";
import MeasurementChartAccordion from "components/visualization/MeasurementChartAccordion";

type ChartPanelProps = {
  device: DetailDeviceItem;
};
const ChartPanel: React.FC<ChartPanelProps> = ({ device }) => (
  <>
    <DetectionCountsByDevicesChartPanel deviceID={device.id} aggregations="sum" />
    <DetectionCountsByDevicesChartPanel deviceID={device.id} aggregations="sum" frequency="week" />
    {device.type === DEVICE_TYPE.Premium && <MeasurementChartAccordion deviceIdIn={[device.id]} />}
  </>
);

export default ChartPanel;
