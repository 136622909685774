/* eslint-disable import/prefer-default-export */
import CONFIG from "config";
import { ListResponse } from "queries/types";
import flatten from "lodash/flatten";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import { isNotNil } from "./lodash";

// Put it better place than this
export type Aggregations = "avg" | "sum";
export type Frequency = "day" | "week";

type Params = Record<"page" | "page_size", number> & { [x: string]: unknown };

type ListAllFactoryOptions = {
  isHuge?: boolean;
};

export const listAllFactory = <TParams extends Params, TListItem>(
  list: (params?: TParams) => Promise<ListResponse<TListItem>>,
  options?: ListAllFactoryOptions
) => {
  const { isHuge } = options ?? {};

  const fn = async (
    params?: Record<Exclude<string, "page" | "page_size">, unknown>
  ): Promise<ListResponse<TListItem>> => {
    const firstPage = await list({
      page: 1,
      page_size: isHuge ? CONFIG.api.maxHugePageSize : CONFIG.api.maxPageSize,
      ...params,
    } as TParams);
    const maxPage = Math.ceil(firstPage.meta.count / firstPage.meta.page_size);
    const promises = [];
    for (let i = 1; i < maxPage; i++) {
      promises.push(
        list({
          page: i + 1,
          page_size: firstPage.meta.page_size,
          ...params,
        } as TParams)
      );
    }
    const data = flatten([
      firstPage.data,
      ...(await Promise.all(promises).then((pages) => pages.map(({ data }) => data))),
    ]);

    return {
      meta: {
        count: firstPage.meta.count,
        page_size: firstPage.meta.count,
        previous: null,
        next: null,
        page: 1,
      },
      data,
    };
  };
  return fn;
};

export const fetchResourceByIds = async <TListItem>(
  items: (string | null)[],
  listAllFn: (params?: Record<string, unknown>) => Promise<ListResponse<TListItem>>
) => {
  const idIn = uniq(items).filter(isNotNil);
  let itemsById: Record<string, TListItem> = {};
  if (!isEmpty(idIn)) {
    const { data } = await listAllFn({ id__in: idIn });
    itemsById = keyBy(data, "id");
  }
  return itemsById;
};

export const customFetchResource = async <TListItem>(
  listAllFn: (params?: Record<string, unknown>) => Promise<ListResponse<TListItem>>
) => {
  let itemsById: Record<string, TListItem> = {};
  const { data } = await listAllFn();
  itemsById = keyBy(data, "id");
  return itemsById;
};

export const resolveKey = <TItem extends Record<string, unknown>>(
  value: null | string | TItem,
  itemsById: Record<string, TItem>
): null | TItem => {
  if (value === null) return null;
  if (isString(value)) {
    return itemsById?.[value] ?? null;
  }
  return value;
};
