import React from "react";
import { Box, CardContent, Dialog, FormControlLabel, FormLabel, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { ToolTippedButton } from "../utils/common.fn";
import { defaultLightConfigFields } from "../utils/defaults";
import { DynamicConfigFieldsType } from "../utils/types";
import InputLigthConfig from "./LigthConfig";

const LightConfigListColumns: React.FC<{
  idx: number;
  isEnabled: boolean;
  EditOnClick: () => void;
  RemoveOnClick: () => void;
}> = ({ idx, isEnabled, EditOnClick, RemoveOnClick }) => {
  const { getValues } = useFormContext();

  const getDynamicConfig = getValues(
    `light.fields[${idx}].dynamicConfig`
  ) as DynamicConfigFieldsType;

  if (!isEnabled) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContentt: "space-around",
      }}
    >
      {Object.keys(defaultLightConfigFields.dynamicConfig).map((k) => (
        <Box sx={{ width: "50%", textAlign: "center" }} key={k}>
          {getDynamicConfig[k as keyof DynamicConfigFieldsType]}
        </Box>
      ))}
      <Box sx={{ width: "50%", textAlign: "center" }}>
        {getValues(`light.fields[${idx}].duration`)}
      </Box>
      <Box sx={{ width: "50%", textAlign: "center" }}>
        {getValues(`light.fields[${idx}].intensity`)}
      </Box>
      <Box sx={{ display: "flex", width: "50%", gap: 1, justifyContent: "flex-end" }}>
        <ToolTippedButton tooltip="Edit Item" onClick={EditOnClick} Icon={<EditIcon />} />
        <ToolTippedButton tooltip="Remove Item" onClick={RemoveOnClick} Icon={<RemoveIcon />} />
      </Box>
    </Box>
  );
};

const LightConfigList: React.FC = () => {
  const [stateIdx, setStateIdx] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState(false);
  const { control, getValues } = useFormContext();

  const [lightDisabled, setLightDisabled] = React.useState(false);

  React.useEffect(() => {
    setLightDisabled(getValues("light.enabled"));
  }, [getValues, setLightDisabled]);

  const {
    fields: parameterfields,
    append: appendParameters,
    remove: removeParameters,
  } = useFieldArray({
    control,
    name: "light.fields",
  });

  const handleStates = (idx: number) => {
    setOpen(true);
    setStateIdx(idx);
  };

  const addNewItem = (
    <ToolTippedButton
      disabled={lightDisabled}
      tooltip="Add New Item"
      onClick={() => {
        handleStates(parameterfields.length);
        appendParameters(defaultLightConfigFields);
      }}
      Icon={<AddIcon />}
    />
  );

  const enableLight = (
    <FormControlLabel
      control={
        <Controller
          name="light.enabled"
          control={control}
          render={({ field }) => (
            <Switch
              onChange={(e) => {
                field.onChange(e.target.checked);
                setLightDisabled(!e.target.checked);
              }}
              checked={field.value || false}
            />
          )}
        />
      }
      label="Enabled"
    />
  );

  const lightListHeader = (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {["sssr", "plusMinus", "offset", "stat", "duration", "intensity", ""].map((v) => (
        <Box key={JSON.stringify(v)} sx={{ width: "50%", textAlign: "center" }}>
          {v}
        </Box>
      ))}
    </Box>
  );

  return (
    <CardContent>
      <Box sx={{ display: "flex", m: 1, gap: 1 }}>
        <FormLabel style={{ fontWeight: "bold" }}>Light config</FormLabel>
        {addNewItem}
      </Box>
      {enableLight}

      {lightListHeader}
      <Box sx={{ minHeight: "100px", overflowY: "scroll" }}>
        {parameterfields.map((v, idx) => {
          return (
            <LightConfigListColumns
              key={JSON.stringify(v)}
              idx={idx}
              isEnabled={!lightDisabled}
              EditOnClick={() => handleStates(idx)}
              RemoveOnClick={() => removeParameters(idx)}
            />
          );
        })}
      </Box>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px", // Set your width here
            },
          },
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <InputLigthConfig currentIdx={stateIdx} />
      </Dialog>
    </CardContent>
  );
};

export default LightConfigList;
