import React from "react";

import { Typography } from "@mui/material";

const NothingToShow: React.FC = () => (
  <Typography variant="overline" component="div" sx={{ margin: "auto", textAlign: "center" }}>
    Nothing to show
  </Typography>
);

export default NothingToShow;
