import React from "react";
import {
  Area,
  AreaChart,
  AreaProps,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export type ChartProps<TKey extends Exclude<string, "x">> = {
  chartConfig: (AreaProps & { dataKey: TKey })[];
  data: Record<string, Record<TKey, number | null | undefined>>;
  yAxisDomain?: number[];
};

const Chart = <TKey extends Exclude<string, "x">>({
  chartConfig,
  data: propData,
  yAxisDomain,
}: ChartProps<TKey>) => {
  const data = React.useMemo(
    () =>
      Object.entries(propData).map(([k, v]) => ({
        x: k,
        ...v,
      })),
    [propData]
  );
  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={{ right: 32 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" />
        {yAxisDomain ? <YAxis domain={yAxisDomain} /> : <YAxis />}
        <Tooltip />
        <Legend />
        {chartConfig.map((props) => (
          // @ts-expect-error recharts type error
          <Area key={props} {...props} />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Chart;
