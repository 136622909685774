import { Box, List, ListItem, PaperProps, Popover, Typography } from "@mui/material";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";

type InfoBoxProps = PaperProps & {
  info: string[];
};

const InfoBox: React.FC<InfoBoxProps> = ({ info }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const listItemSx = {
    p: 0.125,
    pl: 0.5,
    fontSize: "small",
    m: 0.5,
  };
  return (
    <Box>
      <div onMouseEnter={handleOpen}>
        <HelpIcon fontSize="small" />
      </div>
      <Popover
        className="PopoverInfo"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <List>
          {info.sort().map((v) => {
            return (
              <ListItem key={v} sx={{ ...listItemSx, fontSize: "0.75em" }}>
                {v}
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </Box>
  );
};

export const InfoBoxV2: React.FC<
  {
    text: string;
    infoText: string;
  } & PaperProps
> = ({ text, infoText }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopup = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={openPopup ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {text}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openPopup}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{infoText}</Typography>
      </Popover>
    </div>
  );
};

export default InfoBox;
