import React from "react";
import { Chip, Tooltip } from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { isNotNil } from "utils/lodash";
import { formatDistance } from "date-fns";
// TODO colors

type LastSyncChipProps = {
  value?: number | null;
};
const LastSyncChip: React.FC<LastSyncChipProps> = ({ value }) => {
  return (
    <Tooltip title="Last sync" arrow>
      <Chip
        icon={<MapOutlinedIcon />}
        label={
          isNotNil(value)
            ? formatDistance(new Date(value * 1000), new Date(), {
                addSuffix: true,
              })
            : "Unknown"
        }
        variant="outlined"
      />
    </Tooltip>
  );
};

export default LastSyncChip;
