import React, { useState } from "react";
import { AxiosResponse } from "axios";
import Snackbar from "@mui/material/Snackbar";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";

import useGenericQuery from "hooks/useGenericQuery";
import { ListConfigItem, listConfigs } from "queries/configs";
import { useFormContext } from "react-hook-form";
import { useDebounce } from "use-debounce";
import {
  useCreateConfigMutation,
  useDeleteConfigMutation,
  useUpdateConfigMutation,
} from "./reactQuerys";
import { ButtonWithRequest, makeConfigPayload } from "../utils/common.fn";
import { CurrentDeviceConfigType, TemplateCfgType } from "../utils/types";

type InputChooseTemplateProps = {
  handleButtonsChange: (value: string) => void;
  handleDeviceIdChange: (value: ListConfigItem) => void;
  handleDeviceNameChange: (value: string) => void;
  currentDeviceConfig: CurrentDeviceConfigType;
};

const InputChooseTemplate: React.FC<InputChooseTemplateProps> = ({
  handleButtonsChange,
  handleDeviceIdChange,
  handleDeviceNameChange,
  currentDeviceConfig,
}) => {
  const { getValues } = useFormContext();

  const updateConfigMutation = useUpdateConfigMutation();
  const createConfigMutation = useCreateConfigMutation();
  const deleteConfigMutation = useDeleteConfigMutation();

  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 600);

  const configListQuery = useGenericQuery<ListConfigItem>({
    qKey: "config",
    debouncedSearch,
    fn: listConfigs,
    queryParamsKey: ["name__icontains"],
  });

  const innerHandleButtonsChange = (value: string) => {
    configListQuery.refetch();
    handleButtonsChange(value);
  };

  const data = React.useMemo(
    () => (configListQuery.data?.pages ?? [])?.map((it) => it.data).flat(),
    [configListQuery.data]
  );

  const configData = data.map((v) => ({ id: v.id, label: v.name }));

  const customAutocompleteBox = (
    <Autocomplete
      freeSolo
      sx={{ flex: "2" }}
      forcePopupIcon
      onChange={(e, value) => {
        if (!value || typeof value === "string") return;
        const oneTemplateConfig = data.find((v) => v.id === value.id);
        if (!oneTemplateConfig) return;
        handleDeviceIdChange(oneTemplateConfig);
      }}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onInputChange={(_event, value: string, _reason) => {
        const searchedValueFoundInLabel = configData.some(
          (v) => v.label.includes(value) && value.length > 0
        );
        if (!searchedValueFoundInLabel) setSearch(value);
        handleDeviceNameChange(value);
      }}
      ListboxProps={{
        style: { maxHeight: 200 },
        onScroll: (event: React.SyntheticEvent) => {
          if (!configListQuery.hasNextPage) return;
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight) {
            configListQuery.fetchNextPage();
          }
        },
      }}
      options={configData}
      renderInput={(params) => (
        <TextField {...params} size="small" label="Choose template" variant="outlined" />
      )}
    />
  );

  const saveButton = (
    <ButtonWithRequest
      mutation={updateConfigMutation}
      buttonName="Save"
      Icon={<SaveIcon />}
      disabled={currentDeviceConfig.id === ""}
      mutationCallback={() => {
        const payload = makeConfigPayload(getValues() as TemplateCfgType, currentDeviceConfig.name);
        updateConfigMutation.mutate({ id: currentDeviceConfig.id, configParams: payload });
      }}
      afterHandleButton={() => {
        innerHandleButtonsChange("save");
      }}
    />
  );

  const deviceNameNotChangedYet =
    data.find((v) => v.name === currentDeviceConfig.name) !== undefined;

  const saveAsButton = (
    <ButtonWithRequest
      mutation={createConfigMutation}
      disabled={deviceNameNotChangedYet || currentDeviceConfig.name === ""}
      buttonName="Save As new"
      Icon={<SaveAsIcon />}
      mutationCallback={async () => {
        const payload = makeConfigPayload(getValues() as TemplateCfgType, currentDeviceConfig.name);
        handleDeviceIdChange(await createConfigMutation.mutateAsync(payload));
      }}
      afterHandleButton={() => {
        innerHandleButtonsChange("saveas");
      }}
    />
  );

  const deleteButton = (
    <ButtonWithRequest
      mutation={deleteConfigMutation}
      buttonName="Delete"
      Icon={<DeleteIcon />}
      disabled={currentDeviceConfig.id === ""}
      mutationCallback={() => {
        deleteConfigMutation.mutate(currentDeviceConfig.id);
      }}
      afterHandleButton={() => {
        innerHandleButtonsChange("delete");
      }}
    />
  );

  const [configError, setConfigError] = React.useState<AxiosResponse["data"] | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (updateConfigMutation.isError) {
      setConfigError(updateConfigMutation.error?.response?.data);
    }
    if (createConfigMutation.isError) {
      setConfigError(createConfigMutation.error?.response?.data);
    }
    if (deleteConfigMutation.isError) {
      setConfigError(deleteConfigMutation.error?.response?.data);
    }
  }, [updateConfigMutation, createConfigMutation, deleteConfigMutation, setConfigError]);

  const { innerWidth: width } = window;

  return (
    <>
      <Box>
        <Snackbar
          autoHideDuration={10000}
          open={configError !== undefined}
          onClose={() => {
            setConfigError(undefined);
          }}
        >
          <Alert
            sx={{ maxWidth: `${width * 0.75}px` }}
            onClose={() => {
              setConfigError(undefined);
            }}
            severity="error"
          >
            {configError && (
              <Box>
                <pre>{JSON.stringify(configError, null, 1)}</pre>
              </Box>
            )}
          </Alert>
        </Snackbar>
      </Box>
      <FormControl fullWidth sx={{ marginTop: 1 }}>
        <CardContent sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          {customAutocompleteBox}
          {saveButton}
          {saveAsButton}
          {deleteButton}
        </CardContent>
      </FormControl>
    </>
  );
};

export default InputChooseTemplate;
