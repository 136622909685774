import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  ConfigItems,
  ListConfigItem,
  createConfigs,
  deleteConfigs,
  updateConfigs,
} from "queries/configs";

type QueryConfigType = {
  id: string;
  configParams: ConfigItems;
};

const useUpdateConfigMutation = () => {
  const updateConfigMutation = useMutation<ListConfigItem, AxiosError, QueryConfigType, unknown>({
    mutationFn: ({ id, configParams }: QueryConfigType) => {
      return updateConfigs(id, configParams);
    },
  });
  return updateConfigMutation;
};

const useCreateConfigMutation = () => {
  const createConfigMutation = useMutation<ListConfigItem, AxiosError, ConfigItems, unknown>({
    mutationFn: (configParams: ConfigItems) => {
      return createConfigs(configParams);
    },
  });
  return createConfigMutation;
};

const useDeleteConfigMutation = () => {
  const deleteConfigMutation = useMutation<ListConfigItem, AxiosError, string, unknown>({
    mutationFn: (id: string) => {
      return deleteConfigs(id);
    },
  });
  return deleteConfigMutation;
};

export { useUpdateConfigMutation, useCreateConfigMutation, useDeleteConfigMutation };
