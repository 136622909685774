import React from "react";
import CardContent from "@mui/material/CardContent";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { SelectProps } from "@mui/material/Select";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import { Controller, useFormContext } from "react-hook-form";
import { DynamicConfigFieldsType } from "../utils/types";

type DynamicConfigFieldsProps = {
  dynamicLabel: string;
  controllerParentName: string;
  globalProps?: SelectProps<unknown> & TextFieldProps;
};

const DynamicConfigFields: React.FC<DynamicConfigFieldsProps> = ({
  controllerParentName,
  dynamicLabel,
  globalProps,
}) => {
  const { control, setValue, getValues } = useFormContext();

  const clearStaticFieldText = (name: string, value: string, staticName: string) => {
    setValue(name, value);
    setValue(staticName, "");
  };

  const offset = getValues(`${controllerParentName}.offset`);
  const stat = getValues(`${controllerParentName}.stat`);

  return (
    <CardContent sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
      <FormLabel sx={{ display: "flex", alignItems: "center" }}>{dynamicLabel}</FormLabel>

      <Controller
        name={`${controllerParentName}.sssr`}
        control={control}
        render={({ field: { value, ...rest } }) => (
          <FormControl style={{ minWidth: 80 }}>
            <TextField
              {...rest}
              {...globalProps}
              select
              name="sssr"
              value={value || ""}
              label="SS/SR"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              size="small"
              InputProps={{
                onChange: (e) => {
                  clearStaticFieldText(
                    `${controllerParentName}.sssr`,
                    e.target.value,
                    `${controllerParentName}.stat`
                  );
                },
              }}
            >
              <MenuItem value="ss">SS</MenuItem>
              <MenuItem value="sr">SR</MenuItem>
            </TextField>
          </FormControl>
        )}
      />

      <Controller
        name={`${controllerParentName}.plusMinus`}
        control={control}
        render={({ field: { value, ...rest } }) => (
          <FormControl style={{ minWidth: 80 }}>
            <TextField
              {...rest}
              {...globalProps}
              select
              name="plusMinus"
              value={value || ""}
              label=" +/-"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              size="small"
              InputProps={{
                onChange: (e) => {
                  clearStaticFieldText(
                    `${controllerParentName}.plusMinus`,
                    e.target.value,
                    `${controllerParentName}.stat`
                  );
                },
              }}
            >
              <MenuItem value="+">+</MenuItem>
              <MenuItem value="-">-</MenuItem>
            </TextField>
          </FormControl>
        )}
      />

      <Controller
        name={`${controllerParentName}.offset`}
        control={control}
        render={({ field: { value, ...rest } }) => (
          <TextField
            {...rest}
            value={value || ""}
            sx={{ minWidth: "80px" }}
            {...globalProps}
            size="small"
            label="Offset"
            type="number"
            InputLabelProps={{ shrink: offset !== null }}
            InputProps={{
              inputProps: { min: 0, max: 100 },
              onChange: (e) => {
                clearStaticFieldText(
                  `${controllerParentName}.offset`,
                  e.target.value,
                  `${controllerParentName}.stat`
                );
              },
            }}
          />
        )}
      />
      <p> / </p>
      <Controller
        name={`${controllerParentName}.stat`}
        control={control}
        render={({ field: { value, onChange, ...rest } }) => (
          <TextField
            {...rest}
            sx={{ minWidth: "80px" }}
            value={value || 0}
            {...globalProps}
            size="small"
            label="Static"
            type="number"
            onChange={(event) => {
              onChange(parseInt(event.target.value, 10));
            }}
            InputProps={{
              inputProps: { min: 0, max: 1439 },
              onChange: (e) => {
                setValue(`${controllerParentName}.sssr`, "");
                setValue(`${controllerParentName}.plusMinus`, "");
                setValue(`${controllerParentName}.offset`, "");
                setValue(`${controllerParentName}.stat`, parseInt(e.target.value, 10));
              },
            }}
            InputLabelProps={{ shrink: stat !== null }}
          />
        )}
      />
    </CardContent>
  );
};

export default DynamicConfigFields;
