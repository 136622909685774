import React from "react";

interface ValidationPanelContextProps {
  handleMutationSettled: () => void;
}

const INITIAL_VALUES: ValidationPanelContextProps = {
  handleMutationSettled: () => {},
};

const ValidationPanelContext = React.createContext<ValidationPanelContextProps>(INITIAL_VALUES);

type ValidationPanelProviderType = {
  mutationSettledCallback: () => void;
} & React.PropsWithChildren;

const ValidationPanelProvider: React.FC<ValidationPanelProviderType> = ({
  children,
  mutationSettledCallback,
}) => {
  const value = React.useMemo(
    () => ({
      handleMutationSettled: () => {
        mutationSettledCallback();
      },
    }),
    [mutationSettledCallback]
  );

  return (
    <ValidationPanelContext.Provider value={value}> {children} </ValidationPanelContext.Provider>
  );
};

export default ValidationPanelProvider;

export const useValidationPanel = () => React.useContext(ValidationPanelContext);
