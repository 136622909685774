import React from "react";
import { Box, Card } from "@mui/material";

const PestManagement = () => {
  return (
    <Box>
      <Card sx={{ width: "100%" }}>Users</Card>
    </Box>
  );
};

export default PestManagement;
