import debounce from "lodash/debounce";
import { ListConfigItem } from "queries/configs";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Box from "@mui/material/Box/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import InputCamera from "./Contents/inputs/Camera";
import InputChooseTemplate from "./Contents/inputs/ChooseTemplate";
import LightConfigList from "./Contents/inputs/LightConfigList";
import InputTimes from "./Contents/inputs/Times";
import InputWakeupTimes from "./Contents/inputs/WakeupTimes";
import {
  parseCameraFromBackend,
  parseLigthFromBackend,
  parseTimesFromBackend,
  parseWakeUpFromBackend,
} from "./Contents/utils/common.fn";
import { defaultTemplateCfg } from "./Contents/utils/defaults";
import {
  CameraConfigFieldsType,
  CurrentDeviceConfigType,
  StrOrNum,
  TemplateCfgType,
} from "./Contents/utils/types";

const TemplateFormControll: React.FC<{
  children: ReactNode;
  dataState: TemplateCfgType;
}> = ({ children, dataState: defValues }) => {
  const methods = useForm<TemplateCfgType>({
    defaultValues: defValues,
  });

  useEffect(() => {
    if (methods.reset) {
      methods.reset({ ...defValues });
    }
  }, [methods, defValues]);

  return (
    <FormProvider {...methods}>
      <form className="form">{children}</form>
    </FormProvider>
  );
};

const Template: React.FC<{
  // debugPanel: [string, React.Dispatch<React.SetStateAction<string[]>>];
  setPopup?: React.Dispatch<React.SetStateAction<string[]>>;
  open: boolean;
  currentDeviceConfigState: [
    CurrentDeviceConfigType,
    React.Dispatch<React.SetStateAction<CurrentDeviceConfigType>>
  ];
}> = ({
  // debugPanel: [popup, setPopup]
  setPopup,
  open,
  currentDeviceConfigState: [currentDeviceConfig, setCurrentDeviceConfig],
}) => {
  const [data, setData] = useState<TemplateCfgType>(defaultTemplateCfg);
  const [config, setConfig] = React.useState<ListConfigItem>({} as ListConfigItem);
  const { comment } = data;

  const SetWakeupTimes = (config: ListConfigItem) => {
    if (!config.wakeup_times) return;

    const parsed = parseWakeUpFromBackend(
      config.wakeup_times as number[] | { first: string; last: string; times: number }
    );

    setData((val) => ({
      ...val,
      wakeup: {
        type: Array.isArray(config.wakeup_times) ? "static" : "dynamic",
        fields: {
          ...val.wakeup.fields,
          ...parsed,
        },
      },
    }));
  };

  const SetComment = (config: ListConfigItem) => {
    if (!config.comment) return;
    setData((val) => ({
      ...val,
      comment: config.comment || " - ",
    }));
  };

  const SetLigthConfig = (config: ListConfigItem) => {
    if (!config.light_config) return;

    let lightEnabled = true;

    if (Array.isArray(config.light_config)) {
      if (config.light_config.includes("none")) {
        lightEnabled = false;
      }
    }

    // debugger; // eslint-disable-line no-debugger
    const parsed = parseLigthFromBackend(
      lightEnabled,
      config.light_config as [
        {
          light_starts: string;
          light_duration: StrOrNum;
          light_intensity: StrOrNum;
        }
      ]
    );

    setData((val) => ({
      ...val,
      light: {
        enabled: lightEnabled,
        fields: [...val.light.fields, ...parsed],
      },
    }));
  };

  const SetTimeConfig = (config: ListConfigItem) => {
    // interface DataObject {
    //   [key: string]: string[];
    // }

    const parsed = parseTimesFromBackend({
      env_times: config.env_times || [],
      loc_times: config.loc_times || [],
      image_times: config.image_times || [],
      network_times: config.network_times || [],
      diag_times: config.diag_times || [],
      upload_times: config.upload_times || [],
    });
    // } as DataObject);

    setData((val) => ({
      ...val,
      time: {
        ...val.time,
        ...parsed,
      },
    }));
  };

  const SetCameraConfig = (config: ListConfigItem) => {
    if (!config.camera_resolution) return;

    const parsed = parseCameraFromBackend({
      resolution: config.camera_resolution,
      autofocus: config.camera_autofocus,
      flashlight: config.camera_flashlight,
    } as CameraConfigFieldsType);

    setData((val) => ({
      ...val,
      camera: {
        ...val.camera,
        ...parsed,
      },
    }));
  };

  useEffect(() => {
    if (config && config.id && config.name) {
      setData(defaultTemplateCfg);
      setCurrentDeviceConfig((v) => ({ id: config.id, name: config.name || v.name }));
      SetComment(config);
      SetWakeupTimes(config);
      SetLigthConfig(config);
      SetTimeConfig(config);
      SetCameraConfig(config);
    }
  }, [config, config.id, config.name, setData, setCurrentDeviceConfig]);

  const debouncedSetCurrentDeviceConfig = useMemo(
    () =>
      debounce((value: string) => {
        setCurrentDeviceConfig((v) => ({ ...v, name: value }));
      }, 450),
    [setCurrentDeviceConfig]
  );

  const selectDeviceConfig = (
    <InputChooseTemplate
      currentDeviceConfig={currentDeviceConfig}
      handleDeviceIdChange={(oneConfigItem: ListConfigItem) => {
        setConfig(oneConfigItem);
      }}
      handleDeviceNameChange={debouncedSetCurrentDeviceConfig}
      handleButtonsChange={(value: string) => {
        if (value === "saveas" || value === "delete")
          setCurrentDeviceConfig({} as CurrentDeviceConfigType);
      }}
    />
  );

  return (
    <Box
      className="TemplateElement"
      sx={{
        margin: "1px",
        padding: "1px",
        maxWidth: "50%",
        display: `${open ? "inline" : "none"}`,
      }}
    >
      <TemplateFormControll dataState={data}>
        <Card sx={{ height: "100%" }}>
          {selectDeviceConfig}

          <Divider sx={{ my: 0 }} />

          <Controller
            name="comment"
            render={({ field: { value, ...rest } }) => (
              <TextField
                {...rest}
                value={value || ""}
                label="Comment"
                variant="filled"
                fullWidth
                InputLabelProps={{ shrink: !(comment in [null, ""]) }}
              />
            )}
          />

          <Divider sx={{ my: 0 }} />

          <InputWakeupTimes />

          <Divider sx={{ my: 0 }} />

          <LightConfigList />

          <Divider sx={{ my: 0 }} />

          <InputTimes />

          <Divider sx={{ my: 0 }} />

          <InputCamera />
        </Card>
      </TemplateFormControll>
    </Box>
  );
};

export default Template;
