import React from "react";
import { DialogContent, Box, TextField } from "@mui/material";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "queries/users";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import UpdateUserDialogActions, { UpdateUserDialogActionsProps } from "./UpdateUserDialogActions";

type UpdateUserFormValues = {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
};

const schema = Yup.object({
  username: Yup.string().required(),
  email: Yup.string(),
  first_name: Yup.string(),
  last_name: Yup.string(),
});

type UpdateUserDialogFormProps = {
  initialValues: UpdateUserFormValues;
  id: string;
  handleSuccess: () => void;
  actionsProps: UpdateUserDialogActionsProps;
};
const UpdateUserDialogForm: React.FC<UpdateUserDialogFormProps> = ({
  id,
  initialValues,
  handleSuccess,
  actionsProps,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateUserFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();

  const onSuccess = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["list-users"] });
    queryClient.invalidateQueries({ queryKey: ["detail-user", { id }] });
    handleSuccess();
  }, [queryClient, id, handleSuccess]);

  const mutation = useMutation({
    mutationFn: updateUser,
    onSuccess,
  });

  const onSubmit = React.useCallback(
    (params: UpdateUserFormValues) => {
      if (!id) throw new Error("No user id when submitting");

      mutation.mutate({ id, params });
    },
    [id, mutation]
  );

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <TextField
          label="Username"
          error={!!errors.username}
          helperText={errors.username?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          required
          {...register("username")}
        />
        <TextField
          label="Email"
          error={!!errors.email}
          helperText={errors.email?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          {...register("email")}
        />
        <TextField
          label="First name"
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          {...register("first_name")}
        />
        <TextField
          label="Last name"
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          {...register("last_name")}
        />
      </DialogContent>
      <UpdateUserDialogActions isSaveLoading={mutation.isLoading} {...actionsProps} />
    </Box>
  );
};

export default UpdateUserDialogForm;
