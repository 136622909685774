import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { DeviceGeneralStatus } from "utils/device-statuses";

type StatusIndicatorProps = {
  status: DeviceGeneralStatus | undefined;
};

// TODO: when we have actual theme, we should use theme colors
const colorPerStatus: Record<DeviceGeneralStatus, string> = {
  maintenance_needed: "orange",
  not_working: "orange",
  working: "green",
  not_validated: "gray",
  turned_off: "gray",
  not_installed: "gray",
  shipped_back: "gray",
};

const StatusIndicator = styled(Box)`
  width: 5px;
  border-radius: 5px;
  background: ${({ status }: StatusIndicatorProps) => (status ? colorPerStatus[status] : "gray")};
`;

export default StatusIndicator;
