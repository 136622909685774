/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { ListResponse } from "./types";

// eslint-disable-next-line @typescript-eslint/naming-convention
export type SkipDeviceDetail = {
  id: string;
  mac: string;
  imei: string;
};

export type OneSkipDeviceItem = {
  mac: string;
  imei: string;
};

export type ListSkipDeviceParams = {
  page: number;
  page_size: number;
  search?: string;
  ordering?: string;
  hr?: string;
};

export type UpdateSkipDeviceItem = {
  mac?: string;
  imei?: string;
};

export const listSkipDevice = async (
  params?: ListSkipDeviceParams
): Promise<ListResponse<SkipDeviceDetail>> => {
  const { data } = await axios.get<ListResponse<SkipDeviceDetail>>(`/api/iot/skip-devices-upload`, {
    params,
  });
  return data;
};

export const detailSkipDevice = async (id: string): Promise<OneSkipDeviceItem> => {
  const { data } = await axios.get<OneSkipDeviceItem>(`/api/iot/skip-devices-upload/${id}`);
  return data;
};

export const addSkipDevice = async (
  params: UpdateSkipDeviceItem
): Promise<ListResponse<SkipDeviceDetail>> => {
  const { data } = await axios.post<ListResponse<SkipDeviceDetail>>(
    `/api/iot/skip-devices-upload`,
    { ...params }
  );
  return data;
};

export const deleteSkipDevice = async (id: string): Promise<ListResponse<SkipDeviceDetail>> => {
  const { data } = await axios.delete<ListResponse<SkipDeviceDetail>>(
    `/api/iot/skip-devices-upload/${id}`
  );
  return data;
};
