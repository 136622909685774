import React, { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import { green } from "@mui/material/colors";
import { CAM_RES, TimesFieldsType } from "queries/configs";
import { isEmpty } from "lodash";
import { smappLogger } from "utils/logger";
import {
  CameraConfigFieldsType,
  DynamicConfigFieldsType,
  LightConfigFieldsType,
  TemplateCfgType,
  StrOrNum,
  TimeConfigFieldsType,
  WakeupConfigFieldsType,
} from "./types";

// ///////////////////////////////
// Parse config from response / json
// //////////////////////////////

const parseDynamicFromBackend = (pDynamicStartValue: string) => {
  if (typeof pDynamicStartValue !== "string") {
    smappLogger.warn(`
      parseDynamicConfigValue got wrong type of value (not string )
      parsing cannot be done ...
      ${pDynamicStartValue}
    `);
    return { sssr: "", plusMinus: "", offset: 0, stat: pDynamicStartValue };
  }

  smappLogger.log("parse -> Dynamic Config ->", pDynamicStartValue);
  const fields: { [key: string]: boolean } = {};
  let dynamicStartValue = pDynamicStartValue;

  ["ss", "sr", "+", "-"].forEach((field) => {
    fields[field] = dynamicStartValue.includes(field);
  });

  const listOfFields: string[] = [];
  Object.entries(fields)
    .filter(([, v]) => v === true)
    .forEach(([k]) => {
      listOfFields.push(k);
      dynamicStartValue = dynamicStartValue.replace(k, "");
    });

  const offset = Number(dynamicStartValue) ? Number(dynamicStartValue) : 0;
  let sssr = "";
  let plusMinus = "";

  listOfFields.forEach((v) => {
    if (v === "ss" || v === "sr") sssr = v;
    if (v === "+" || v === "-") plusMinus = v;
  });

  if (sssr === "" && plusMinus === "") {
    return { stat: offset };
  }
  return { sssr, plusMinus, offset };
};

const parseWakeUpFromBackend = (
  value: number[] | { first: string; last: string; times: number }
) => {
  let result: Partial<WakeupConfigFieldsType> = {};
  if (isEmpty(value)) return result;

  if (Array.isArray(value)) {
    result = {
      staticMultiText: value.join(", "),
    };
  } else {
    const first = parseDynamicFromBackend(value.first);
    const last = parseDynamicFromBackend(value.last);

    let valueTimes = value.times;
    if (typeof valueTimes === "string") {
      valueTimes = parseInt(valueTimes as string, 10);
    }

    result = {
      firstDynamicConfig: { ...first },
      lastDynamicConfig: { ...last },
      dynamicTimes: valueTimes,
    };
  }
  smappLogger.log("parse -> WakeUpTimes ->", result);
  return result;
};

const parseLigthFromBackend = (
  lightEnabled: boolean,
  value: [{ light_starts: string; light_duration: StrOrNum; light_intensity: StrOrNum }]
) => {
  let result: LightConfigFieldsType[] = [];
  if (isEmpty(value) || !lightEnabled) return result;

  // Because of config changes lightconfig can be a json or list of json
  let dummyValue = value;
  if (!Array.isArray(value)) {
    dummyValue = [value];
  }

  result = dummyValue.map((v) => ({
    dynamicConfig: {
      ...parseDynamicFromBackend(v.light_starts),
    },
    duration: v.light_duration,
    intensity: v.light_intensity,
  }));
  smappLogger.log("parse -> LigthConfig ->", result);
  return result;
};

// const parseTimesFromBackend = (value: Record<string, string[]>) => {
const parseTimesFromBackend = (value: TimeConfigFieldsType | TimesFieldsType) => {
  let result: Partial<TimeConfigFieldsType> = {};
  if (isEmpty(value)) return result;

  const dynamicTimeConfigListToString = Object.entries(value).reduce((pre, [key, value]) => {
    // Array.isArray(value)
    if (Array.isArray(value)) {
      pre[key] = value.join(", ");
    }
    return pre;
  }, {} as any);

  result = {
    ...dynamicTimeConfigListToString,
  };
  smappLogger.log("parse -> Times ->", value, result);
  return result;
};

const parseCameraFromBackend = (value: CameraConfigFieldsType) => {
  let result: Partial<CameraConfigFieldsType> = {};
  if (isEmpty(value)) return result;
  result = {
    autofocus: value.autofocus,
    resolution: value.resolution.toLowerCase() as `${CAM_RES}`,
    flashlight: value.flashlight,
  };
  smappLogger.log("parse -> CameraConfig ->", value, result);
  return result;
};

// ///////////////////////////////
// Data to payload
// ///////////////////////////////

const parseDynamic = (dyna: Partial<DynamicConfigFieldsType>) => {
  if (!dyna.sssr || !dyna.plusMinus) {
    return dyna.stat;
  }
  return `${dyna.sssr}${dyna.plusMinus}${dyna.offset}`;
};

const parseWakeupTimes = (config: TemplateCfgType) => {
  let wakeupTimes;
  if (config.wakeup.type === "static") {
    wakeupTimes = [];
    if (!isEmpty(config.wakeup.fields.staticMultiText)) {
      wakeupTimes = config.wakeup.fields.staticMultiText.split(",").map((v) => +v.trim());
    }
  } else {
    wakeupTimes = {
      first: parseDynamic(config.wakeup.fields.firstDynamicConfig),
      last: parseDynamic(config.wakeup.fields.lastDynamicConfig),
      times: config.wakeup.fields.dynamicTimes,
    };
  }
  smappLogger.log("parseWakeupTimes ->", wakeupTimes);
  return wakeupTimes;
};

const parseLightConfig = (config: TemplateCfgType) => {
  let lightConfig;
  if (config.light.enabled) {
    lightConfig = config.light.fields.map((v) => ({
      light_starts: parseDynamic(v.dynamicConfig),
      light_duration: v.duration,
      light_intensity: v.intensity,
    }));
  } else {
    lightConfig = ["none"];
  }
  smappLogger.log("parse -> LightConfig ->", config.light);
  return lightConfig;
};

const makeConfigPayload = (config: TemplateCfgType, configDeviceName: string) => {
  const res: TimesFieldsType = {} as TimesFieldsType;
  Object.entries(config.time).forEach(([k, v]) => {
    const splittedValue = (v as string).split(/[\s,]+/);
    res[k as keyof TimesFieldsType] = isEmpty(v)
      ? []
      : splittedValue.map((s) => parseInt(s.trim(), 10) || s.trim());
    return res;
  });

  return {
    name: configDeviceName,
    active: true,
    comment: config.comment,
    version: 1,
    light_config: parseLightConfig(config),
    wakeup_times: parseWakeupTimes(config),
    ...res,
    camera_resolution: config.camera.resolution as CAM_RES,
    camera_autofocus: config.camera.autofocus,
    camera_flashlight: config.camera.flashlight,
  };
};

// ///////////////////////////////
// Custom React Components
// ///////////////////////////////

type ToolTippedButtonType = {
  onClick: () => void;
  tooltip: string;
  disabled?: boolean;
  Icon: JSX.Element;
};

const ToolTippedButton: React.FC<ToolTippedButtonType> = ({ disabled, onClick, tooltip, Icon }) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        disabled={disabled === true}
        sx={{ m: 0, p: 0, minWidth: 0 }}
        variant="contained"
        size="small"
        onClick={() => {
          onClick();
        }}
      >
        {Icon}
      </Button>
    </Tooltip>
  );
};

const ButtonWithRequest: React.FC<{
  buttonName?: string;
  Icon?: ReactElement;
  children?: ReactNode;
  disabled?: boolean;
  mutationCallback: () => void;
  afterHandleButton?: () => void;
  mutation: UseMutationResult<any, unknown, any, unknown>;
}> = ({ buttonName, Icon, children, disabled, mutationCallback, afterHandleButton, mutation }) => {
  const [loading, setLoading] = React.useState(false);

  const buttonSx = useMemo(() => {
    return {
      ...(mutation.isSuccess && {
        bgcolor: green[500],
        "&:hover": {
          bgcolor: green[700],
        },
      }),
    };
  }, [mutation]);

  const handleButton = useCallback(async () => {
    if (!mutation.isLoading) {
      setLoading(true);
      window.setTimeout(async () => {
        mutationCallback();
        setLoading(false);
        window.setTimeout(() => {
          mutation.reset();
        }, 4000);
        if (afterHandleButton) afterHandleButton();
      }, 1000);
    }
  }, [mutation, mutationCallback, afterHandleButton]);

  const Bttn = useCallback(() => {
    return (
      <Tooltip title={buttonName}>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            title={buttonName || ""}
            variant="contained"
            onClick={handleButton}
            disabled={loading || disabled || false}
            sx={buttonSx}
          >
            {mutation.isSuccess ? <CheckIcon /> : Icon || children}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Tooltip>
    );
  }, [Icon, buttonName, buttonSx, children, handleButton, disabled, loading, mutation]);
  return <Bttn />;
};

export {
  ToolTippedButton,
  ButtonWithRequest,
  parseCameraFromBackend,
  parseLigthFromBackend,
  parseTimesFromBackend,
  parseWakeUpFromBackend,
  parseDynamicFromBackend,
  makeConfigPayload,
};
