import axios from "axios";

export type ArchiveParams = {
  devices_to_archive: string[];
  keep_old_corporation?: boolean;
  keep_old_user?: boolean;
};

export const archiveDevices = async (params: ArchiveParams): Promise<void> => {
  const { data } = await axios.patch<void>(`/api/devices/archive`, params);
  return data;
};
