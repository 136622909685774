import React from "react";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
import { useUserPreferences } from "./UserPreferencesProvider";

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { directThemeMode } = useUserPreferences();

  const theme = createTheme({
    palette: {
      primary: {
        light: "#f4b519",
        main: "#f4b519",
        dark: "#f4b519",
        contrastText: "#000",
      },
      mode: directThemeMode,
    },
  });
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
