import axios from "axios";

const exportDevice = async (search: string): Promise<Blob> => {
  const { data } = await axios.get(`/api/devices/export`, {
    params: { search },
    responseType: "blob",
  });
  return data;
};

const exportUsers = async (search: string): Promise<Blob> => {
  const { data } = await axios.get(`/api/users/export`, {
    params: { search },
    responseType: "blob",
  });
  return data;
};

export default exportDevice;
