import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ListDeviceItem, updateDeviceStatus } from "queries/devices";
import React from "react";
import {
  DEVICE_DETAILED_STATUSES,
  DeviceDetailedStatus,
  statusToReadable,
} from "utils/device-statuses";

interface Props {
  device: ListDeviceItem;
}

const ValidatorTrapStatusForm = ({ device }: Props) => {
  const currentStatus = device.last_status?.detailed_status ?? "";
  const [newStatus, setNewStatus] = React.useState<DeviceDetailedStatus | "">(currentStatus);

  const queryClient = useQueryClient();
  const changeStatusMutation = useMutation({
    mutationFn: async () => {
      if (!newStatus) return;
      await updateDeviceStatus({ id: device.id, status: newStatus });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["detail-device", { id: device.id }] });
      queryClient.invalidateQueries({ queryKey: ["inf-list-devices"], type: "all" });
    },
  });

  // user can update status only if selected status is not current status (and has selected status)
  const isSaveDisabled = newStatus === currentStatus || changeStatusMutation.isLoading;

  return (
    <Grid container gap={2} sx={{ pb: 3 }}>
      <Grid item>
        <FormControl fullWidth size="small">
          <InputLabel id="newStatus">Status</InputLabel>
          <Select
            labelId="newStatus"
            value={newStatus}
            label="New Status"
            onChange={(e) => setNewStatus(e.target.value as DeviceDetailedStatus)}
            sx={{ minWidth: 200 }}
          >
            {DEVICE_DETAILED_STATUSES.map((value) => (
              <MenuItem key={value} value={value}>
                {statusToReadable(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={() => changeStatusMutation.mutate()}
          disabled={isSaveDisabled}
        >
          Update Trap Status
        </Button>
      </Grid>
    </Grid>
  );
};

export default ValidatorTrapStatusForm;
