import { useValidation } from "context/ValidationProvider";
import React from "react";
import { Layer, Rect, Stage } from "react-konva";
import { Image as ImageRef } from "konva/lib/shapes/Image";
import URLImage from "../URLImage";
import Toolbar from "./Toolbar";

export type ImageEditorProps = {
  data: Blob;
  width: number;
  // eslint-disable-next-line react/no-unused-prop-types
  isEditing: true;
};

const ImageEditor: React.FC<ImageEditorProps> = ({ isEditing, data, width }) => {
  const imageRef = React.useRef<ImageRef>(null);

  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    annotationsToDraw,
    stageRef,
    setCoordinateList,
  } = useValidation();

  React.useEffect(() => {
    const coordinates = annotationsToDraw.map((val) => ({
      x: val.x,
      y: val.y,
      w: val.width,
      h: val.height,
    }));
    setCoordinateList(coordinates);
  }, [annotationsToDraw, setCoordinateList]);

  return (
    <>
      <Stage
        ref={stageRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        width={width}
        height={300}
      >
        <Layer>
          <URLImage obj={data} ref={imageRef} />
          {isEditing &&
            annotationsToDraw.map((value) => {
              return (
                <Rect
                  key={value.id}
                  x={value.x}
                  y={value.y}
                  width={value.width}
                  height={value.height}
                  fill="transparent"
                  stroke={value.stroke}
                  strokeWidth={
                    imageRef.current?.width() ? imageRef.current.width() * 0.004 : undefined
                  }
                />
              );
            })}
        </Layer>
      </Stage>
      {isEditing && <Toolbar />}
    </>
  );
};

export default ImageEditor;
