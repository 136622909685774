import React from "react";

type TabPanelProps = {
  index: number;
  value: number;
  id: string;
  "aria-labelledby": string;
};

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  children,
  value,
  index,
  ...props
}) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...props}>
      {value === index && children}
    </div>
  );
};

export default TabPanel;
