import React from "react";
import { Chip, Tooltip } from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { isNotNil } from "utils/lodash";
// TODO colors

type AreaChipProps = {
  country: string | null;
  city: string | null;
};
const AreaChip: React.FC<AreaChipProps> = ({ country, city }) => {
  const label = [country, city].filter(isNotNil).join(" - ") || "Unknown";
  return (
    <Tooltip title="Area" arrow>
      <Chip icon={<MapOutlinedIcon />} label={label} variant="outlined" />
    </Tooltip>
  );
};

export default AreaChip;
