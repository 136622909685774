import React from "react";
import { Box, ListSubheader, Paper } from "@mui/material";
import useDateRange from "hooks/useDateRange";
import { AVG_MEASUREMENT_FREQUENCY } from "queries/devices/averageMeasurements";
import MeasurementChart, {
  MeasurementChartProps,
  TEMPERATURE_CHART_CONFIG,
  HUMIDITY_CHART_CONFIG,
} from "./MeasurementChart";

type MeasurementChartPanelProps = Pick<MeasurementChartProps, "deviceIdIn">;
const MeasurementChartPanel: React.FC<MeasurementChartPanelProps> = ({ deviceIdIn }) => {
  const { dateRange, form: dateRangeForm } = useDateRange({});

  const { humidityLabel, temperatureLabel, title } =
    deviceIdIn?.length === 1
      ? { temperatureLabel: "Temperature", humidityLabel: "Humidity", title: "Measurement" }
      : {
          temperatureLabel: "AVG temperature",
          humidityLabel: "AVG humidity",
          title: "Average measurements",
        };

  return (
    <Paper
      elevation={3}
      sx={{
        pt: 3,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 1, display: "flex" }}>
        <ListSubheader sx={{ flex: 1 }}>{title}</ListSubheader>
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <MeasurementChart
          deviceIdIn={deviceIdIn}
          from={dateRange.from}
          to={dateRange.to}
          frequency={AVG_MEASUREMENT_FREQUENCY.D1}
          chartConfig={[
            { ...HUMIDITY_CHART_CONFIG, name: humidityLabel },
            { ...TEMPERATURE_CHART_CONFIG, name: temperatureLabel },
          ]}
        />
      </Box>
    </Paper>
  );
};

export default MeasurementChartPanel;
