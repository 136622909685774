import React from "react";
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { StrokeColor } from "hooks/useAnnotations";
import { useValidation } from "context/ValidationProvider";
import { LoadingButton } from "@mui/lab";

const Toolbar: React.FC = () => {
  const { annotations, stroke, setStroke, onClose, onClear, onSave, isSaving } = useValidation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStroke(event.target.value as StrokeColor);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ flex: 1 }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={stroke}
            onChange={handleChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="red"
              control={<Radio sx={stroke === "red" ? { color: "red !important" } : undefined} />}
              label="Red"
            />

            <FormControlLabel
              value="green"
              control={
                <Radio sx={stroke === "green" ? { color: "green !important" } : undefined} />
              }
              label="Green"
            />
            <FormControlLabel
              value="blue"
              control={<Radio sx={stroke === "blue" ? { color: "blue !important" } : undefined} />}
              label="Blue"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <LoadingButton
          loading={isSaving}
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={onSave}
        >
          SAVE
        </LoadingButton>
        <Button
          variant="outlined"
          size="small"
          sx={{ m: 1 }}
          onClick={onClear}
          disabled={annotations.length === 0}
        >
          CLEAR
        </Button>
        <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={onClose}>
          CLOSE
        </Button>
      </Box>
    </Box>
  );
};

export default Toolbar;
