import React from "react";
import { Routes, Route } from "react-router-dom";

import { useAuth } from "context/AuthProvider";
import ProtectedRoute from "components/ProtectedRoute";
import AppWrapper from "components/AppWrapper";
import DeviceConfigMain from "./main/DeviceConfigMain";

const DevicesConfig: React.FC = () => {
  const { isAdmin } = useAuth();

  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <AppWrapper mainSxProps={{ position: "relative", width: "98%", maxWidth: "98%" }}>
              {isAdmin && <DeviceConfigMain />}
            </AppWrapper>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default DevicesConfig;
