export enum LeafletColoredMarker {
  Green = "leafletGreen",
  Red = "leafletRed",
  Yellow = "leafletYellow",
}

export enum DetectionCountCatchChipColor {
  Green = "success",
  Yellow = "warning",
  Red = "error",
}

export interface DetectionCountColors {
  Green: string
  Red: string
  Yellow: string
}

export const GetDetectionCountColor = (detectionCountDelta: number, dcdColor: DetectionCountColors) => {
  if (detectionCountDelta > 0 && detectionCountDelta <= 2) return dcdColor.Yellow;
  if (detectionCountDelta >= 3) return dcdColor.Red;
  return dcdColor.Green;
};

export const changePageTitle = (title: string) => {
  document.title = title;
};

const readFromClipboard = async (): Promise<string[]> => {
  if (navigator.clipboard) {
    const text = await navigator.clipboard.readText();
    return text.split(/\r?\n/);
  }
  return [];
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function smappAssert(condition: boolean, message: string): asserts condition {
  if (!condition) {
    throw new Error(message || "Assertion failed");
  }
}

export default readFromClipboard;
