import React from "react";
import { Grid, Paper, List, ListSubheader, IconButton, Tooltip, Switch } from "@mui/material";
import { DetailDeviceItem, updateDevice } from "queries/devices";
import { deleteOperationRequest } from "queries/devices/operationRequest";
import { formatDistance } from "date-fns";
import { format } from "utils/date-fns";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import PropertyCell from "components/PropertyCell";
import { useAuth } from "context/AuthProvider";
import UpdateDeviceGenericsDialog from "pages/devices/_dialogs/UpdateDeviceGenericsDialog";
import { useMutation } from "@tanstack/react-query";
import { useDeviceView } from "context/DeviceViewProvider";

type GenericsProps = {
  device: DetailDeviceItem;
};
const Generics: React.FC<GenericsProps> = ({ device }) => {
  const { handleMutationSettled, namedOperationRequests } = useDeviceView();
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [switchButtonEnable, setSwitchButtonDisable] = React.useState(true);
  const { isAdmin } = useAuth();

  const updateMutation = useMutation({
    mutationFn: (operation_request: string) =>
      updateDevice({ id: device.id, params: { target_operation_request: operation_request } }),
    onSettled: () => {
      setSwitchButtonDisable(true);
      handleMutationSettled();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteOperationRequest({ id }),
    onSettled: () => {
      setSwitchButtonDisable(true);
      handleMutationSettled();
    },
  });

  return (
    <>
      <Paper elevation={3} sx={{ height: "100%" }}>
        <IconButton
          sx={{ float: "right", zIndex: 2 }}
          // eslint-disable-next-line no-console
          onClick={() => setIsEditOpen(true)}
        >
          <Tooltip title="Edit generics" arrow>
            <ModeEditOutlinedIcon />
          </Tooltip>
        </IconButton>

        {isAdmin &&
          namedOperationRequests.map((v) => (
            <Tooltip key={v.name} title={`${v.name} operation request`} arrow>
              <Switch
                disabled={!switchButtonEnable}
                sx={{ float: "right", zIndex: 2 }}
                onClick={() => {
                  if (!v.data) {
                    updateMutation.mutate(v.name.toLowerCase());
                  } else {
                    deleteMutation.mutate(v.data.id);
                  }
                }}
                checked={v.name.toLowerCase() === v?.data?.operation_request}
              />
            </Tooltip>
          ))}

        <List subheader={<ListSubheader>Generics</ListSubheader>} dense>
          <PropertyCell label="Name" value={device.name} />
          {isAdmin && <PropertyCell label="Comment" value={device.comment} />}
          {isAdmin && <PropertyCell label="Corporation" value={device.corporations?.join(",")} />}
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <PropertyCell label="Type" value={device.type} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <PropertyCell label="Timezone" value={device.timezone} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <PropertyCell label="Country" value={device.country} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <PropertyCell label="City" value={device.city} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <PropertyCell label="Created at" value={format(new Date(device.created_at * 1000))} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <PropertyCell
                label="Updated at"
                value={formatDistance(new Date(device.updated_at * 1000), new Date(), {
                  addSuffix: true,
                })}
              />
            </Grid>
          </Grid>
        </List>
      </Paper>
      <UpdateDeviceGenericsDialog
        isOpen={isEditOpen}
        onClose={() => {
          setIsEditOpen(false);
        }}
        id={device.id}
      />
    </>
  );
};

export default Generics;
