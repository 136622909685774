import { Alert, Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { approveStatusChange, ListDeviceItem } from "queries/devices";
import React from "react";
import { statusToReadable } from "utils/device-statuses";

interface Props {
  device: ListDeviceItem;
}

const ApproveTrapStatusChange = ({ device }: Props) => {
  const queryClient = useQueryClient();
  const changeStatusMutation = useMutation({
    mutationFn: async () => {
      await approveStatusChange({ deviceId: device.id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["detail-device", { id: device.id }] });
      queryClient.invalidateQueries({ queryKey: ["inf-list-devices"], type: "all" });
    },
  });

  if (!device.last_status || device.last_status.approved) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      sx={{ mb: 3 }}
      action={
        <Button
          size="small"
          color="inherit"
          onClick={() => changeStatusMutation.mutate()}
          disabled={changeStatusMutation.isLoading}
        >
          Approve status
        </Button>
      }
    >
      Pending approval of status change to{" "}
      <b>
        {statusToReadable(device.last_status.detailed_status)} (
        {statusToReadable(device.last_status.general_status)})
      </b>
      . Currently visible status is{" "}
      <b>
        {statusToReadable(device.status)} ({statusToReadable(device.status_general)})
      </b>
      .
    </Alert>
  );
};

export default ApproveTrapStatusChange;
