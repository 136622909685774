import React from "react";
import { Chip, Tooltip } from "@mui/material";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
// TODO colors
type RssiChipProps = {
  value?: number;
};
const RssiChip: React.FC<RssiChipProps> = ({ value }) => {
  return (
    <Tooltip title="RSSI" arrow>
      <Chip
        icon={<SignalCellularAltOutlinedIcon />}
        label={value?.toFixed(2) ?? "Unknown"}
        variant="outlined"
      />
    </Tooltip>
  );
};

export default RssiChip;
