import React from "react";
import { DialogContent, Box, Skeleton } from "@mui/material";

import { useAuth } from "context/AuthProvider";
import UpdateDeviceGenericsDialogActions, {
  UpdateDeviceGenericsDialogActionsProps,
} from "./UpdateDeviceGenericsDialogActions";

type UpdateDeviceGenericsDialogFormLoaderProps = {
  actionsProps: UpdateDeviceGenericsDialogActionsProps;
};

const UpdateDeviceGenericsDialogFormLoader: React.FC<UpdateDeviceGenericsDialogFormLoaderProps> = ({
  actionsProps,
}) => {
  const { isAdmin } = useAuth();
  return (
    <Box>
      <DialogContent>
        <Skeleton height="4rem" />
        <Skeleton height="4rem" />
        <Skeleton height="4rem" />
        <Skeleton height="4rem" />
        {isAdmin && <Skeleton height="4rem" />}
      </DialogContent>
      <UpdateDeviceGenericsDialogActions isSaveDisabled {...actionsProps} />
    </Box>
  );
};

export default UpdateDeviceGenericsDialogFormLoader;
