import React from "react";
import { Paper, List, ListSubheader, Grid } from "@mui/material";
import { DetailDeviceItem } from "queries/devices";
import { useQuery } from "@tanstack/react-query";
import AsyncPropertyCell from "components/PropertyCell/AsyncPropertyCell";
import { formatDistance } from "date-fns";
import { detailNetworkDiagnostic } from "queries/devices/networkDiagnostics";

type LastNetworkDiagnosticProps = {
  device: DetailDeviceItem;
};
const LastNetworkDiagnostic: React.FC<LastNetworkDiagnosticProps> = ({ device }) => {
  const networkDiagnosticQuery = useQuery({
    queryFn: () => detailNetworkDiagnostic({ id: device.last_network_diagnostic as string }),
    queryKey: ["detail-network-diagnostic", { id: device.last_network_diagnostic }],
    enabled: !!device.last_network_diagnostic,
  });

  return (
    <Paper elevation={3} sx={{ height: "100%" }}>
      <List subheader={<ListSubheader>Last network diagnostic</ListSubheader>} dense>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="Service"
              value={(data) => data.service}
              query={networkDiagnosticQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="Operator"
              value={(data) => data.operator}
              query={networkDiagnosticQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="Band"
              value={(data) => data.band}
              query={networkDiagnosticQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="Channel"
              value={(data) => data.channel}
              query={networkDiagnosticQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="Cell"
              value={(data) => data.cell}
              query={networkDiagnosticQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="RSSI"
              value={(data) => data.rssi?.toFixed(2) ?? "Unknown"}
              query={networkDiagnosticQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="IP"
              value={(data) => data.ip}
              query={networkDiagnosticQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <AsyncPropertyCell
              label="Last synced"
              value={(data) =>
                formatDistance(new Date(data.created_at * 1000), new Date(), {
                  addSuffix: true,
                })
              }
              query={networkDiagnosticQuery}
            />
          </Grid>
        </Grid>
      </List>
    </Paper>
  );
};

export default LastNetworkDiagnostic;
