import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import React from "react";
import { IMAGE_TYPES, ListImageItem } from "queries/devices/images";
import NothingToShow from "components/NothingToShow";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Image from "components/Image";
import LoadingImage from "components/Image/LoadingImage";
import DetectionCountChip from "components/deviceChips/DetectionCountChip";
import DetectionCountDeltaChip from "components/deviceChips/DetectionCountDeltaChip";
import CleanedButton from "components/deviceChips/CleanedButton";
import { UseQueryResult } from "@tanstack/react-query";
import InfoImageButton from "components/deviceChips/InfoImageButton";

type ValidatedImageProps = {
  imageQuery: UseQueryResult<ListImageItem[], unknown>;
};

const ValidatedImage: React.FC<ValidatedImageProps> = ({ imageQuery }) => {
  const { isLoading } = imageQuery;
  const data = imageQuery.data?.[0];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [editBaseType, setEditBaseType] = React.useState<IMAGE_TYPES | undefined>(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let content = <NothingToShow />;
  if (isLoading) {
    content = <LoadingImage />;
  } else if (data && (editBaseType || data?.validated_blob_name)) {
    content = (
      <Image
        id={data.id}
        type={editBaseType ?? IMAGE_TYPES.validated}
        saveAs={IMAGE_TYPES.validated}
        isEditing={!!editBaseType}
        onClose={() => {
          setEditBaseType(undefined);
        }}
      />
    );
  }

  return (
    <Grid item sm={12} lg={6}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: "9px" }}>
          <Typography variant="overline" component="div" sx={{ flex: 1 }}>
            Current validated
          </Typography>
          <Stack direction="row" spacing={2}>
            <DetectionCountChip
              value={data?.detection_count}
              isEditable={!!data}
              imageId={data?.id}
            />
            <DetectionCountDeltaChip value={data?.detection_count_delta} />

            <CleanedButton
              id={data?.id}
              handleCleanupClick={() => {
                imageQuery.refetch();
              }}
            />
          </Stack>
          {!editBaseType && (
            <>
              <Tooltip title="Create current validated..." arrow>
                <IconButton
                  id="button-create-validated"
                  aria-controls={open ? "menu-create-validated" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-create-validated"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "button-create-validated",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.raw);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.raw_blob_name}
                  aria-disabled={!data?.raw_blob_name}
                >
                  From current raw
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.processed);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.processed_blob_name}
                  aria-disabled={!data?.processed_blob_name}
                >
                  From current processed
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.delta);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.delta_blob_name}
                  aria-disabled={!data?.delta_blob_name}
                >
                  From current delta
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditBaseType(IMAGE_TYPES.validated);
                    setAnchorEl(null);
                  }}
                  disabled={!data?.validated_blob_name}
                  aria-disabled={!data?.validated_blob_name}
                >
                  From current validated
                </MenuItem>
              </Menu>
            </>
          )}

          <InfoImageButton data={data} editBaseType={editBaseType} />
        </Box>
        {content}
      </Paper>
    </Grid>
  );
};

export default ValidatedImage;
