import React from "react";
import { DialogContent, Box, TextField } from "@mui/material";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { updateUser } from "queries/users";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import UpdatePasswordDialogActions, {
  UpdatePasswordDialogActionsProps,
} from "./UpdatePasswordDialogActions";

type UpdatePasswordFormValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const schema = Yup.object({
  currentPassword: Yup.string().required(),
  newPassword: Yup.string().required(),
  confirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword")], "New passwords must match"),
});

type UpdatePasswordDialogFormProps = {
  initialValues: UpdatePasswordFormValues;
  id: string;
  handleSuccess: () => void;
  actionsProps: UpdatePasswordDialogActionsProps;
};
const UpdatePasswordDialogForm: React.FC<UpdatePasswordDialogFormProps> = ({
  id,
  initialValues,
  handleSuccess,
  actionsProps,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePasswordFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: updateUser,
    onSuccess: handleSuccess,
  });

  const onSubmit = React.useCallback(
    (params: UpdatePasswordFormValues) => {
      if (!id) throw new Error("No user id when submitting");

      // TODO: endpoint for pw updates
      const password = params.newPassword;

      mutation.mutate({ id, params: { password } });
    },
    [id, mutation]
  );

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <TextField
          label="Current password"
          type="password"
          error={!!errors.currentPassword}
          helperText={errors.currentPassword?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          required
          {...register("currentPassword")}
        />
        <TextField
          label="New password"
          type="password"
          error={!!errors.newPassword}
          helperText={errors.newPassword?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          required
          {...register("newPassword")}
        />
        <TextField
          label="Confirm new password"
          type="password"
          error={!!errors.confirmNewPassword}
          helperText={errors.confirmNewPassword?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          required
          {...register("confirmNewPassword")}
        />
      </DialogContent>

      <UpdatePasswordDialogActions isSaveLoading={mutation.isLoading} {...actionsProps} />
    </Box>
  );
};

export default UpdatePasswordDialogForm;
