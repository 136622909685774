import React from "react";
import { Paper, List, ListSubheader, Grid } from "@mui/material";
import { DetailDeviceItem } from "queries/devices";
import PropertyCell from "components/PropertyCell";
import { useAuth } from "context/AuthProvider";
import useImeiLabel from "hooks/useImeiLabel";

type IdentifiersProps = {
  device: DetailDeviceItem;
};
const Identifiers: React.FC<IdentifiersProps> = ({ device }) => {
  const imeiLabel = useImeiLabel();
  const { isAdmin } = useAuth();
  return (
    <Paper elevation={3} sx={{ height: "100%" }}>
      <List subheader={<ListSubheader>Identifiers</ListSubheader>} dense>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={12}>
            <PropertyCell label="SMAPP ID" value={device.smapp_id} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={12}>
            <PropertyCell label={imeiLabel} value={device.imei} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={12}>
            <PropertyCell label="MAC" value={device.mac} />
          </Grid>
          {isAdmin && (
            <Grid item xs={12} sm={12} md={6} lg={12}>
              <PropertyCell label="IMSI" value={device.imsi} />
            </Grid>
          )}
          {isAdmin && (
            <Grid item xs={12} sm={12} md={6} lg={12}>
              <PropertyCell label="ICCID" value={device.iccid} />
            </Grid>
          )}
        </Grid>
      </List>
    </Paper>
  );
};

export default Identifiers;
