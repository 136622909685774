import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React from "react";

type SmappAccordianType = {
  defaultOpen?: boolean;
  title: string;
  titleBar?: (isOpen: boolean) => JSX.Element;
  children: React.ReactNode;
  lazyLoad?: () => React.ReactNode;
};

const SmappAccordian = ({
  children,
  defaultOpen = false,
  title,
  titleBar,
  lazyLoad,
}: SmappAccordianType) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
    >
      <AccordionSummary aria-controls="panel1bh-content" sx={{ display: "flex" }}>
        <Typography variant="h6">{title}</Typography>
        {titleBar !== undefined && titleBar(isOpen)}
      </AccordionSummary>
      <AccordionDetails>
        {children !== undefined && isOpen && children}
        {lazyLoad !== undefined && isOpen && lazyLoad()}
      </AccordionDetails>
    </Accordion>
  );
};

export default SmappAccordian;
