import * as React from "react";
// import Typography from "@mui/material/Typography";
import AppWrapper from "components/AppWrapper";
import ROUTES from "routes";
import { Navigate } from "react-router-dom";

const HomeMain: React.FC = () => {
  return (
    <AppWrapper>
      <Navigate to={ROUTES.DEVICES} replace />
    </AppWrapper>
  );
};

export default HomeMain;
