import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";

import DynamicConfigFields from "./DynamicConfigFields";

const InputWakeupTimes: React.FC = () => {
  const { control, getValues, watch } = useFormContext();

  const isStatic = watch("wakeup.type") === "static";
  const dynamicTimes = getValues("wakeup.fields.dynamicTimes");
  const staticMultiText = getValues("wakeup.fields.staticMultiText");

  return (
    <FormControl fullWidth>
      <CardContent>
        <FormLabel style={{ fontWeight: "bold" }}>Wakeup times</FormLabel>
        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
          <Controller
            render={({ field: { value, onChange, ...rest } }) => (
              <RadioGroup
                row
                sx={{ flex: 5, gap: 20, alignItems: "center", justifyContent: "center" }}
                {...rest}
              >
                {["Dynamic", "Static"].map((mapElem) => {
                  return (
                    <FormControlLabel
                      key={mapElem}
                      value={mapElem.toLowerCase()}
                      control={
                        <Radio
                          checked={value === mapElem.toLowerCase()}
                          onChange={(event) => {
                            onChange(event.target.value);
                          }}
                        />
                      }
                      label={mapElem}
                    />
                  );
                })}
              </RadioGroup>
            )}
            name="wakeup.type"
            control={control}
          />
        </CardContent>

        <CardContent className="Dynamic" sx={{ display: !isStatic ? "block" : "none" }}>
          <DynamicConfigFields
            dynamicLabel="First"
            controllerParentName="wakeup.fields.firstDynamicConfig"
          />
          <DynamicConfigFields
            controllerParentName="wakeup.fields.lastDynamicConfig"
            dynamicLabel="Last"
          />

          <CardContent sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: 5 }}>
            <FormLabel>Times</FormLabel>
            <Controller
              name="wakeup.fields.dynamicTimes"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => {
                return (
                  <TextField
                    {...rest}
                    type="number"
                    value={value || 0}
                    label="Times"
                    size="small"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={(event) => {
                      onChange(parseInt(event.target.value, 10));
                    }}
                    InputLabelProps={{ shrink: dynamicTimes !== null }}
                  />
                );
              }}
            />
          </CardContent>
        </CardContent>

        <CardContent className="Static" sx={{ display: isStatic ? "block" : "none" }}>
          <Controller
            name="wakeup.fields.staticMultiText"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <TextField
                {...rest}
                value={value || ""}
                label="Config"
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{ shrink: staticMultiText !== null }}
              />
            )}
          />
        </CardContent>
      </CardContent>
    </FormControl>
  );
};

export default InputWakeupTimes;
