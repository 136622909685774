import React from "react";
import { Chip, Tooltip } from "@mui/material";
import BatteryStdOutlinedIcon from "@mui/icons-material/BatteryStdOutlined";
import { isNotNil } from "utils/lodash";

// TODO colors
type BatteryVoltageChipProps = {
  value?: number | null;
};
const BatteryVoltageChip: React.FC<BatteryVoltageChipProps> = ({ value }) => {
  return (
    <Tooltip title="Battery voltage" arrow>
      <Chip
        icon={<BatteryStdOutlinedIcon />}
        label={isNotNil(value) ? `${value.toFixed(2)} V` : "Unknown"}
        variant="outlined"
      />
    </Tooltip>
  );
};

export default BatteryVoltageChip;
