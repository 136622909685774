import React from "react";
import { Box, Snackbar, Alert } from "@mui/material";

const useCustomSnackBar = () => {
  const [errorList, setErrorList] = React.useState<string[]>([]);
  const [successList, setSuccessList] = React.useState<string[]>([]);

  return {
    jsx: (
      <Box>
        <Snackbar
          onClose={() => {
            setErrorList([]);
          }}
          autoHideDuration={5000}
          open={errorList.length > 0}
        >
          <Alert
            sx={{ maxWidth: `${window.innerWidth * 0.75}px` }}
            onClose={() => {
              setErrorList([]);
            }}
            severity="error"
          >
            {errorList.map((v) => (
              // <pre key={v}>{JSON.stringify(v, null, 1)}</pre>
              <pre key={v}>{v}</pre>
            ))}
          </Alert>
        </Snackbar>
        <Snackbar
          onClose={() => {
            setSuccessList([]);
          }}
          autoHideDuration={5000}
          open={successList.length > 0}
        >
          <Alert
            sx={{ maxWidth: `${window.innerWidth * 0.75}px` }}
            onClose={() => {
              setSuccessList([]);
            }}
            severity="success"
          >
            {successList.map((v) => (
              // <pre key={v}>{JSON.stringify(v, null, 1)}</pre>
              <pre key={v}>{v}</pre>
            ))}
          </Alert>
        </Snackbar>
      </Box>
    ),
    setErrorList,
    setSuccessList,
  };
};

export default useCustomSnackBar;
