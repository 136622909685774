import React from "react";
import { Chip, Tooltip } from "@mui/material";
import convert from "convert";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import { TEMPERATURE, useUserPreferences } from "context/UserPreferencesProvider";
import { isNotNil } from "utils/lodash";

// TODO: colors
type TemperatureChipProps = {
  value?: number | null;
};
const TemperatureChip: React.FC<TemperatureChipProps> = ({ value }) => {
  const { temperature } = useUserPreferences();
  let label = "Unknown";
  if (isNotNil(value)) {
    if (temperature === TEMPERATURE.CELSIUS) label = `${value.toFixed(2)} ℃`;
    else if (temperature === TEMPERATURE.FAHRENHEIT)
      label = `${convert(value, "celsius").to("fahrenheit").toFixed(2)} ℉`;
  }
  return (
    <Tooltip title="Temperature" arrow>
      <Chip icon={<ThermostatOutlinedIcon />} label={label} variant="outlined" />
    </Tooltip>
  );
};

export default TemperatureChip;
