import { useAuth } from "context/AuthProvider";
import React from "react";
import { Navigate } from "react-router-dom";
import ROUTES from "routes";

// Anonym route is used to redirect to home page if user is logged in. Only logged out users can access this route.
// Example usage: login page -> redirect to home page if user is logged in.
const AnonymRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  return <>{children}</>;
};

export default AnonymRoute;
