import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import CardContent from "@mui/material/CardContent";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const InputTimes: React.FC = () => {
  const { control, getValues } = useFormContext();

  return (
    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
      <FormLabel style={{ fontWeight: "bold" }}>Times</FormLabel>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {Object.entries(getValues("time")).map(([k, v]) => {
          return (
            <Controller
              key={`time.${k}`}
              name={`time.${k}`}
              control={control}
              render={({ field }) => (
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <TextField
                    {...field}
                    value={field.value || ""}
                    size="small"
                    label={(() => {
                      const newV = k.replace("_times", "");
                      return newV[0].toUpperCase() + newV.slice(1);
                    })()}
                    InputLabelProps={{ shrink: v !== null }}
                  />
                </Grid>
              )}
            />
          );
        })}
      </Grid>
    </CardContent>
  );
};

export default InputTimes;
