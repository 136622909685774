import { Report } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";
import { DeviceStatus } from "utils/device-statuses";

type AdminApprovalChipProps = {
  // only admins should receive last status. If last status is null, then we don't show this chip
  lastStatus: DeviceStatus | null;
};

const AdminApprovalChip: React.FC<AdminApprovalChipProps> = ({ lastStatus }) => {
  if (!lastStatus || lastStatus?.approved) {
    return null;
  }
  return <Chip icon={<Report />} label="Approval needed" color="error" variant="outlined" />;
};

export default AdminApprovalChip;
