import * as React from "react";
import AppWrapper from "components/AppWrapper";
import { useAuth } from "context/AuthProvider";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { ListUserItem, detailUser } from "queries/users";
import { Box, Button, Grid, List, ListSubheader, Paper } from "@mui/material";
// import { formatDistance } from "date-fns";
import { format } from "utils/date-fns";
import AsyncPropertyCell from "components/PropertyCell/AsyncPropertyCell";
import UpdateUserDialog from "../_dialogs/UpdateUserDialog";
import UpdatePasswordDialog from "../_dialogs/UpdatePasswordDialog";
// import { isNil } from "lodash";

type ProfileProps = {
  userQuery: UseQueryResult<ListUserItem, unknown>;
};
const Profile: React.FC<ProfileProps> = ({ userQuery }) => {
  return (
    <Paper elevation={3} sx={{ height: "100%" }}>
      <List subheader={<ListSubheader>Profile</ListSubheader>} dense>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AsyncPropertyCell label="Username" query={userQuery} value={(data) => data.username} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AsyncPropertyCell
              label="First name"
              query={userQuery}
              value={(data) => data.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AsyncPropertyCell
              label="Last name"
              query={userQuery}
              value={(data) => data.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AsyncPropertyCell label="Email" query={userQuery} value={(data) => data.email} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <AsyncPropertyCell
              label="Date joined"
              query={userQuery}
              value={(data) => format(new Date(data.date_joined * 1000))}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={12}>
            <AsyncPropertyCell
              label="Last login"
              query={userQuery}
              value={(data) =>
                isNil(data.last_login)
                  ? "null"
                  : formatDistance(new Date(data.last_login * 1000), new Date(), {
                      addSuffix: true,
                    })
              }
            />
          </Grid> */}
        </Grid>
      </List>
    </Paper>
  );
};

type ProfileActionsProps = {
  userQuery: UseQueryResult<ListUserItem, unknown>;
};

const ProfileActions: React.FC<ProfileActionsProps> = ({ userQuery }) => {
  const [openedDialog, setOpenedDialog] = React.useState<"password" | "user" | undefined>(
    undefined
  );

  const { logout } = useAuth();

  return (
    <>
      <Paper elevation={3} sx={{ height: "100%" }}>
        <List subheader={<ListSubheader>Actions</ListSubheader>} dense>
          <Box sx={{ pb: 1, pl: 2, pr: 2, pt: 1 }}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenedDialog("user");
              }}
              sx={{ mb: 2 }}
              fullWidth
            >
              Update user
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenedDialog("password");
              }}
              sx={{ mb: 2 }}
              fullWidth
            >
              Change password
            </Button>
            <Button variant="contained" onClick={logout} sx={{ mb: 2 }} fullWidth>
              Log out
            </Button>
            {/* <Button variant="contained" color="error" onClick={() => {}} sx={{ mb: 2 }} fullWidth>
            Delete user
          </Button> */}
          </Box>
        </List>
      </Paper>
      <UpdateUserDialog
        isOpen={openedDialog === "user"}
        id={userQuery.data?.id}
        onClose={() => {
          setOpenedDialog(undefined);
        }}
      />
      <UpdatePasswordDialog
        isOpen={openedDialog === "password"}
        id={userQuery.data?.id}
        onClose={() => {
          setOpenedDialog(undefined);
        }}
      />
    </>
  );
};

const MyProfileMain: React.FC = () => {
  const { currentUser } = useAuth();

  const userQuery = useQuery({
    queryKey: ["detail-user", { id: currentUser?.id }],
    queryFn: () => detailUser({ id: currentUser?.id as string }),
    enabled: !!currentUser?.id,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return (
    <AppWrapper>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Profile userQuery={userQuery} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ProfileActions userQuery={userQuery} />
        </Grid>
      </Grid>
    </AppWrapper>
  );
};

export default MyProfileMain;
