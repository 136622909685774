import axios from "axios";
import { API_ROUTES } from "routes";

export type AccessTokenParams = {
  username: string;
  password: string;
};

export type TokenResponse = {
  access: string;
  refresh: string;
};

export const accessToken = async (params: AccessTokenParams): Promise<TokenResponse> => {
  const { data } = await axios.post<TokenResponse>(API_ROUTES.ACCESS_TOKEN, params);
  return data;
};

type RefreshTokenParams = {
  refresh: string;
};
export const refreshToken = async (params: RefreshTokenParams): Promise<TokenResponse> => {
  const { data } = await axios.post<TokenResponse>(API_ROUTES.REFRESH_TOKEN, params);
  return data;
};
