import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { ListConfigItem } from "queries/configs";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// TODO colors

type ConfigChipProps = {
  value?: ListConfigItem | null;
};
const ConfigChip: React.FC<ConfigChipProps> = ({ value }) => {
  const label = value?.name && value.version ? `${value.name} - ${value.version}` : "Unknown";

  return (
    <Tooltip title="Config" arrow>
      <Chip icon={<SettingsOutlinedIcon />} label={label} variant="outlined" />
    </Tooltip>
  );
};

export default ConfigChip;
