import React from "react";

import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";
import MapMain from "./main";

const Map: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <MapMain />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Map;
