import React from "react";
import { CAM_RES } from "queries/configs";
import { Controller, useFormContext } from "react-hook-form";
import CardContent from "@mui/material/CardContent";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const InputCamera: React.FC = () => {
  const { control } = useFormContext();
  const camResultions = Object.entries(CAM_RES);

  return (
    <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormLabel style={{ fontWeight: "bold" }}>Camera</FormLabel>

      <Controller
        name="camera.autofocus"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Switch {...field} name="enabled" checked={field.value || false} />}
            label="Autofocus"
          />
        )}
      />

      <Controller
        name="camera.flashlight"
        control={control}
        render={({ field: { value, ...rest } }) => (
          <TextField
            {...rest}
            value={value || ""}
            label="Flashlight"
            size="small"
            sx={{ width: "25%" }}
            // InputLabelProps={{ shrink: dynamicTimes !== null }}
          />
        )}
      />

      <Controller
        name="camera.resolution"
        control={control}
        render={({ field: { value, ...rest } }) => (
          <>
            <InputLabel id="resolution">Choose resolution</InputLabel>
            <Select {...rest} value={value || ""} labelId="resolution">
              {camResultions.map(([k, v]) => {
                return (
                  <MenuItem key={k} value={v}>
                    {k}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        )}
      />
    </CardContent>
  );
};

export default InputCamera;
