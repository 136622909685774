import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Box, Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import CoordinateImage from "components/Image/CoordinateImage";
import InfoIcon from "@mui/icons-material/Info";
import { IMAGE_TYPES, ListImageItem, cleanedImage } from "queries/devices/images";

type InfoImageButtonProps = {
  data: ListImageItem | undefined;
  editBaseType: IMAGE_TYPES | undefined;
};

const InfoImageButton: React.FC<InfoImageButtonProps> = ({ data, editBaseType }) => {
  const [open, setOpen] = useState(false);

  const cleanedImageMutation = useMutation({
    mutationFn: cleanedImage,
    onSettled: () => {
      setTimeout(() => {
        cleanedImageMutation.reset();
      }, 2500);
    },
  });

  return (
    <Tooltip
      sx={{ position: "absolute", top: 0, right: 0 }}
      className="CoordinateTooltip"
      title="Coordinates"
      arrow
    >
      <IconButton
        onClick={() => {
          setOpen((open) => !open);
        }}
      >
        <InfoIcon />
        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason && reason === "backdropClick") return;
            setOpen(false);
          }}
          PaperProps={{
            style: {
              minHeight: "60%",
              minWidth: "60%",
            },
          }}
        >
          <DialogContent>
            <CoordinateImage
              id={data?.id || ""}
              coordinates={data?.coordinates}
              type={IMAGE_TYPES.raw}
            />
          </DialogContent>
        </Dialog>
      </IconButton>
    </Tooltip>
  );
};

export default InfoImageButton;
